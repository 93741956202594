import {
  FIND_SERVICE_TYPES_REQUEST,
  FIND_SERVICE_TYPES_SUCCESS,
  FIND_SERVICE_TYPES_ERROR,
  SELECT_SERVICE_TYPE,
  UPDATE_DIAGNOSTIC_SERVICE,
  DEFAULT_SERVICE_TYPE,
} from '../createNewWorkOrderConstants';

const serviceTypesReducerDefaultState = {
  isFetching: true,
  errorMessage: '',
  selectedDiagnosticServices: [],
  serviceType: DEFAULT_SERVICE_TYPE,
  serviceTypes: [],
};

export default (
  state = serviceTypesReducerDefaultState,
  { errorMessage, selectedDiagnosticServices, serviceType, serviceTypes, type },
) => {
  switch (type) {
    case FIND_SERVICE_TYPES_REQUEST:
      return {
        ...state,
      };

    case FIND_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        serviceTypes,
      };

    case FIND_SERVICE_TYPES_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };

    case SELECT_SERVICE_TYPE:
      return {
        ...state,
        serviceType,
      };

    case UPDATE_DIAGNOSTIC_SERVICE:
      return {
        ...state,
        selectedDiagnosticServices,
      };

    default:
      return state;
  }
};
