import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-dynamic-swiper/lib/styles.css';
import SlideContent from '../components/SlideContent';
import workOrderDays from '../../../utils/workOrderDays';
import WorkOrderListTop from '../components/workOrderListTop/WorkOrderListTop';
import './workOrderList.scss';
import PreviousWorkOrderListContainer from './PreviousWorkOrderListContainer';
import PageSwiper from '../../../components/Swiper/PageSwiper';
import getDayAsString from '../../../utils/getDayAsString';

function WorkOrderListSlideContainer({
  productList,
  workOrders,
  numberOfPrevWorkOrders,
  languageStrings,
}) {
  const [swiper, updateSwiper] = useState(null);

  const findCorrectProductList = date =>
    productList.find(listElement => listElement.serviceDate === date)
      ? productList.find(listElement => listElement.serviceDate === date).products
      : {};
  const { allWorkOrders } = workOrderDays(workOrders);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <PageSwiper getSwiper={updateSwiper} containerClass="workOrder__list" initialSlide={1}>
      <div>
        <WorkOrderListTop
          hideProductList
          workOrders={workOrders}
          title={languageStrings.TITLE}
          onNextClick={goNext}
          hasNext
        />
        <PreviousWorkOrderListContainer />
      </div>
      {allWorkOrders.map((item, index) => (
        <div id={index} key={item.date}>
          <WorkOrderListTop
            hideProductList={!item.workOrdersNotDone.length}
            title={getDayAsString(item.date)}
            productList={findCorrectProductList(item.date)}
            onNextClick={index + 1 < allWorkOrders.length ? goNext : null}
            onPrevClick={goPrev}
            numberOfElementsPrevPage={index === 0 ? numberOfPrevWorkOrders : null}
          />
          <SlideContent
            isWorkOrderListEmpty={item.isWorkOrderListEmpty}
            areAllWorkOrdersDone={item.areAllWorkOrdersDone}
            isExternalInstaller
            workOrdersCancelled={item.workOrdersCancelled}
            workOrdersDone={item.workOrdersDone}
            workOrdersNotDone={item.workOrdersNotDone}
          />
        </div>
      ))}
    </PageSwiper>
  );
}

WorkOrderListSlideContainer.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.any).isRequired,
  workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  numberOfPrevWorkOrders: PropTypes.number,
  languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};

WorkOrderListSlideContainer.defaultProps = {
  numberOfPrevWorkOrders: null,
};

const mapStateToProps = state => ({
  productList: state.productList.productList,
  workOrders: state.workOrders.workOrders,
  numberOfPrevWorkOrders: state.previousWorkOrders.workOrders.length
    ? state.previousWorkOrders.latestPreviousWorkOrderRequest.totalElements
    : 0,
  isFetchingPrevWorkOrders: state.previousWorkOrders.isFetching,
  languageStrings: state.NOB.PREV_WORKORDERS,
});

export default connect(mapStateToProps)(WorkOrderListSlideContainer);
