import React from "react";
import Archive from "./Archive";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import Calendar from "./Calendar";
import Camera from "./Camera";
import Car from "./Car";
import Check from "./Check";
import Clock from "./Clock";
import Cross from "./Cross";
import CrossLarge from "./CrossLarge";
import Delete from "./Delete";
import Edit from "./Edit";
import Expand from "./Expand";
import GetBox from "./GetBox";
import Heart from "./Heart";
import HealthCheck from "./HealthCheck";
import History from "./History";
import House from "./House";
import Link from "./Link";
import Map from "./Map";
import Minimize from "./Minimize";
import Plus from "./Plus";
import Refresh from "./Refresh";
import Save from "./Save";
import Scan from "./Scan";
import Search from "./Search";
import StatusError from "./StatusError";
import StatusOk from "./StatusOk";
import Switch from "./Switch";
import Telephone from "./Telephone";
import Tool from "./Tool";
import ToolOutlined from "./ToolOutlined";
import Trash from "./Trash";
import User from "./User";
import Switch2 from "./Switch2";
import * as ICON from "../constants/iconNames";
import Pdf from "./Pdf";

const Icon = props => {
  switch (props.name) {
    case ICON.ARCHIVE:
      return <Archive {...props} />;
    case ICON.ARROW_DOWN:
      return <ArrowDown {...props} />;
    case ICON.ARROW_LEFT:
      return <ArrowLeft {...props} />;
    case ICON.CALENDAR:
      return <Calendar {...props} />;
    case ICON.CAMERA:
      return <Camera {...props} />;
    case ICON.CAR:
      return <Car {...props} />;
    case ICON.CHECK:
      return <Check {...props} />;
    case ICON.CLOCK:
      return <Clock {...props} />;
    case ICON.CROSS:
      return <Cross {...props} />;
    case ICON.CROSS_LARGE:
      return <CrossLarge {...props} />;
    case ICON.DELETE:
      return <Delete {...props} />;
    case ICON.EDIT:
      return <Edit {...props} />;
    case ICON.EXPAND:
      return <Expand {...props} />;
    case ICON.GET_BOX:
      return <GetBox {...props} />;
    case ICON.HEART:
      return <Heart {...props} />;
    case ICON.HISTORY:
      return <History {...props} />;
    case ICON.HOUSE:
      return <House {...props} />;
    case ICON.HEALTH_CHECK:
        return <HealthCheck {...props} />;
    case ICON.LINK:
      return <Link {...props} />;
    case ICON.MAP:
      return <Map {...props} />;
    case ICON.MINIMIZE:
      return <Minimize {...props} />;
    case ICON.SAVE:
      return <Save {...props} />;
    case ICON.SCAN:
      return <Scan {...props} />;
    case ICON.SEARCH:
      return <Search {...props} />;
    case ICON.STATUS_ERROR:
      return <StatusError {...props} />;
    case ICON.STATUS_OK:
      return <StatusOk {...props} />;
    case ICON.SWITCH:
      return <Switch {...props} />;
    case ICON.PLUS:
      return <Plus {...props} />;
    case ICON.REFRESH:
      return <Refresh {...props} />;
    case ICON.TOOL:
      return <Tool {...props} />;
    case ICON.TOOL_OULINED:
      return <ToolOutlined {...props} />;
    case ICON.TRASH:
      return <Trash {...props} />;
    case ICON.USER:
      return <User {...props} />;
    case ICON.TELEPHONE:
      return <Telephone {...props} />;
    case ICON.PDF:
      return <Pdf {...props} />;
    case ICON.SWITCH2:
      return <Switch2 />
    default:
      return <Clock {...props} />;
  }
};

export default Icon;
