import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NOB from '../../../../constants/language-strings';
import * as addAction from '../../actions/addProductActions';
import './addProduct.scss';
import SearchProductBar from '../../../../components/SearchProduct/SearchProductBar';
import Feedback from '../../../../feedbacks';
import { FEEDBACK_HMM } from '../../../../constants/feedbackNames';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import SlideInModal from '../../../../components/SlideInModal/SlideInModal';

function AddProductsContainer({
  agreementId,
  addProduct: { availableProducts, availableProductsError },
  customerId,
  requestAvailableProducts,
  requestAddProduct,
  isVisible,
  onClose,
  areAgreementsFetching,
  isFetchingAvailableProducts,
  clearAvailableProductsError,
}) {
  useEffect(() => {
    if (!availableProductsError && agreementId > 0) {
      requestAvailableProducts(agreementId);
    }
  }, [agreementId]);

  const addNewProduct = product => {
    requestAddProduct(agreementId, customerId, product);
    onClose();
  };

  return (
    <SlideInModal
      display={isVisible}
      onClose={onClose}
      mainHeading="Legg til produkter"
      isFetching={areAgreementsFetching || isFetchingAvailableProducts}
      cleanUp={clearAvailableProductsError}
      fetchingSpinnerColor="black" // will NOT work if color imported from variables.scss, use basic colors instead.
    >
      {availableProductsError ? (
        <div className="add_product_search">
          <InfoBox
            feedback={<Feedback name={FEEDBACK_HMM} />}
            firstInfoBoxLine={NOB.ADD_PRODUCT.ERROR_MESSAGE_FETCHING_PRODUCTS}
            secondInfoBoxLine={availableProductsError}
            link={false}
          />
        </div>
      ) : (
        <SearchProductBar
          options={availableProducts}
          placeholder={NOB.ADD_PRODUCT.SEARCH}
          onChange={addNewProduct}
        />
      )}
    </SlideInModal>
  );
}

AddProductsContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  agreementId: PropTypes.number.isRequired,
  addProduct: PropTypes.objectOf(PropTypes.any).isRequired,
  customerId: PropTypes.number.isRequired,
  areAgreementsFetching: PropTypes.bool.isRequired,
  isFetchingAvailableProducts: PropTypes.bool.isRequired,
  requestAddProduct: PropTypes.func.isRequired,
  requestAvailableProducts: PropTypes.func.isRequired,
  clearAvailableProductsError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  addProduct: state.addProduct,
  customerId: state.workOrder.workOrder.customer.customerId,
  areAgreementsFetching: state.agreements.isFetching,
  isFetchingAvailableProducts: state.addProduct.isFetching,
});

const mapDispatchToProps = dispatch => ({
  requestAddProduct: (agreementId, customerId, product) =>
    dispatch(addAction.requestAddProduct({ agreementId, customerId, product })),
  requestAvailableProducts: agreementId =>
    dispatch(addAction.requestAvailableProducts(agreementId)),
  clearAvailableProductsError: () => dispatch(addAction.clearAvailableProductsError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductsContainer);
