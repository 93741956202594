import {
  CHANGE_PRODUCT_REQUEST,
  CHANGE_PRODUCT_SUCCESS,
  CHANGE_PRODUCT_FAILURE,
  CLEAR_ERROR_MESSAGE,
} from '../customerProductsConstants';

const changeProductReducerDefaultState = {
  changedProduct: [],
  errorMessage: '',
  errorProducts: [],
  isFetching: false,
  dpadId: 0,
};

export default (
  state = changeProductReducerDefaultState,
  { changedProduct, errorMessage, errorProducts, oldSerial, dpadId, productName, type },
) => {
  switch (type) {
    case CHANGE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetchingChangeProduct: true,
        errorMessage: '',
        oldSerial,
        dpadId,
      };

    case CHANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        changedProduct: [...state.changedProduct, changedProduct],
        productName,
        isFetchingChangeProduct: false,
        errorMessage: '',
        errorProducts: [],
      };

    case CHANGE_PRODUCT_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetching: false,
        isFetchingChangeProduct: false,
        errorProducts: state.errorProducts.concat(errorProducts),
      };

    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
        errorProducts: [...state.errorProducts.filter(products => products !== errorProducts)],
      };

    default:
      return state;
  }
};
