import React from 'react';
import timeInHoursAndMinutes from '../../../../utils/timeInHoursAndMinutes';
import '../../renderService.scss';

/**
 * Helper method to highlight matched words in a search with bold. Used by RenderService which is supplied to react-select-search as "option"
 * Taken from the basic usage in markSearch.js
 */
function checkMatch(service, input) {
  const regex = input && new RegExp(input.search.trim(), 'i');
  const segments = service.name.split(regex);
  const match = service.name.match(regex);

  if (input && input.search) {
    if (match) {
      return (
        <div className="service_name">
          {segments[0]}
          <span className="searched">{match}</span>
          {segments[1]}
        </div>
      );
    }

    return <div className="service_name">{service.name}</div>;
  }

  return <div className="service_name">{service.name}</div>;
}

const RenderService = (service, input) => {
  const { hours, minutes } = timeInHoursAndMinutes(service.timeUnitPerService);
  const serviceName = checkMatch(service, input);

  return (
    <div className="service_outer">
      {serviceName}
      <div className="service_time">{`${hours}t ${minutes}m`}</div>
      <div className="service_price">{`${service.amount}-,`}</div>
    </div>
  );
};

export default RenderService;
