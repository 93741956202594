import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import './orderedProducts.scss';
import { hours, minutes } from '../../../../utils/timeCalculator';

const OrderedProducts = ({ done, orderedItems, technician }) => {
  const showDiagnosis = technician && !done;

  const itemSummary = showDiagnosis
    ? orderedItems.itemSummary.filter(item =>
        item.name.split(' > ').includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS),
      )
    : orderedItems.itemSummary.filter(item => !item.name.includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS));

  const removeDiagnosis = diagnosisItemName =>
    diagnosisItemName.replace(`${NOB.ORDERED_PRODUCTS.DIAGNOSIS} >`, '');

  const productsTable = itemSummary.map(product => (
    <div className="ordered__products--items" key={product.workOrderServiceId}>
      <div>{product.quantity}</div>
      <div>{showDiagnosis ? removeDiagnosis(product.name) : product.name}</div>
      <div>{`${hours(product.timeEstimate)} ${minutes(product.timeEstimate)}`}</div>
    </div>
  ));

  return (
    itemSummary.length > 0 && (
      <div className="ordered__products">
        <div className="ordered__products--headings">
          <div>{NOB.ORDERED_PRODUCTS.QUANTITY}</div>
          {done || !technician
            ? NOB.ORDERED_PRODUCTS.SERVICE_CODES
            : NOB.ORDERED_PRODUCTS.DIAGNOSIS}
          <div>{NOB.ORDERED_PRODUCTS.TIME}</div>
        </div>
        <div className="ordered__products--productlist">{productsTable}</div>
      </div>
    )
  );
};

OrderedProducts.propTypes = {
  done: PropTypes.bool.isRequired,
  orderedItems: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  technician: PropTypes.bool.isRequired,
};

export default OrderedProducts;
