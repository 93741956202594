import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';
import { CROSS_LARGE, ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import Variables from '../../styles/variables.scss';
import './imageViewer.scss';

export default function ExtendedImage({ expandedIndex, imagesFiles, toggleExpandImage }) {
  const icon = className => (
    <span className={className}>
      <Icon name={ARROW_DOWN} stroke={Variables.whiteSecondary} width="30px" height="30px" />
    </span>
  );

  const sortedImagesFiles = () => {
    if (!expandedIndex) {
      return imagesFiles;
    }
    const startValues = imagesFiles.slice(expandedIndex);
    const endValues = imagesFiles.slice(0, expandedIndex);

    return startValues.concat(endValues);
  };

  return (
    <div className="image_file__expanded">
      <button onClick={toggleExpandImage}>
        <Icon fill={Variables.whiteSecondary} name={CROSS_LARGE} />
      </button>

      <Swiper
        swiperOptions={{
          slidesPerView: 'auto',
          loop: sortedImagesFiles().length > 1,
          pagination: false,
        }}
        nextButton={() => icon('navigation-arrow--next')}
        prevButton={() => icon('navigation-arrow--prev')}
      >
        {sortedImagesFiles().map((item, index) => (
          <Slide id={index} key={item}>
            <img alt="added item" src={item.file} key={item.fileName} />
          </Slide>
        ))}
      </Swiper>
    </div>
  );
}

ExtendedImage.propTypes = {
  expandedIndex: PropTypes.number.isRequired,
  imagesFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleExpandImage: PropTypes.func.isRequired,
};
