import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-dynamic-swiper/lib/styles.css';
import { EXTERNAL_INSTALLER, TECHNICIAN } from '../../Authentication/constants';
import Feedback from '../../../feedbacks';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import SlideContent from '../components/SlideContent';
import WorkOrderListBottom from '../components/workOrderListBottom/WorkOrderListBottom';
import * as productListAction from '../actions/productList';
import * as popupAction from '../../../components/Popup/actions/popupActions';
import * as workOrderAction from '../actions/workOrderList';
import * as zendeskAction from '../../WorkLog/actions/zendeskActions';
import './workOrderList.scss';
import WorkOrderListSlideContainer from './WorkOrderListSlideContainer';

class WorkOrderListContainer extends Component {
  componentDidMount() {
    const { requestProductList, requestWorkOrderList, saveComment, updateFollowUp } = this.props;

    saveComment('');
    updateFollowUp(false, false, false);
    requestWorkOrderList();
    requestProductList();
  }

  componentWillUnmount() {
    const { cancelWorkOrderListFetching } = this.props;

    cancelWorkOrderListFetching();
  }

  render() {
    const {
      endDay,
      errorMessage,
      isProductListFetching,
      isWorkOrderListFetching,
      role,
      togglePopup,
      workOrders,
      previousWorkOrders,
    } = this.props;

    const isTechnician = role === TECHNICIAN;
    const isExternalInstaller = role === EXTERNAL_INSTALLER;
    const productsLoading = isTechnician ? false : isProductListFetching;
    const isWorkOrderListEmpty = !workOrders.length;

    const unfinishedPastWorkOrders = previousWorkOrders.filter(item => item.status === 'SENT');

    if (isWorkOrderListFetching || productsLoading) {
      return (
        <div className="work_order_list--fetching">
          <FetchingSpinner />
        </div>
      );
    }

    if (errorMessage !== '' && !workOrders.length) {
      return (
        <div className="workorder__list--no--workorder">
          <InfoBox
            feedback={<Feedback />}
            firstInfoBoxLine={NOB.FEEDBACKS.SORRY}
            link={false}
            secondInfoBoxLine={errorMessage}
          />
          <WorkOrderListBottom endDayFunction={endDay} role={role} togglePopup={togglePopup} />
        </div>
      );
    }

    return (
      <div className="workOrder__list" style={{ overflowX: 'hidden' }}>
        {!!unfinishedPastWorkOrders.length && (
          <div className="unfinished_work_orders_message">
            <b>VIKTIG!</b> Du har
            <span className="unfinished_work_orders_message__number">
              {unfinishedPastWorkOrders.length}
            </span>
            WO&apos;er som ikke er meldt inn. Vennligst meld inn disse så fort fort du kan! Jobber
            skal alltid meldes inn samme dag.
          </div>
        )}
        {isExternalInstaller && <WorkOrderListSlideContainer />}
        {isTechnician && (
          <SlideContent
            isTechnician
            workOrdersNotDone={workOrders}
            isWorkOrderListEmpty={isWorkOrderListEmpty}
          />
        )}

        <WorkOrderListBottom endDayFunction={endDay} role={role} togglePopup={togglePopup} />
      </div>
    );
  }
}

WorkOrderListContainer.propTypes = {
  endDay: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isProductListFetching: PropTypes.bool.isRequired,
  isWorkOrderListFetching: PropTypes.bool.isRequired,
  requestProductList: PropTypes.func.isRequired,
  requestWorkOrderList: PropTypes.func.isRequired,
  cancelWorkOrderListFetching: PropTypes.func.isRequired,
  role: PropTypes.string,
  saveComment: PropTypes.func.isRequired,
  togglePopup: PropTypes.func.isRequired,
  updateFollowUp: PropTypes.func.isRequired,
  workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  previousWorkOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
};

WorkOrderListContainer.defaultProps = {
  errorMessage: '',
  role: EXTERNAL_INSTALLER,
};

const mapStateToProps = state => ({
  errorMessage: state.workOrders.errorMessage,
  isProductListFetching: state.productList.isFetching,
  isWorkOrderListFetching: state.workOrders.isFetching,
  productList: state.productList.productList,
  role: state.authentication.role,
  workOrders: state.workOrders.workOrders,
  numberOfPrevWorkOrders: state.previousWorkOrders.workOrders.length
    ? state.previousWorkOrders.latestPreviousWorkOrderRequest.totalElements
    : 0,
  previousWorkOrders: state.previousWorkOrders.workOrders,
});

const mapDispatchToProps = dispatch => ({
  endDay: endDay => dispatch(workOrderAction.endDayFunction({ endDay })),
  requestWorkOrderList: () => dispatch(workOrderAction.requestWorkOrderList()),
  cancelWorkOrderListFetching: () => dispatch(workOrderAction.workOrderListCancel()),
  requestProductList: () => dispatch(productListAction.requestProductList()),
  saveComment: commentBody => dispatch(zendeskAction.saveComment(commentBody)),
  togglePopup: (close, popupType) => dispatch(popupAction.togglePopup({ close, popupType })),
  updateFollowUp: (followUp, customerNotHome, allChecked) =>
    dispatch(zendeskAction.setFollowUp({ followUp, customerNotHome, allChecked })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderListContainer);
