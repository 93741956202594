import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddFiles from '../../../components/addFiles/addFiles';
import { TECHNICIAN } from '../../Authentication/constants';
import NOB from '../../../constants/language-strings';
import useForm from '../../../utils/customHooks/useForm';
import {
  FOLLOW_UP,
  CUSTOMER_NOT_HOME,
  COMMENT,
  CONFIRMED_RIGHT_ADDRESS,
  RUNG_KNOCKED_DOOR,
  CALLED_CUSTOMER,
  AZIMUTH,
} from '../../WorkLog/workLogConstants';
import '../workOrderCompletionForm.scss';
import zendeskBody from '../../../utils/zendeskBody';
import completeWorkOrderWithZendesk from '../../WorkLog/actions/completeWorkOrderActions';
import {
  COMPLETE_WITHOUT_CODE,
  INCOMPLETE_WITHOUT_CODE,
  INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE,
} from '../../../constants/status-codes';
import { whitePrimary } from '../../../styles/variables.scss';
import FetchingSpinnerWithOverlay from '../../../components/FetchingSpinnerWithOverlay/FetchingSpinnerWithOverlay';
import WorkOrderCompletionFields from '../components/WorkOrderCompletionFields';
import WorkOrderCompletionButtons from '../components/WorkOrderCompletionButtons';
import WorkOrderCompletionNavigationButtons from './WorkOrderCompletionNavigationButtons';
import formValidator from '../formValidator';
import Platform from '../../../constants/platform';
import FetchingSpinner from '../../../utils/FetchingSpinner';

function WorkOrderCompletionFormContainer({
  customerNotHome,
  isCompletingWorkOrder,
  role,
  requestCompleteWorkOrder,
  workOrder,
  workOrderServices,
  customerProducts,
}) {
  const [files, setFiles] = useState([]);
  const [azimuthErrorMessage, setAzimuthErrorMessage] = useState('');
  const technician = role === TECHNICIAN;
  const addedServiceCodes = workOrderServices.filter(
    item => !item.name.includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS),
  );

  const { values, handleChange, overrideValue, overrideValues } = useForm();

  const handleChangeValidated = event => {
    handleChange(event);
    formValidator(event.target.name, event.target.value, setAzimuthErrorMessage);
  };

  const onSubmit = isComplete => {
    const zendeskBodyParams = zendeskBody(
      workOrder,
      customerProducts.customerProducts,
      {
        commentBody: values[COMMENT],
        files,
        followUp: values[FOLLOW_UP],
      },
      isComplete,
    );

    let completeStatus;

    if (values[CUSTOMER_NOT_HOME]) completeStatus = INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE;
    else if (isComplete) completeStatus = COMPLETE_WITHOUT_CODE;
    else completeStatus = INCOMPLETE_WITHOUT_CODE;

    requestCompleteWorkOrder(
      zendeskBodyParams,
      completeStatus,
      workOrder.workOrderId,
      values[AZIMUTH],
    );
  };

  useEffect(() => {
    overrideValue(CUSTOMER_NOT_HOME, customerNotHome);
  }, []);

  const nameAndValue = (name, value) => ({ name, value });

  const overriddenHandleChange = event => {
    if (
      !values[CUSTOMER_NOT_HOME] &&
      (values[CONFIRMED_RIGHT_ADDRESS] || values[RUNG_KNOCKED_DOOR] || values[CALLED_CUSTOMER])
    ) {
      overrideValues(
        nameAndValue(CALLED_CUSTOMER, false),
        nameAndValue(CONFIRMED_RIGHT_ADDRESS, false),
        nameAndValue(RUNG_KNOCKED_DOOR, false),
        nameAndValue(CUSTOMER_NOT_HOME, true),
      );
    } else handleChangeValidated(event);
  };

  const disableCompleteButtons =
    customerNotHome || values[CUSTOMER_NOT_HOME]
      ? !values[COMMENT] ||
        !values[CONFIRMED_RIGHT_ADDRESS] ||
        !values[RUNG_KNOCKED_DOOR] ||
        !values[CALLED_CUSTOMER] ||
        !files.length ||
        !values[CUSTOMER_NOT_HOME]
      : !values[COMMENT] || !addedServiceCodes.length;

  const disableNotCompleteButton =
    values[FOLLOW_UP] ||
    (values[CUSTOMER_NOT_HOME] &&
      !(values[CONFIRMED_RIGHT_ADDRESS] && values[RUNG_KNOCKED_DOOR] && values[CALLED_CUSTOMER]));

  const disableCompleteButton =
    workOrderServices.length === 0 ||
    customerNotHome ||
    values[CUSTOMER_NOT_HOME] ||
    azimuthErrorMessage !== '';
  // TODO: hot fix: due network components we cannot known if azimuth is required
  // removed condition:   || (!values[AZIMUTH] && workOrder.address.platform === Platform.FWA);

  const isWorkOrderLoading =
    workOrder === undefined || workOrder === null || workOrder?.workOrderId === 0;

  if (isWorkOrderLoading) {
    return (
      <div className="workorder-completion-wrapper">
        <FetchingSpinner color={whitePrimary} size={60} />
      </div>
    );
  }

  return (
    <div className="workorder-completion-wrapper">
      {isCompletingWorkOrder && <FetchingSpinnerWithOverlay color={whitePrimary} size={60} />}
      <WorkOrderCompletionFields
        handleChange={overriddenHandleChange}
        values={values}
        technician={technician}
        azimuthErrorMessage={azimuthErrorMessage}
        azimuthProposed={workOrder.technicalDetails?.Azimuth_proposed || ''}
        isFWA={workOrder.address?.platform === Platform.FWA}
      />
      <AddFiles saveFiles={setFiles} files={files} />
      {technician && !customerNotHome && (
        <WorkOrderCompletionNavigationButtons
          technician={technician}
          customerNotHome={customerNotHome}
        />
      )}
      <WorkOrderCompletionButtons
        disabled={technician ? disableCompleteButtons : !values[COMMENT]}
        handleSubmit={onSubmit}
        technician={technician}
        values={values}
        disableCompleteButton={disableCompleteButton}
        disableNotCompleteButton={disableNotCompleteButton}
      />
    </div>
  );
}

WorkOrderCompletionFormContainer.propTypes = {
  customerNotHome: PropTypes.bool,
  isCompletingWorkOrder: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  requestCompleteWorkOrder: PropTypes.func.isRequired,
  workOrderServices: PropTypes.arrayOf(PropTypes.any).isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  customerProducts: PropTypes.objectOf(PropTypes.any),
};

WorkOrderCompletionFormContainer.defaultProps = {
  customerNotHome: false,
};
const mapStateToProps = state => ({
  isCompletingWorkOrder: state.workOrderServices.isCompletingWorkOrder,
  role: state.authentication.role,
  workOrder: state.workOrder.workOrder,
  workOrderServices: state.workOrderServices.orderedItems.itemSummary,
  customerProducts: state.customerProducts,
});

const mapDispatchToProps = dispatch => ({
  requestCompleteWorkOrder: (body, status, workOrderId, azimuth) =>
    dispatch(completeWorkOrderWithZendesk(body, status, workOrderId, azimuth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderCompletionFormContainer);
