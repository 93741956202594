/*
  The samValueCorrector function takes in a JSON object, obtained from
  the SAM-device-polling-API, and returns a new similar object, where
  specific values have been transformed.

  This transformation is made due to the API multiplying certain values with
  factors of 10 to avoid floating point numbers.

  The logic is extracted from the brain of Olaf Sverre Johansen (olaf.sverre.johansen@get.no)
*/
export default function samValueCorrector(samDevicePolling) {
  const usChannels = samDevicePolling.UsChannels.map(ch => ({
    ...ch,
    Frequency: ch.Frequency / 100,
    Power: ch.Power / 10,
    Snr: ch.Snr / 10,
  }));

  const dsChannels = samDevicePolling.DsChannels.map(ch => ({
    ...ch,
    Power: ch.Power / 10,
    Snr: ch.Snr / 10,
  }));

  return {
    ...samDevicePolling,
    UsChannels: usChannels,
    DsChannels: dsChannels,
  };
}
