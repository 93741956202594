import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import SlideInModal from '../../components/SlideInModal/SlideInModal';
import HealthCheckTabs from './components/HealthCheckTabs';
import FetchingSpinner from '../../utils/FetchingSpinner';
import { getAPI } from '../../api/apiConfig';
import IconButton from '../../components/Buttons/IconButton/IconButton';
import { REFRESH } from '../../constants/iconNames';
import InfoBox from '../../components/InfoBox/InfoBox';
import Feedback from '../../feedbacks';
import { DEVICE_POLL_URL } from '../../constants/endpoints';

function DeviceHealthCheckContainer({ pollUrl, onClose, description, display, languageStrings }) {
  const [isFetching, setIsFetching] = useState(true);
  const [healthCheck, setHealthCheck] = useState({ success: false, errorDescription: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const callGetHealthCheck = async () => {
    setIsFetching(true);
    setErrorMessage('');
    try {
      const healthCheckResult = await getAPI(DEVICE_POLL_URL(pollUrl));

      setHealthCheck(healthCheckResult);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(languageStrings.HEALTH_CHECK_ERROR);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (pollUrl) callGetHealthCheck();
  }, [pollUrl]);

  return (
    <SlideInModal
      display={display}
      onClose={onClose}
      mainHeading={description}
      leftIcon={
        isFetching ? (
          <ClipLoader size={20} />
        ) : (
          <IconButton onClick={callGetHealthCheck} icon={REFRESH} className="sam_refresh-button" />
        )
      }
    >
      {isFetching && <FetchingSpinner />}
      {!isFetching && (errorMessage || !healthCheck.success) && (
        <InfoBox
          feedback={<Feedback />}
          firstInfoBoxLine={languageStrings.SORRY}
          link={false}
          secondInfoBoxLine={healthCheck.errorDescription || errorMessage}
        />
      )}
      {!isFetching && healthCheck.success && (
        <HealthCheckTabs categories={healthCheck.categories} />
      )}
    </SlideInModal>
  );
}

DeviceHealthCheckContainer.propTypes = {
  pollUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  display: PropTypes.bool.isRequired,
  languageStrings: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

DeviceHealthCheckContainer.defaultProps = {
  pollUrl: '',
  description: '',
};

const mapStateToProps = state => ({
  languageStrings: state.NOB.FEEDBACKS,
});

export default connect(mapStateToProps)(DeviceHealthCheckContainer);
