import React from 'react';
import PropTypes from 'prop-types';
import ProductList from './productList/ProductList';
import IconButton from '../../../../components/Buttons/IconButton/IconButton';
import { ARROW_DOWN } from '../../../../constants/iconNames';

import './workOrderListTop.scss';

function WorkOrderListTop({
  hideProductList,
  productList,
  onNextClick,
  onPrevClick,
  title,
  numberOfElementsPrevPage,
}) {
  const prevButton = (
    <div className={onPrevClick ? 'navigation-arrow' : 'navigation-arrow--disabled'}>
      <IconButton onClick={onPrevClick || (() => {})} className="prev" icon={ARROW_DOWN} />
      {numberOfElementsPrevPage > 0 && <span className="number">{numberOfElementsPrevPage}</span>}
    </div>
  );

  const nextButton = (
    <div className={onNextClick ? 'navigation-arrow' : 'navigation-arrow--disabled'}>
      <IconButton onClick={onNextClick || (() => {})} className="next" icon={ARROW_DOWN} />
    </div>
  );

  return (
    <div className="work-order-list__top">
      <div className="navigation">
        {prevButton}
        <span className="title">{title}</span>
        {nextButton}
      </div>
      {!hideProductList && <ProductList productList={productList} />}
    </div>
  );
}

WorkOrderListTop.propTypes = {
  hideProductList: PropTypes.bool,
  productList: PropTypes.objectOf(PropTypes.any),
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  numberOfElementsPrevPage: PropTypes.number,
};

WorkOrderListTop.defaultProps = {
  productList: {},
  onNextClick: null,
  numberOfElementsPrevPage: null,
  onPrevClick: null,
  hideProductList: false,
};

export default WorkOrderListTop;
