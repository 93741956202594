import React from "react";
import Variables from "../styles/variables.scss";

const SVG = ({ fill }) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>Oval</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="MVP-+-sprint-3-og-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2-helsesjekk-negativ" transform="translate(-39.000000, -104.000000)">
        <g id="Oval" transform="translate(39.000000, 104.000000)">
          <circle fill={fill} cx="10" cy="10" r="10" />
          <g id="Icon/X" strokeWidth="1" transform="translate(5.000000, 5.000000)" fill={Variables.whiteSecondary}>
            <path d="M5,4.44412195 L9.31120423,0.118498483 C9.46405572,-0.0348642382 9.71726438,-0.0400444486 9.87676159,0.106928159 C10.0362588,0.253900767 10.0416462,0.497370657 9.88879473,0.650733378 L5.55402561,5.00000055 L9.88879473,9.34926771 C10.0416462,9.50263044 10.0362588,9.74610032 9.87676159,9.89307293 C9.71726438,10.0400455 9.46405572,10.0348653 9.31120423,9.88150261 L5,5.55587915 L0.688795771,9.88150261 C0.535944277,10.0348653 0.28273562,10.0400455 0.123238408,9.89307293 C-0.0362588036,9.74610032 -0.0416462218,9.50263044 0.111205273,9.34926771 L4.44597439,5.00000055 L0.111205273,0.650733378 C-0.0416462218,0.497370657 -0.0362588036,0.253900767 0.123238408,0.106928159 C0.28273562,-0.0400444486 0.535944277,-0.0348642382 0.688795771,0.118498483 L5,4.44412195 Z" id="Combined-Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SVG.defaultProps = {
  fill: Variables.samRed,
};

export default SVG;
