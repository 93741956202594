import { call, put, takeLatest } from 'redux-saga/effects';
import { FOLLOW_UP_FORMS } from '../../../constants/endpoints';
import { FOLLOW_UP_FORM_REQUEST } from '../FollowUpConstans';
import * as followUpFormActions from '../actions/followUpFormActions';
import { getAPI } from '../../../api/apiConfig';

export function* callGetZendeskForms() {
  try {
    const forms = yield call(getAPI, FOLLOW_UP_FORMS);

    yield put(followUpFormActions.followUpFormsSuccess(forms));
  } catch (error) {
    yield put(
      followUpFormActions.followUpFormsError({
        errorMessage: `Could not get zendesk forms. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(FOLLOW_UP_FORM_REQUEST, callGetZendeskForms)];
