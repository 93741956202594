import {
  CHANGE_PRODUCT_REQUEST,
  CHANGE_PRODUCT_SUCCESS,
  CHANGE_PRODUCT_FAILURE,
  CLEAR_ERROR_MESSAGE,
} from '../customerProductsConstants';

export const requestChangeProduct = (productId, dpadId, serialNumber, reload) => ({
  type: CHANGE_PRODUCT_REQUEST,
  productId,
  dpadId,
  serialNumber,
  reload,
});

export const receiveChangeProduct = ({ changedProduct, oldSerial, productName }) => ({
  type: CHANGE_PRODUCT_SUCCESS,
  changedProduct,
  oldSerial,
  productName,
});

export const errorChangeProduct = ({ errorMessage, errorProducts }) => ({
  type: CHANGE_PRODUCT_FAILURE,
  errorMessage,
  errorProducts,
});

export const clearErrorMessage = ({ errorProducts }) => ({
  type: CLEAR_ERROR_MESSAGE,
  errorProducts,
});
