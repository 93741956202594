import React from 'react';
import PropTypes from 'prop-types';
import ClickOutHandler from 'react-onclickout';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import { ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import { iconBlue } from '../../styles/variables.scss';

export default function QuantityDropdown({
  amount,
  dropDownValues,
  selected,
  showDropdown,
  toggleDropdown,
  value,
}) {
  return (
    <div className="quantity__dropdown">
      <ClickOutHandler onClickOut={showDropdown ? () => toggleDropdown() : () => () => {}}>
        <button onClick={toggleDropdown}>
          {amount}
          <span className={showDropdown ? 'rotate-up' : 'rotate-down'}>
            <Icon stroke={iconBlue} name={ARROW_DOWN} />
          </span>
        </button>

        <SlideDown className="slidedown" transitionOnAppear={false} closed={!showDropdown}>
          <SelectSearch
            autofocus
            onChange={selected}
            options={dropDownValues}
            value={value}
            search={false}
          />
        </SlideDown>
      </ClickOutHandler>
    </div>
  );
}

QuantityDropdown.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  selected: PropTypes.func.isRequired,
  dropDownValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  showDropdown: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};
