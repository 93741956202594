import {
  REMOVE_PRODUCT_FAILURE,
  REMOVE_PRODUCT_REQUEST,
  REMOVE_PRODUCT_SUCCESS,
  TO_BE_REMOVED,
  CLEAR_REMOVE_ERROR_MESSAGE,
} from '../customerProductsConstants';

const removeProductReducerDefaultState = {
  agreementDetailId: 0,
  errorMessage: '',
  isFetching: false,
};

export default (
  state = removeProductReducerDefaultState,
  { agreementDetailId, errorMessage, type, removedProductId },
) => {
  switch (type) {
    case TO_BE_REMOVED:
      return {
        ...state,
        agreementDetailId,
      };

    case REMOVE_PRODUCT_REQUEST:
      return {
        isFetching: true,
        agreementDetailId,
      };

    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        removedProductId,
      };

    case REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetching: false,
      };

    case CLEAR_REMOVE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
        isFetching: false,
      };

    default:
      return state;
  }
};
