import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import Icon from '../../../../icons';
import { ARROW_DOWN } from '../../../../constants/iconNames';
import Variables from '../../../../styles/variables.scss';

export default function CategoryDropdown({
  categories,
  toggleCategoryDropdown,
  selectedCategory,
  show,
  type,
  value,
}) {
  const showDropdown = show && categories.length > 0;

  return (
    categories.length > 0 && (
      <div className="category_dropdown">
        <button onClick={() => toggleCategoryDropdown(type)}>
          <span>{value}</span>
          <span
            className={showDropdown ? 'category_dropdown--icon-up' : 'category_dropdown--icon-down'}
          >
            <Icon name={ARROW_DOWN} stroke={Variables.opacityWhiteLight} />
          </span>
        </button>

        <SlideDown className="slidedown" transitionOnAppear closed={!showDropdown}>
          <div className="select-search-box__container">
            {showDropdown && (
              <SelectSearch
                autofocus
                handleClickOutside={() => {}}
                onChange={selectedCategory}
                options={categories}
                value={value}
                search={false}
              />
            )}
          </div>
        </SlideDown>
      </div>
    )
  );
}

CategoryDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleCategoryDropdown: PropTypes.func.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
