import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/customerProducts';
import {
  CUSTOMER_PRODUCTS_REQUEST,
  CUSTOMER_PRODUCTS_REQUEST_BACKGROUND,
} from '../customerProductsConstants';
import { getAPI } from '../../../api/apiConfig';
import { PRODUCTS_INSTALLER, PRODUCTS_TECHNICIAN } from '../../../constants/endpoints';
import { TECHNICIAN } from '../../Authentication/constants';

function* callCustomerProducts({ customerId }) {
  try {
    const role = yield select(state => state.authentication.role);
    const technician = role === TECHNICIAN;
    const url = technician ? PRODUCTS_TECHNICIAN(customerId) : PRODUCTS_INSTALLER(customerId);
    const result = yield call(getAPI, url);

    yield put(actions.receiveCustomerProducts({ customerProducts: result }));
  } catch (error) {
    yield put(
      actions.errorCustomerProducts({
        errorMessage: `Error while trying to get customer products. ${error.message}`,
      }),
    );
  }
}

export default [
  takeLatest(CUSTOMER_PRODUCTS_REQUEST, callCustomerProducts),
  takeLatest(CUSTOMER_PRODUCTS_REQUEST_BACKGROUND, callCustomerProducts),
];
