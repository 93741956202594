import React from 'react';
import PropTypes from 'prop-types';
import './dataFieldRow.scss';

function DatafieldRow({ title, value, symbol }) {
  return (
    <div className="datafield-row">
      <span className="title">{title}</span>
      <span className="value">
        {value} {symbol}
      </span>
    </div>
  );
}

DatafieldRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  symbol: PropTypes.string,
};

DatafieldRow.defaultProps = {
  symbol: null,
};

export default DatafieldRow;
