import React from 'react';
import PropTypes from 'prop-types';
import { AMOUNT_MONEY } from '../../constants/characters';
import { ARROW_DOWN, CROSS_LARGE } from '../../constants/iconNames';
import Icon from '../../icons';
import { whitePrimary } from '../../styles/variables.scss';
import { hours, minutes } from '../../utils/timeCalculator';

export default function ServicecodeContent({
  amount,
  noDropdown,
  removeClick,
  service: { name, price, timeEstimate, quantity },
  technician,
}) {
  const totalMinutes = timeEstimate.toFixed(2) * quantity;

  return (
    <div className={noDropdown ? 'service__long' : 'service__short'}>
      {noDropdown && (
        <div className="service__long__left">
          {amount}
          <Icon stroke={whitePrimary} name={ARROW_DOWN} />
        </div>
      )}

      <div className="service__center">
        <span>{name}</span>

        <div className="service__center--bottom">
          {!technician ? (
            <div className="service__center--bottom-price-duation">
              <span>{`${hours(totalMinutes)} ${minutes(totalMinutes)}`}</span>
              <span>
                {price}
                {AMOUNT_MONEY}
              </span>
            </div>
          ) : (
            <span>
              {price}
              {AMOUNT_MONEY}
            </span>
          )}
        </div>
      </div>

      <button onClick={removeClick}>
        <Icon name={CROSS_LARGE} />
      </button>
    </div>
  );
}

ServicecodeContent.propTypes = {
  amount: PropTypes.number.isRequired,
  noDropdown: PropTypes.bool.isRequired,
  removeClick: PropTypes.func.isRequired,
  service: PropTypes.objectOf(PropTypes.any).isRequired,
  technician: PropTypes.bool.isRequired,
};
