import React from 'react';
import PropTypes from 'prop-types';

import { ARROW_DOWN } from '../../../../../constants/iconNames';
import { EXPANDING, EXPANDED } from '../../../workOrderListConstants';
import Icon from '../../../../../icons';
import NOB from '../../../../../constants/language-strings';
import Variables from '../../../../../styles/variables.scss';

export default function DropdownButton({ onToggle, toggleState }) {
  return (
    <button className="product__list__dropdown" onClick={onToggle}>
      <span>{NOB.PRODUCT_LIST.TODAYS_PRODUCT_LIST}</span>

      <span
        className={
          toggleState === EXPANDING || toggleState === EXPANDED ? 'rotate-up' : 'rotate-down'
        }
      >
        <Icon stroke={Variables.blackPrimary} name={ARROW_DOWN} />
      </span>
    </button>
  );
}

DropdownButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  toggleState: PropTypes.string.isRequired,
};
