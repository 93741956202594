import React from 'react';
import SlideInPage from '../SlideInPage/SlideInPage';
import InfoBox from '../InfoBox/InfoBox';

function UnknownHash() {
  return (
    <SlideInPage mainHeading="" showLeftIcon showRightIcon={false}>
      <InfoBox />
    </SlideInPage>
  );
}

export default UnknownHash;
