import { COMPLETE_STATUS_CHANGE_SUCCESS } from '../workLogConstants';

const updateStatusReducerDefaultState = {
  workOrder: {
    orderedItems: {
      itemSummary: [],
      totalTimeEstimate: 0,
    },
    customer: {
      customerId: 0,
      firstName: '',
      phoneNumber: '',
      surname: '',
    },
    address: {},
    status: '',
    workDescription: '',
    technicalDetails: {},
    capacity: {
      data: [],
    },
  },
  isFetching: false,
  statusChanged: false,
  errorMessage: '',
  status: '',
  checked: '',
};

export default (state = updateStatusReducerDefaultState, { status, type, workOrder }) => {
  switch (type) {
    case COMPLETE_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        workOrder,
        isFetching: false,
        statusChanged: true,
        status,
      };
    default:
      return state;
  }
};
