import {
  PREVIOUS_WORKORDER_LIST_REQUEST,
  PREVIOUS_WORKORDER_LIST_FAILURE,
  PREVIOUS_WORKORDER_LIST_SUCCESS,
} from '../workOrderListConstants';

const prevWorkOrderListReducerDefaultState = {
  errorMessage: '',
  isFetching: false,
  workOrders: [],
  latestPreviousWorkOrderRequest: {},
};

export default (
  state = prevWorkOrderListReducerDefaultState,
  { errorMessage, type, workOrderRequest },
) => {
  switch (type) {
    case PREVIOUS_WORKORDER_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PREVIOUS_WORKORDER_LIST_SUCCESS: {
      const workOrders = !workOrderRequest.first
        ? [...state.workOrders, ...workOrderRequest.content]
        : workOrderRequest.content;

      return {
        ...state,
        isFetching: false,
        errorMessage: '',
        workOrders,
        latestPreviousWorkOrderRequest: workOrderRequest,
      };
    }

    case PREVIOUS_WORKORDER_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };
    default:
      return state;
  }
};
