import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ stroke }) => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="icon/refresh-" transform="translate(-2.000000, -3.000000)" stroke={stroke} strokeWidth="1.75">
        <g id="RCU-icons_button_startforfra" transform="translate(15.494613, 16.000000) scale(-1, 1) rotate(-37.000000) translate(-15.494613, -16.000000) translate(4.994613, 4.187500)">
          <path d="M7.175,2.8 L11.025,2.8 C16.45,2.8 21,7.175 21,12.775 C21,18.375 16.625,22.75 11.025,22.75 C5.425,22.75 1.05,18.375 1.05,12.775" id="Shape" />
          <polyline id="Shape" strokeLinejoin="round" points="10.325 0.35 7.175 2.8 10.325 5.25" />
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  stroke: Variables.blackSecondary,
};

export default SVG;
