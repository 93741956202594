import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import './listItem.scss';
import { iconBlue } from '../../styles/variables.scss';

function ListItem({
  activated,
  activeBorder,
  children,
  isFetching,
  clickable,
  onClick,
  errorMessage,
}) {
  const className = activated
    ? `list-item list_item--activated
    ${activeBorder ? 'list_item--active--border' : 'list_item--inactive--border'}`
    : `list-item
    ${activeBorder ? 'list_item--active--border' : 'list_item--inactive--border'}`;

  const listItem = (
    <div className={className}>
      <div className="list_item-content">
        {isFetching && (
          <div className="list_item--fetching">
            <FadeLoader color={iconBlue} />
          </div>
        )}
        {children}
      </div>
      <div className="error">{errorMessage}</div>
    </div>
  );

  if (clickable) {
    return (
      <button onClick={onClick} className="list_item-button">
        {listItem}
      </button>
    );
  }

  return listItem;
}

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  activated: PropTypes.bool,
  activeBorder: PropTypes.bool,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  errorMessage: PropTypes.string,
};

ListItem.defaultProps = {
  activated: false,
  activeBorder: false,
  children: null,
  isFetching: false,
  onClick: () => {},
  clickable: false,
  errorMessage: '',
};

export default withRouter(ListItem);
