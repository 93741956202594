import React from "react";
import Variables from "../styles/variables.scss";

const SVG = props => (
  <svg width="60px" height="80px" viewBox="0 0 60 79" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icon/vedlegg</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/vedlegg">
            <path d="M40,0 L60,17 L60,74 C60,76.7614237 57.7614237,79 55,79 L6,79 C3.23857625,79 1,76.7614237 1,74 L1,5 C1,2.23857625 3.23857625,5.07265313e-16 6,0 L40,0 Z" id="Combined-Shape" fill="#086670"></path>
            <path d="M40,0 L40,12 C40,14.7614237 42.2385763,17 45,17 L60,17 L60,74 C60,76.7614237 57.7614237,79 55,79 L6,79 C3.23857625,79 1,76.7614237 1,74 L1,5 C1,2.23857625 3.23857625,5.07265313e-16 6,0 L40,0 Z" id="Combined-Shape" fill="#339BA7"></path>
            <text id="PDF" fontFamily="Metric-Semibold, Metric" fontSize="20" fontWeight="500" linespacing="24" letterSpacing="0.945" fill="#F4F4F4" dominanbaseline="middle" textAnchor="middle">
                <tspan x="50%" y="48">{props.title}</tspan>
            </text>
        </g>
    </g>
</svg>
);

export default SVG;
