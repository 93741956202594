import React from 'react';
import PropTypes from 'prop-types';
import NOB from './../../../constants/language-strings';
import styled from 'styled-components';

const ListItemWrapper = styled.li`
  list-style-type: none;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  &:last-child {
    border-bottom: none;
  }
`;

export default function WorkOrderHistoryItem({ workOrderHistoryItem }) {
  return (
    <ListItemWrapper>
      <div>{workOrderHistoryItem.time}</div>
      <div>{`${NOB.WORKORDER_HISTORY.USER}: ${workOrderHistoryItem.userFullName} (${workOrderHistoryItem.userId})`}</div>
      <div>{`${NOB.WORKORDER_HISTORY.STATUS_ID}: ${workOrderHistoryItem.statusId}`}</div>
      <div>
        {`${NOB.WORKORDER_HISTORY.REASON}: ${workOrderHistoryItem.reasonDescription} (${workOrderHistoryItem.reasonKey})`}
      </div>
    </ListItemWrapper>
  );
}

WorkOrderHistoryItem.propTypes = {
  workOrderHistoryItem: PropTypes.shape({
    reasonDescription: PropTypes.string.isRequired,
    reasonKey: PropTypes.number.isRequired,
    statusId: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    userFullName: PropTypes.string.isRequired,
  }).isRequired,
};
