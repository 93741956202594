import { call, put, takeLatest } from 'redux-saga/effects';
import { FIND_SERVICES } from '../../../../constants/endpoints';
import { FIND_SERVICES_REQUEST } from '../../workLogConstants';
import { getAPI } from '../../../../api/apiConfig';
import * as actions from '../../actions/findServiceActions';

export function* callFindServices({ workOrderId }) {
  try {
    const url = FIND_SERVICES({ workOrderId });
    const result = yield call(getAPI, url);

    yield put(actions.findServicesSuccess({ services: result }));
  } catch (error) {
    yield put(
      actions.findServicesError({
        errorMessage: `Could not find service. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(FIND_SERVICES_REQUEST, callFindServices)];
