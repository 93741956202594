import {
  WORKORDER_LIST_REQUEST,
  WORKORDER_LIST_SUCCESS,
  WORKORDER_LIST_FAILURE,
  END_DAY,
  EMPTY_WORKORDER_LIST,
  WORKORDER_STATUS_UPDATE,
  PREVIOUS_WORKORDER_LIST_SUCCESS,
  PREVIOUS_WORKORDER_LIST_REQUEST,
  PREVIOUS_WORKORDER_LIST_FAILURE,
  WORKORDER_LIST_CANCEL,
} from '../workOrderListConstants';

export const requestWorkOrderList = () => ({
  type: WORKORDER_LIST_REQUEST,
});

export const receiveWorkOrderList = ({ workOrders }) => ({
  type: WORKORDER_LIST_SUCCESS,
  workOrders,
});

export const errorWorkOrderList = errorMessage => ({
  type: WORKORDER_LIST_FAILURE,
  errorMessage,
});

export const requestPrevWorkOrderList = pageNumber => ({
  type: PREVIOUS_WORKORDER_LIST_REQUEST,
  pageNumber,
});

export const receivePrevWorkOrderList = workOrderRequest => ({
  type: PREVIOUS_WORKORDER_LIST_SUCCESS,
  workOrderRequest,
});

export const errorPrevWorkOrderList = errorMessage => ({
  type: PREVIOUS_WORKORDER_LIST_FAILURE,
  errorMessage,
});

export const endDayFunction = ({ endDay }) => ({
  type: END_DAY,
  endDay,
});

export const emptyWorkOrderList = () => ({
  type: EMPTY_WORKORDER_LIST,
});

export const updateSingleWorkOrderStatus = (newStatus, workOrderId) => ({
  type: WORKORDER_STATUS_UPDATE,
  newStatus,
  workOrderId,
});

export const workOrderListCancel = () => ({
  type: WORKORDER_LIST_CANCEL,
});
