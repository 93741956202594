import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { hashes } from '../../constants/paths';
import CustomerProductsContainer from '../../modules/CustomerProducts/containers/CustomerProductsContainer';
import './slideInPagePortal.scss';
import HealthCheckContainer from '../../modules/HealthCheck/containers/HealthCheckContainer';
import AddressHistoryContainer from '../../modules/AddressHistory/containers/AddressHistoryContainer';
import FollowUpFormsContainer from '../../modules/FollowUpForms/FollowUpFormsContainer';
import UnknownHash from './UnknownHash';
import NotHome from '../../modules/Workorder/components/notHome/NotHome';
import WorkOrderHistoryContainer from '../../modules/WorkOrderHistory/containers/WorkOrderHistoryContainer';

/* children container needs to be added to array for slide in pages in routes to work properly */
function SlideInPagePortal({ hash }) {
  // keep selected hash i state so content does not disappear on slide out.
  const [selectedHash, setSelectedHash] = useState(hash);

  useEffect(() => {
    // keep previous hash so it does not disappear on slide out
    if (hash !== selectedHash && hash) {
      setSelectedHash(hash);
    }
  }, [hash]);

  // To display containers correctly wrap container in SlideInPage component
  const containers = [
    { hash: hashes.customerProducts, container: <CustomerProductsContainer /> },
    { hash: hashes.addressHistory, container: <AddressHistoryContainer /> },
    { hash: hashes.workOrderHistory, container: <WorkOrderHistoryContainer /> },
    { hash: hashes.healthCheck, container: <HealthCheckContainer /> },
    { hash: hashes.followUp, container: <FollowUpFormsContainer /> },
    { hash: hashes.notHome, container: <NotHome /> },
  ];
  const selectedContainer = containers.find(container => container.hash === selectedHash);

  return ReactDOM.createPortal(
    <CSSTransition
      in={!!hash}
      timeout={450}
      classNames="slide"
      className="slide_in_portal"
      mountOnEnter
      unmountOnExit
    >
      <div>{selectedContainer ? selectedContainer.container : <UnknownHash />}</div>
    </CSSTransition>,
    document.getElementById('slide-in-page'),
  );
}

export default SlideInPagePortal;
