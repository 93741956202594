function getFormattedDate(date) {
  const dateobject = new Date(date);

  return dateobject
    .toLocaleDateString(undefined, { year: '2-digit', month: '2-digit', day: '2-digit' })
    .replace('/', '.')
    .replace('/', '.');
}

export default getFormattedDate;
