import moment from 'moment/moment';
import { DATE_LETTERS } from '../constants/dateFormats';
import weekdays from '../constants/weekdays';

// Set moment to norwegian time
moment.locale('nb');

export default function getDayAsString(day) {
  const date = moment(day).format(DATE_LETTERS).slice(0, -4);
  const today = new Date(day).getDay();
  const weekday = weekdays[today];

  return `${weekday} ${date}`;
}
