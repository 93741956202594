import React from 'react';
import styled from 'styled-components';
import Icon from '../../icons';
import { SEARCH, STATUS_ERROR } from '../../constants/iconNames';
import { http } from '../../api/apiConfig';
import { useQuery } from 'react-query';
import { PebbleSpinner } from './pebble-spinner';
import { CUSTOMER_INFO_URL } from '../../constants/paths';
import withRouter from 'react-router/withRouter';
import { SearchResultItem } from './SearchResultItem';
import { CUSTOMER_SEARCH_API_URL } from '../../constants/endpoints';
import { useSearchStore } from './useSearchStore';

const AddressSearchFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;

const AddressHeading = styled.h1`
  margin-bottom: 18px;
  font-weight: 500;
`;

const AddressAndFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const StyledForm = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 16px;
  padding: 0 0.5rem;
  box-sizing: border-box;
  gap: 0.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextInputField = styled.input.attrs(props => ({
  style: {
    border: '1px solid black',
  },
}))`
  font-size: 18px;
  margin-right: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;

  :focus {
    border: 2px solid rgb(240, 127, 165);
    outline: none;
  }
`;

const StyledButton = styled.button`
  width: 4rem;
  height: 2.7rem;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 0.4rem;
  border: 1px solid black;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  background: black;
  margin-top: 6px;

  &:checked:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #ffe500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ButtonAndCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddressSearchResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const ResultsWrapper = styled.div`
  background-color: rgb(232, 232, 237);
  border-radius: 0.333333rem;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledScrollWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(220, 220, 225);
    border-radius: 5px;
    outline: none;
    width: 5px;
  }
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 18px;
  justify-content: center;
  border-radius: 0.133333rem;
  border-width: 0.4rem 0.133333rem 0.133333rem;
  border-style: solid;
  border-color: rgb(240, 127, 165);
  margin-top: 32px;
  margin-bottom: 0;
  margin-left: 12px;
  margin-right: 12px;
  padding: 16px 36px;
`;

const ErrorText = styled.div`
  margin-left: 8px;
`;

const ResultListItem = styled.li`
  display: flex;
  &:nth-child(odd) {
    background-color: rgb(220, 220, 225);
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  margin: 0 auto 0;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const LoadingText = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 12px;
`;

export const AddressSearchForm = withRouter(({ history }) => {
  const {
    searchInput,
    setSearchInput,
    searchQuery,
    setSearchQuery,
    onlyActive,
    setSearchOnlyActive,
    cache,
    setCache,
    cacheKey,
    setCacheKey,
  } = useSearchStore();

  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ['search', searchQuery, onlyActive],
    queryFn: async () => {
      if (cacheKey === searchQuery) {
        return cache;
      }

      const { data } = await http.get(CUSTOMER_SEARCH_API_URL, {
        params: {
          q: searchQuery,
          status: onlyActive ? 'Active' : '',
        },
        timeout: 40000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      });

      setCacheKey(searchQuery);
      setCache(data);

      return data;
    },
    enabled: searchQuery !== '',
  });

  function onSearchClicked() {
    if (searchInput !== searchQuery) {
      setSearchQuery(searchInput);
    } else {
      setCacheKey('');
      setCache(null);
      window.setTimeout(() => {
        void refetch();
      }, 1);
    }
  }

  return (
    <AddressSearchFormWrapper>
      <AddressHeading>KUNDESØK</AddressHeading>
      <AddressAndFormWrapper>
        <StyledForm autoComplete="off" handleSubmit={() => false}>
          <InputContainer>
            <StyledTextInputField
              name="query"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
            />
          </InputContainer>
          <ButtonAndCheckboxWrapper>
            <StyledButton
              type="submit"
              onClick={e => {
                e.preventDefault();
                onSearchClicked();
              }}
            >
              <Icon color={'#1e1c1e'} name={SEARCH} />
            </StyledButton>
            {data && (
              <CheckboxWrapper>
                Active
                <StyledCheckbox
                  type="checkbox"
                  checked={onlyActive}
                  onChange={() => setSearchOnlyActive(!onlyActive)}
                />
              </CheckboxWrapper>
            )}
          </ButtonAndCheckboxWrapper>
        </StyledForm>
        {isFetching ? (
          <LoaderContainer>
            <PebbleSpinner />
            <LoadingText>Søker etter kunder...</LoadingText>
          </LoaderContainer>
        ) : (
          <AddressSearchResultsWrapper>
            {data && !!data.customers.length && (
              <ResultsWrapper>
                <StyledScrollWrapper as="ul">
                  {data.customers.map(it => (
                    <ResultListItem key={it.customerId}>
                      <SearchResultItem
                        customer={it}
                        onClick={() => history.push(CUSTOMER_INFO_URL(it.customerId))}
                      />
                    </ResultListItem>
                  ))}
                </StyledScrollWrapper>
              </ResultsWrapper>
            )}
            {data && data.customers.length === 0 && (
              <Error>
                <Icon fill={'#F07FA5FF'} name={STATUS_ERROR} />
                <ErrorText>Ingen kunder funnet etter et gitt kriterium.</ErrorText>
              </Error>
            )}
            {isError && (
              <Error>
                <Icon fill={'#F07FA5FF'} name={STATUS_ERROR} />
                <ErrorText>Uventet feil i svar</ErrorText>
              </Error>
            )}
          </AddressSearchResultsWrapper>
        )}
      </AddressAndFormWrapper>
    </AddressSearchFormWrapper>
  );
});
