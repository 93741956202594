export const POWER = 'POWER';
export const MIN_POWER = 'MIN_POWER';
export const MAX_POWER = 'MAX_POWER';

export const SNR = 'SNR';
export const MIN_SNR = 'MIN_SNR';
export const MAX_SNR = 'MAX_SNR';

export const CORRECTED = 'CORRECTED';
export const UNCORRECTABLE = 'UNCORRECTABLE';

export const DOWNSTREAM = 'DOWNSTREAM';
export const UPSTREAM = 'UPSTREAM';
