import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ stroke }) => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="icon/forstørre" stroke={stroke}>
            <g id="Arrow/Arrow-left_svart" transform="translate(14.435029, 33.435029) rotate(-45.000000) translate(-14.435029, -33.435029) translate(4.435029, 24.435029)">
                <g id="Group" transform="translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 3.000000)">
                    <polyline id="Path-2" strokeWidth="2" transform="translate(15.000000, 6.000000) scale(-1, 1) rotate(90.000000) translate(-15.000000, -6.000000) " points="9 3 15 9 21 3" />
                    <path d="M17,6 L0,6" id="Line" strokeWidth="2" />
                </g>
            </g>
            <g id="Arrow/Arrow-left_svart" transform="translate(33.435029, 14.435029) scale(-1, 1) rotate(45.000000) translate(-33.435029, -14.435029) translate(23.435029, 5.435029)">
                <g id="Group" transform="translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 3.000000)">
                    <polyline id="Path-2" strokeWidth="2" transform="translate(15.000000, 6.000000) scale(-1, 1) rotate(90.000000) translate(-15.000000, -6.000000) " points="9 3 15 9 21 3" />
                    <path d="M17,6 L0,6" id="Line" strokeWidth="2" />
                </g>
            </g>
        </g>
    </g>
  </svg>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  stroke: Variables.blackPrimary,
};

export default SVG;
