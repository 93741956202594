import {
  FIND_SERVICE_TYPES_SUCCESS,
  FIND_SERVICE_TYPES_ERROR,
  SELECT_SERVICE_TYPE,
  UPDATE_DIAGNOSTIC_SERVICE,
} from '../createNewWorkOrderConstants';

export const findServiceTypesSuccess = ({ serviceTypes }) => ({
  type: FIND_SERVICE_TYPES_SUCCESS,
  serviceTypes,
});

export const findServiceTypesError = ({ errorMessage }) => ({
  type: FIND_SERVICE_TYPES_ERROR,
  errorMessage,
});

export const selectServiceType = ({ serviceType }) => ({
  type: SELECT_SERVICE_TYPE,
  serviceType,
});

export const updateDiagnosticServices = ({ selectedDiagnosticServices }) => ({
  type: UPDATE_DIAGNOSTIC_SERVICE,
  selectedDiagnosticServices,
});
