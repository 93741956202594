import {
  WORKORDER_SERVICES_REQUEST,
  WORKORDER_SERVICES_SUCCESS,
  WORKORDER_SERVICES_ERROR,
} from '../workLogConstants';

export const workorderServicesRequest = workOrderId => ({
  type: WORKORDER_SERVICES_REQUEST,
  workOrderId,
});

export const workorderServicesSuccess = ({ services }) => ({
  type: WORKORDER_SERVICES_SUCCESS,
  services,
});

export const workorderServicesError = ({ errorMessage }) => ({
  type: WORKORDER_SERVICES_ERROR,
  errorMessage,
});
