import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { withRouter } from 'react-router';
import CustomerInfoContainer from '../../components/customerInfo/CustomerInfoContainer';
import Feedback from '../../../../feedbacks';
import FetchingSpinner from '../../../../utils/FetchingSpinner';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import GoogleMapsButton from '../../../../components/Popup/components/GoogleMapsButton';
import Popup from '../../../../components/Popup/containers/Popup';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import MapContainer from '../../../Map/containers/MapContainer';
import NOB from '../../../../constants/language-strings';
import TechnicalDetails from '../../components/technicalDetails/TechnicalDetails';
import { EXTERNAL_INSTALLER } from '../../../Authentication/constants';
import { SCHEDULED_ACTIVATION, WAITING_ACTIVATION } from '../../../../constants/productStatuses';
import UNAUTHORIZED from '../../../../constants/errorStatusCodes';
import * as toggleAction from '../../../../components/Popup/actions/popupActions';
import '../../../../components/Popup/popup.scss';
import './customerPage.scss';
import NavigationButton from '../../../../components/Buttons/NavigationButton/NavigationButton';
import {
  CUSTOMER_INFO_ADDRESS_HISTORY_URL,
  CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL,
  CUSTOMER_INFO_HEALTH_CHECK_URL,
} from '../../../../constants/paths';
import Icon from '../../../../icons';
import Variables from '../../../../styles/variables.scss';
import { GET_BOX, HEALTH_CHECK, HISTORY } from '../../../../constants/iconNames';
import { requestWorkOrderByCustomerInfo } from '../../actions/workOrder';

class CustomerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completed: false,
    };
  }

  componentDidMount() {
    const { customerId, dispatchAll } = this.props;

    dispatchAll(customerId);
  }

  componentDidUpdate(prevProps) {
    const { dispatchAll, customerId } = this.props;

    if (customerId !== prevProps.customerId) {
      dispatchAll(customerId);
    }
  }

  render() {
    const {
      addressHistory,
      close,
      customerProducts,
      match,
      popupType,
      togglePopupFunc,
      workOrder: { workOrder, errorMessage, isFetching },
      customerId,
      areProductsFetching,
    } = this.props;

    const waitingActivationsLength = customerProducts.filter(
      waitingActivation =>
        waitingActivation.status === WAITING_ACTIVATION ||
        waitingActivation.status === SCHEDULED_ACTIVATION,
    ).length;

    const newWorkOrderEntered = !customerId || match.params.id !== `${customerId}`;

    if (isFetching) {
      return (
        <div className="work__order__container">
          <FetchingSpinner />
        </div>
      );
    }

    const noAccess = errorMessage.includes(UNAUTHORIZED);

    if (errorMessage) {
      return (
        <div className="work__order__container">
          <InfoBox
            feedback={<Feedback />}
            link={noAccess}
            secondInfoBoxLine={noAccess ? NOB.NO_WORK_ORDERS.NO_ACCESS : errorMessage}
          />
        </div>
      );
    }

    if (workOrder === undefined || workOrder.workOrderId === 0) {
      return (
        <div className="work__order__container">
          <InfoBox
            feedback={<Feedback />}
            link={true}
            secondInfoBoxLine="Unable to retrieve work order information. Try to refresh browser or login again."
          />
        </div>
      );
    }

    return (
      <div className="work__order__container">
        <div>
          <div className="work__order">
            <div className="work__order__content">
              <div className="work__order__content--top">
                <div className="work__order__content--customer-info-container">
                  <CustomerInfoContainer
                    address={workOrder.address}
                    addressDetails={workOrder.addressDetails}
                    customer={workOrder.customer}
                    togglePopup={togglePopupFunc}
                    workOrder={workOrder}
                  />
                </div>

                {addressHistory.length > 0 && (
                  <NavigationButton
                    URL={CUSTOMER_INFO_ADDRESS_HISTORY_URL(customerId)}
                    leftIcon={<Icon name={HISTORY} />}
                    title={NOB.WORKORDER_TABS.ADDRESS_HISTORY}
                    number={addressHistory.length}
                  />
                )}

                {/* Customer Products button */}
                <NavigationButton
                  URL={CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL(customerId)}
                  title={NOB.CUSTOMER_PRODUCTS.CUSTOMER_PRODUCTS}
                  leftIcon={
                    areProductsFetching ? (
                      <ClipLoader size={16} color={Variables.grayButton} />
                    ) : (
                      <Icon
                        fill={
                          waitingActivationsLength === 0 ? Variables.iconBlue : Variables.buttonRed
                        }
                        name={GET_BOX}
                      />
                    )
                  }
                  number={waitingActivationsLength > 0 ? waitingActivationsLength : null}
                  numberColor={waitingActivationsLength > 0 ? NOB.COMPONENT.RED : null}
                />

                {/* HealthCheck button */}
                <NavigationButton
                  URL={CUSTOMER_INFO_HEALTH_CHECK_URL(customerId)}
                  leftIcon={<Icon name={HEALTH_CHECK} />}
                  title={NOB.HEALTH_CHECK.HEALTH_CHECK}
                />

                <TechnicalDetails
                  technicalDetails={workOrder.technicalDetails}
                  capacity={workOrder?.capacity?.data}
                />
              </div>

              {!close && popupType === GOOGLE_MAPS && (
                <Popup
                  firstLine={NOB.GOOGLE_MAPS_POPUP.GOOGLE_MAPS}
                  secondLine={NOB.GOOGLE_MAPS_POPUP.QUESTION}
                  button={<GoogleMapsButton />}
                />
              )}

              <MapContainer newWorkOrderEntered={newWorkOrderEntered} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerPage.propTypes = {
  addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
  close: PropTypes.bool,
  customerProducts: PropTypes.arrayOf(PropTypes.any),
  isFetching: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchAll: PropTypes.func.isRequired,
  popupType: PropTypes.string,
  role: PropTypes.string,
  togglePopupFunc: PropTypes.func.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any),
  customerId: PropTypes.number,
  areProductsFetching: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

CustomerPage.defaultProps = {
  close: true,
  customerProducts: [],
  popupType: '',
  workOrder: {
    workOrder: {},
    errorMessage: '',
  },
  role: EXTERNAL_INSTALLER,
  customerId: 0,
  isFetching: false,
};

const mapStateToProps = state => ({
  addressHistory: state.addressHistory.addressHistory,
  close: state.popup.close,
  customerProducts: state.customerProducts.customerProducts,
  isPrimaryFetching: state.workOrder.isPrimaryFetching,
  areProductsFetching: state.customerProducts.isFetching,
  popupType: state.popup.popupType,
  role: state.authentication.role,
  workOrder: state.workOrder,
});

const mapDispatchToProps = dispatch => ({
  dispatchAll: customerId => {
    dispatch(requestWorkOrderByCustomerInfo(customerId));
  },
  togglePopupFunc: (close, popupType) => dispatch(toggleAction.togglePopup({ close, popupType })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPage));
