import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';

function HealthCheckTabContent({ categoryValues }) {
  return categoryValues.map(categoyValue => {
    // Table columns are based on the element with the longest list of values(propertyValues)
    const columnValueTypes = categoyValue.subcategoryValues
      .reduce((a, b) => (a.propertyValues.length > b.propertyValues.length ? a : b))
      .propertyValues.map(value => value.type);

    // Construct table columns. Based on the category tag as the first column and values from all tags above
    const columns = [...columnValueTypes];

    columns.unshift(categoyValue.description);

    return (
      <div className="table-wrapper" key={categoyValue.name}>
        {categoyValue.name && <span className="title">{categoyValue.name}</span>}
        <Table
          columns={columns}
          rows={categoyValue.subcategoryValues}
          columnValueTypes={columnValueTypes}
        />
      </div>
    );
  });
}

HealthCheckTabContent.propTypes = {
  categoryValues: PropTypes.arrayOf(
    PropTypes.shape({
      subcategoryValues: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          propertyValues: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string.isRequired,
              value: PropTypes.objectOf(
                PropTypes.shape({
                  first: PropTypes.objectOf(
                    PropTypes.shape({
                      value: PropTypes.string.isRequired,
                      valueStatus: PropTypes.string.isRequired,
                    }),
                  ),
                  second: PropTypes.objectOf(
                    PropTypes.shape({
                      value: PropTypes.string.isRequired,
                      valueStatus: PropTypes.string.isRequired,
                    }),
                  ),
                }),
              ).isRequired,
            }),
          ).isRequired,
        }),
      ),
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HealthCheckTabContent;
