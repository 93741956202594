import React from 'react';
import PropTypes from 'prop-types';
import './radio-button-group.scss';
import RadioButton from '../Buttons/RadioButton/RadioButton';

function RadioButtonGroup({ title, values, onChange, chosenValue, hasError }) {
  const radioButtons = values.map(value => (
    <RadioButton
      name={title}
      onClick={onChange}
      checked={chosenValue === value}
      label={value}
      key={value}
      value={value}
    />
  ));

  const label = hasError ? `* ${title}` : title;
  const className = hasError ? 'radio-group--error' : 'radio-group';

  return (
    <div className={className}>
      <label className="radio-group-label">{label}</label>
      <div className="radio-buttons">{radioButtons}</div>
    </div>
  );
}

RadioButtonGroup.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  chosenValue: PropTypes.string,
  hasError: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  chosenValue: '',
  hasError: false,
};

export default RadioButtonGroup;
