import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import DeviceStatusList from '../components/DeviceStatusList/DeviceStatusList';
import HealthCheckHeader from '../components/healthCheck/HealthCheckHeader';
import HealthCheckList from '../components/healthCheck/HealthCheckList';
import NOB from '../../../constants/language-strings';
import * as action from '../actions';
import '../healthCheck.scss';
import InfoBoxError from '../../../components/InfoBox/InfoBoxError';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import { REFRESH } from '../../../constants/iconNames';
import {
  requestUpdateDevicePollingMac,
  requestDevicePollingMac,
} from '../actions/devicePollingMacActions';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
import Feedback from '../../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../../constants/feedbackNames';
import DeviceHealthCheckContainer from '../../DeviceHealthCheck/DeviceHealthCheckContainer';
import Sam from './Sam';
import Platform from '../../../constants/platform';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import HitDecoderContainer from '../../HealthCheck/provisioning/HitDecoderContainer';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
    },
  },
});

function HealthCheckContainer({
  customerId,
  requestHealthCheck,
  requestDevicePollingAllDevices,
  requestIccCheck,
  healthCheck,
  requestUpdateHealthCheck,
  devicePollingAllDevices,
  isFetching,
  isIccFetching,
  isFetchingProvisioning,
  devicePollingError,
  isUpdateFetching,
  devicePollingSingleDeviceError,
  requestUpdateDevicePollingSingleDevice,
  requestDevicePollingSingleDevice,
  languageStrings,
}) {
  const [displaySam, setDisplaySam] = useState(false);
  const [displaySamDevice, setDisplaySamDevice] = useState({});
  const [IsVisibleHitDecoderModal, setIsVisibleHitDecoderModal] = useState(false);

  useEffect(() => {
    if (customerId !== 0) {
      requestHealthCheck(customerId);
      requestDevicePollingAllDevices(customerId);
      requestIccCheck(customerId);
    }
  }, [customerId]);

  const updateHealthCheck = () => {
    const healthCheckIds = healthCheck.provisioning.healthCheckResponses
      .filter(filterResponse => filterResponse.canUpdate)
      .map(mapResponse => mapResponse.id);

    requestUpdateHealthCheck(customerId, healthCheckIds);
  };

  const toggleDisplaySam = selectedProduct => {
    setDisplaySam(!displaySam);

    if (selectedProduct) {
      setDisplaySamDevice(selectedProduct);
    } else {
      setDisplaySamDevice('');
    }
  };

  const closeHealthCheckModal = event => {
    event.persist();
    setDisplaySam(false);
    setDisplaySamDevice('');
  };

  const refresh = () =>
    new Promise(() => {
      if (!isUpdateFetching && !devicePollingSingleDeviceError && displaySamDevice.pollIdentifier) {
        requestUpdateDevicePollingSingleDevice(displaySamDevice.pollIdentifier);
      } else {
        requestDevicePollingSingleDevice(displaySamDevice.pollUrl);
      }
    });

  return (
    <SlideInPage mainHeading={NOB.HEALTH_CHECK.HEALTH_CHECK} showLeftIcon showRightIcon={false}>
      <div className="healthCheck__workOrder__details">
        <HealthCheckHeader
          header={NOB.HEALTH_CHECK.PROVISIONING_HEADING}
          healthCheck={healthCheck}
          updateHealthCheck={updateHealthCheck}
          deviceCheck={devicePollingAllDevices.checkPassedStatus}
          isFetching={isFetching}
          isIccFetching={isIccFetching}
          isFetchingProvisioning={isFetchingProvisioning}
        />

        <HealthCheckList
          updateHealthCheck={updateHealthCheck}
          healthCheck={healthCheck}
          devicePollingCheckPassed={devicePollingAllDevices.checkPassed}
          isIccFetching={isIccFetching}
          isFetchingProvisioning={isFetchingProvisioning}
        />

        <div className="device__status__container">
          <div className="device__status__container__heading">{NOB.HEALTH_CHECK.DEVICE_STATUS}</div>
          {devicePollingAllDevices.deviceStatus.length > 0 && !isFetching && (
            <DeviceStatusList
              devices={devicePollingAllDevices.deviceStatus}
              toggleDisplaySam={toggleDisplaySam}
              error={devicePollingError}
            />
          )}
          {!devicePollingAllDevices.deviceStatus.length && !isFetching && (
            <InfoBoxError
              feedback={<Feedback name={FEEDBACK_NOT_FOUND} />}
              firstInfoBoxLine={devicePollingError || languageStrings.NO_DEVICES_FOUND}
              link={false}
            />
          )}
        </div>
        <div className="device__status__container">
          <SimpleButton
            color={Color.BLACK}
            title="Reprovisjoner boks"
            onClick={() => {
              setIsVisibleHitDecoderModal(state => !state);
            }}
          />
        </div>
      </div>
      {
        // TODO: Refactor HFC endpoint and remove this conditional render
        devicePollingAllDevices.platform.toUpperCase() === Platform.HFC ? (
          <SlideInModal
            display={displaySam}
            onClose={closeHealthCheckModal}
            mainHeading={displaySamDevice.description}
            leftIcon={
              isUpdateFetching ? (
                <ClipLoader size={20} />
              ) : (
                <IconButton onClick={refresh} icon={REFRESH} className="sam_refresh-button" />
              )
            }
          >
            <Sam
              pollIdentifier={displaySamDevice.pollIdentifier}
              pollUrl={displaySamDevice.pollUrl}
            />
          </SlideInModal>
        ) : (
          <DeviceHealthCheckContainer
            {...displaySamDevice}
            display={displaySam}
            onClose={closeHealthCheckModal}
          />
        )
      }
      <QueryClientProvider client={queryClient}>
        <HitDecoderContainer
          isVisible={IsVisibleHitDecoderModal}
          customerId={customerId}
          onClose={() => setIsVisibleHitDecoderModal(state => !state)}
        />
      </QueryClientProvider>
    </SlideInPage>
  );
}

HealthCheckContainer.propTypes = {
  customerId: PropTypes.number,
  devicePollingAllDevices: PropTypes.objectOf(PropTypes.any),
  devicePollingError: PropTypes.string.isRequired,
  healthCheck: PropTypes.objectOf(PropTypes.any),
  isFetching: PropTypes.bool.isRequired,
  isFetchingProvisioning: PropTypes.bool.isRequired,
  isUpdateFetching: PropTypes.bool.isRequired,
  isIccFetching: PropTypes.bool.isRequired,
  requestDevicePollingAllDevices: PropTypes.func.isRequired,
  requestHealthCheck: PropTypes.func.isRequired,
  requestIccCheck: PropTypes.func.isRequired,
  requestUpdateDevicePollingSingleDevice: PropTypes.func.isRequired,
  requestDevicePollingSingleDevice: PropTypes.func.isRequired,
  requestUpdateHealthCheck: PropTypes.func.isRequired,
  devicePollingSingleDeviceError: PropTypes.string.isRequired,
  languageStrings: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
    .isRequired,
};

HealthCheckContainer.defaultProps = {
  customerId: null,
  devicePollingAllDevices: {},
  healthCheck: {},
};

const mapStateToProps = state => ({
  customerId: state.workOrder?.workOrder?.customer?.customerId || 0,
  devicePollingSingleDeviceError: state.devicePollingMac.errorMessage,
  workOrder: state.workOrder,
  customerProducts: state.customerProducts.customerProducts,
  devicePollingAllDevices: state.devicePollingCustomer.devicePollingCustomer,
  healthCheck: state.healthCheck,
  isIccFetching: state.healthCheck.isIccFetching,
  isFetching: state.devicePollingCustomer.isFetching,
  devicePollingError: state.devicePollingCustomer.errorMessage,
  isFetchingProvisioning: state.healthCheck.isFetching,
  isUpdateFetching: state.updateDevicePollingMac.isFetching,
  languageStrings: state.NOB.HEALTH_CHECK,
});

const mapDispatchToProps = dispatch => ({
  requestHealthCheck: customerId => dispatch(action.requestHealthCheck({ customerId })),
  requestUpdateHealthCheck: (customerId, healthCheckIds) =>
    dispatch(action.requestUpdateHealthCheck({ customerId, healthCheckIds })),
  requestDevicePollingAllDevices: customerId =>
    dispatch(action.requestDevicePollingCustomer({ customerId })),
  requestIccCheck: customerId => dispatch(action.requestIccCheck(customerId)),
  requestUpdateDevicePollingSingleDevice: macAddress =>
    dispatch(requestUpdateDevicePollingMac({ macAddress })),
  requestDevicePollingSingleDevice: pollUrl => dispatch(requestDevicePollingMac(pollUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthCheckContainer);
