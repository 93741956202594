import React from 'react';
import { AddressSearchForm } from './address-search-form';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
    },
  },
});

export const AddressSearch = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AddressSearchForm />
      </QueryClientProvider>
    </>
  );
};
