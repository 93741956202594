import {
  CUSTOMER_PRODUCTS_REQUEST,
  CUSTOMER_PRODUCTS_SUCCESS,
  CUSTOMER_PRODUCTS_FAILURE,
  CUSTOMER_PRODUCTS_UPDATE,
  CUSTOMER_PRODUCTS_REQUEST_BACKGROUND,
  UPDATE_SINGLE_PRODUCT,
} from '../customerProductsConstants';

export const requestCustomerProducts = action => ({
  type: CUSTOMER_PRODUCTS_REQUEST,
  customerId: action.customerId,
});

export const requestCustomerProductsBackground = customerId => ({
  type: CUSTOMER_PRODUCTS_REQUEST_BACKGROUND,
  customerId,
});

export const receiveCustomerProducts = action => ({
  type: CUSTOMER_PRODUCTS_SUCCESS,
  customerProducts: action.customerProducts,
});

export const errorCustomerProducts = action => ({
  type: CUSTOMER_PRODUCTS_FAILURE,
  errorMessage: action.errorMessage,
});

export const updateCustomerProducts = action => ({
  type: CUSTOMER_PRODUCTS_UPDATE,
  customerProducts: action.customerProducts,
});

export const updateSingleProduct = newProduct => ({
  type: UPDATE_SINGLE_PRODUCT,
  newProduct,
});
