import { CATEGORY_MAIN, CATEGORY_SUB } from '../modules/AddServiceCode/addServiceCodeConstants';
import { MAIN, SUB } from '../constants/elements';
import setCategoriesValues from './setCategoriesValues';

export default function setCategoriesDropdown(category, allCategories, main, sub) {
  let values = [];

  switch (category) {
    case CATEGORY_MAIN: {
      // Categories containing a sub category
      const mainCategoriesMultiple = allCategories.filter(singleCategory => singleCategory.sub);

      // Categories not containing a sub category
      const mainCategoriesSingle = allCategories.filter(singleCategory => !singleCategory.sub);

      values = setCategoriesValues(mainCategoriesMultiple, mainCategoriesSingle, MAIN);

      return values;
    }

    case CATEGORY_SUB: {
      // Categories containing the selected main category and a sub category
      const subCategories = allCategories.filter(
        singleCategory => singleCategory.main === main && singleCategory.sub,
      );

      // Filtered categories containing a main error
      const subCategoriesMultiple = subCategories.filter(singleCategory => singleCategory.error);

      // Filtered categories not containing a main error
      const subCategoriesSingle = subCategories.filter(singleCategory => !singleCategory.error);

      values = setCategoriesValues(subCategoriesMultiple, subCategoriesSingle, SUB);

      return values;
    }

    default: {
      // Categories containing the selected main category and a sub category
      const errorCategories = allCategories.filter(
        singleCategory =>
          singleCategory.main === main && singleCategory.sub === sub && singleCategory.error,
      );

      values = errorCategories.map(service => ({
        name: service.error,
        value: service.id,
      }));

      return values;
    }
  }
}
