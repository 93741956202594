import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SEARCH } from '../../../constants/iconNames';
import Icon from '../../../icons';
import Variables from '../../../styles/variables.scss';
import '../addServiceListSearch.scss';

const AddServiceListSearch = ({ options, placeholder, onChange, renderOption }) => (
  <div className="addService_list_search-container">
    <span className="addService_list_search-icon">
      <Icon color={Variables.blackPrimary} name={SEARCH} />
    </span>
    <SelectSearch
      autofocus={false}
      className="addService_list_search"
      onChange={onChange}
      options={options}
      search
      renderOption={renderOption}
      placeholder={placeholder}
    />
  </div>
);

AddServiceListSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
  renderOption: PropTypes.func.isRequired,
};

export default AddServiceListSearch;
