import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddServiceCodeContainer from '../../AddServiceCode/containers/AddServiceCodeContainer';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import { NEARLY_DONE, COMPLETE, INCOMPLETE } from '../../../constants/status-codes';
import ServiceListContainer from '../../ServiceList/ServiceListContainer';
import * as findServicesAction from '../actions/findServiceActions';
import * as updateServiceAction from '../actions/updateServiceActions';
import * as workOrderAction from '../../Workorder/actions/workOrder';
import './workLog.scss';
import WorkOrderDetails from '../../Workorder/components/workOrderDetails/WorkOrderDetails';
import { workorderServicesRequest } from '../actions/workOrderServiceActions';
import WorkOrderCompletionFormContainer from '../../WorkOrderCompletionForm/containers/WorkOrderCompletionFormContainer';

class WorkLogContainer extends Component {
  componentDidMount() {
    const {
      findServicesRequest,
      pathname,
      requestStatusChange,
      match,
      requestWorkOrderServices,
      workOrder: { workOrder },
    } = this.props;

    requestWorkOrderServices(match.params.id);

    findServicesRequest(pathname.replace(/\D/g, ''));

    if (workOrder.isThreeSixty && workOrder.status !== NEARLY_DONE) {
      requestStatusChange(workOrder.status);
    }
  }

  render() {
    const {
      findServices: { isFetchingServices },
      workOrder: { workOrder },
      isFetchingWorkOrderServices,
      orderedItems,
    } = this.props;

    const done = COMPLETE.includes(workOrder.status) || INCOMPLETE.includes(workOrder.status);

    return (
      <div className="work__log">
        <div className="work__log__content">
          <WorkOrderDetails
            done={done}
            workOrder={workOrder}
            totalTimeEstimate={orderedItems.totalTimeEstimate}
            inWorkLog
          />
          {isFetchingWorkOrderServices || isFetchingServices ? (
            <FetchingSpinner />
          ) : (
            <ServiceListContainer />
          )}

          {!isFetchingServices && (
            <AddServiceCodeContainer
              addServiceCode
              workOrderServices={orderedItems.itemSummary.map(item => item.id)}
            />
          )}
          <WorkOrderCompletionFormContainer />
        </div>
      </div>
    );
  }
}

WorkLogContainer.propTypes = {
  findServices: PropTypes.objectOf(PropTypes.any),
  findServicesRequest: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  requestStatusChange: PropTypes.func.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetchingWorkOrderServices: PropTypes.bool.isRequired,
  orderedItems: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  requestWorkOrderServices: PropTypes.func.isRequired,
};

WorkLogContainer.defaultProps = {
  findServices: { services: [] },
};

const mapStateToProps = state => ({
  findServices: state.findServices,
  pathname: state.router.location.pathname,
  workOrder: state.workOrder,
  workOrderServices: state.workOrderServices.orderedItems.itemSummary,
  isFetchingWorkOrderServices: state.workOrderServices.isFetchingServices,
  orderedItems: state.workOrderServices.orderedItems,
});

const mapDispatchToProps = dispatch => ({
  findServicesRequest: workOrderId =>
    dispatch(findServicesAction.findServicesRequest({ workOrderId })),
  requestStatusChange: currentStatus =>
    dispatch(workOrderAction.requestStatusChange(currentStatus)),
  requestUpdateService: (workOrderId, serviceId, quantity, added) =>
    dispatch(
      updateServiceAction.requestUpdateService({
        workOrderId,
        serviceId,
        quantity,
        added,
      }),
    ),
  requestWorkOrderServices: workOrderId => dispatch(workorderServicesRequest(workOrderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkLogContainer);
