// Map
export const ATTRIBUTION = 'attribution';
export const GEOCODE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
export const LATITUDE = 59.9544862;
export const LONGITUDE = 10.760517;
export const NO = 'NO';
export const SCALE = 200;
export const UTM = '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
export const WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
export const WKID = 25833;

// Map id
export const MAP_ID_FAILURE = 'MAP_ID_FAILURE';
export const MAP_ID_REQUEST = 'MAP_ID_REQUEST';
export const MAP_ID_SUCCESS = 'MAP_ID_SUCCESS';

// Map token
export const MAP_TOKEN_FAILURE = 'MAP_TOKEN_FAILURE';
export const MAP_TOKEN_REQUEST = 'MAP_TOKEN_REQUEST';
export const MAP_TOKEN_SUCCESS = 'MAP_TOKEN_SUCCESS';

export const DEVELOP = 'develop';
export const ORIGIN = 'http://localhost:8080';
