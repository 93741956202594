import React from "react";
import PropTypes from "prop-types";
import { iconBlue } from "../styles/variables.scss";

const SVG = ({ stroke }) => (
  <svg width="25px" height="22px" viewBox="0 0 25 22" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/history" transform="translate(-1.000000, 1.000000)">
            <path d="M8,6.7254834 L8,12.0426407 C8,12.1530976 7.91045695,12.2426407 7.8,12.2426407 L2.48284271,12.2426407 C2.37238576,12.2426407 2.28284271,12.1530976 2.28284271,12.0426407 C2.28284271,11.9895974 2.30391408,11.9387266 2.34142136,11.9012193 L7.65857864,6.58406204 C7.7366835,6.50595719 7.8633165,6.50595719 7.94142136,6.58406204 C7.97892863,6.62156932 8,6.6724401 8,6.7254834 Z" id="Rectangle" fill="#086670" transform="translate(5.000000, 9.242641) rotate(45.000000) translate(-5.000000, -9.242641) "></path>
            <path d="M14.7573593,20 C20.2802068,20 24.7573593,15.5228475 24.7573593,10 C24.7573593,4.4771525 20.2802068,0 14.7573593,0 C9.23451181,0 4.75735931,4.4771525 4.75735931,10" id="Oval" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"></path>
            <g id="Group" transform="translate(11.757359, 6.000000)" stroke={stroke}>
                <rect id="Rectangle" x="1.5" y="0.5" width="1" height="4" rx="0.5"></rect>
                <rect id="Rectangle-Copy" transform="translate(3.493559, 5.377987) rotate(121.000000) translate(-3.493559, -5.377987) " x="3.24355858" y="3.37798732" width="1" height="4" rx="0.5"></rect>
            </g>
        </g>
    </g>
</svg>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  stroke: iconBlue,
};

export default SVG;
