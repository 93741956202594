import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import AddProductsContainer from '../../containers/addProduct/AddProductsContainer';
import NOB from '../../../../constants/language-strings';
import { NUMBERS_IN_LETTERS_NOB } from '../../../../constants/numbers-in-letters';
import AgreementPrice from './AgreementPrice/AgreementPrice';
import filterCustomerProducts from '../../../../utils/filterCustomerProducts';
import './customerProductList.scss';
import SimpleButton from '../../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../../components/Buttons/Color';
import ListItem from '../../../../components/ListItem/ListItem';
import ProductListElementContainer from '../../containers/CustomerProduct/ProductListElementContainer';
import { QueryClient, QueryClientProvider } from 'react-query';

Modal.setAppElement('#app');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
    },
  },
});

export default function CustomerProductList({
  addProductErrorMessage,
  customerId,
  agreements,
  customerProducts,
  errorMessage,
  isAdding,
}) {
  const [IsVisibleAddProductModal, setIsVisibleAddProductModal] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(0);
  const [showAddProductErrorMessage, setShowAddProductErrorMessage] = useState(false);

  const allCustomerProducts = filterCustomerProducts(customerProducts);

  // If there is a new product, move this to the bottom of list.
  if (allCustomerProducts.find(product => product.newProduct)) {
    const findNewProductIndex = allCustomerProducts.findIndex(product => product.newProduct);
    const newProductItem = allCustomerProducts.splice(findNewProductIndex, 1);

    allCustomerProducts.push(newProductItem[0]);
  }
  const showAddProductButton =
    !errorMessage && customerProducts && agreements.agreements.length > 0;

  /* list out all products */
  const productList = products => (
    <div>
      {products.map(product => (
        <ProductListElementContainer product={product} key={product.productId} />
      ))}
    </div>
  );
  /* show productlist with prices and add products button */
  const agreementProductList = agreements.agreements.map(agreement => {
    const productListFilter = allCustomerProducts.filter(
      singleCustomerProduct => singleCustomerProduct.agreementId === agreement.agreementId,
    );

    const productWithDevices = productListFilter.map(it => it.name);
    const productsInAgreementWithoutDevice = agreement.productNames.filter(
      it => !productWithDevices.includes(it),
    );

    return (
      <div className="customer__products__list__container" key={agreement.agreementId}>
        <QueryClientProvider client={queryClient}>
          <AgreementPrice
            customerId={customerId}
            agreementId={agreement.agreementId}
            originalPrice={agreement.originalPrice}
            currentPrice={agreement.currentPrice}
            isFetchingPrices={agreements.isFetchingPrices}
          />
        </QueryClientProvider>
        <div className="customer__products__container__products-without-devices-list">
          {productsInAgreementWithoutDevice.map(it => (
            <p key={it}>{it}</p>
          ))}
        </div>

        {productList(productListFilter)}
        {isAdding && <ListItem isFetching={isAdding} />}
        {showAddProductButton && (
          <div className="customer__products__container__add-button">
            {(addProductErrorMessage || showAddProductErrorMessage) && (
              <div className="customer__products__container__add-button--error">
                {showAddProductErrorMessage
                  ? 'Kan ikke legge til produkter, fikk ikke hentet ut kundens agreements'
                  : addProductErrorMessage}
              </div>
            )}

            <SimpleButton
              onClick={() => {
                if (agreements.errorMessage) {
                  setShowAddProductErrorMessage(true);
                } else {
                  setIsVisibleAddProductModal(state => !state);
                  setSelectedAgreement(agreement.agreementId);
                }
              }}
              deactivated={!!agreements.errorMessage}
              color={Color.BLACK}
              title="Legg til produkt"
            />
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="customer__products__container">
      {agreements.length > 1 && (
        <div className="customer__products__container--heading">
          <span>{NOB.CUSTOMER_PRODUCTS.CUSTOMER_HAS}</span>
          &nbsp;{NUMBERS_IN_LETTERS_NOB[agreements.length]}&nbsp;
          <span>{NOB.CUSTOMER_PRODUCTS.AGREEMENTS}</span>
        </div>
      )}
      {agreementProductList.length > 0 ? agreementProductList : productList(allCustomerProducts)}
      <AddProductsContainer
        agreementId={selectedAgreement}
        isVisible={IsVisibleAddProductModal}
        onClose={() => setIsVisibleAddProductModal(state => !state)}
      />
    </div>
  );
}

CustomerProductList.propTypes = {
  agreements: PropTypes.objectOf(PropTypes.any).isRequired,
  customerProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  errorMessage: PropTypes.string,
  isAdding: PropTypes.bool.isRequired,
  customerId: PropTypes.number.isRequired,
  addProductErrorMessage: PropTypes.string.isRequired,
};

CustomerProductList.defaultProps = {
  errorMessage: '',
};
