import PropTypes from 'prop-types';
import React from 'react';
import ScannerIcon from '../Buttons/ScannerIcon/ScannerIcon';
import SerialNumberInput from './SerialNumberInput';

function SerialNumberList({
  onScannerClick,
  devices,
  handleChange,
  handleSubmit,
  onReset,
  isFetching,
}) {
  return (
    <div>
      {devices.map(({ errorMessage, value, dpadId }) => (
        <SerialNumberInput
          errorMessage={errorMessage}
          value={value}
          showScannerIcon={devices.length === 1}
          key={dpadId}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          id={dpadId}
          onScannerClick={onScannerClick}
          onReset={onReset}
          isFetching={isFetching}
        />
      ))}
      {devices.length > 1 && <ScannerIcon onClick={onScannerClick} />}
    </div>
  );
}

SerialNumberList.propTypes = {
  onScannerClick: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      errorMessage: PropTypes.string,
      value: PropTypes.string,
      dpadId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

SerialNumberList.defaultProps = {
  isFetching: false,
};

export default SerialNumberList;
