import EsriLoaderReact from 'esri-loader-react';
import proj4 from 'proj4';
import React from 'react';
import PropTypes from 'prop-types';
import { EXPAND, CROSS_LARGE } from '../../../constants/iconNames';
import ComponentHeader from '../../../components/ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import Icon from '../../../icons/index';
import NOB from '../../../constants/language-strings';
import * as CONSTANT from '../constants';
import './map.scss';

const EsriLoader = ({ address, handleExpand, id, isFetching, position, shouldExpand, token }) => {
  if (isFetching) {
    return (
      <div className="map__container--minimized">
        <FetchingSpinner />
      </div>
    );
  }
  let customerPosition = true;

  if (Number.isNaN(position.latitude) || Number.isNaN(position.longitude)) {
    customerPosition = false;
  }

  let UTM33;

  if (customerPosition) {
    UTM33 = proj4(CONSTANT.WGS84, CONSTANT.UTM, [position.longitude, position.latitude]);
  }

  return (
    <div className={shouldExpand ? 'map__container--fullscreen' : 'map__container--minimized'}>
      <EsriLoaderReact
        modulesToLoad={[
          'esri/config',
          'esri/identity/IdentityManager',
          'esri/WebMap',
          'esri/views/MapView',
          'esri/tasks/Locator',
          'esri/Graphic',
          'esri/layers/GraphicsLayer',
          'esri/Viewpoint',
          'esri/geometry/Point',
        ]}
        onReady={({
          loadedModules: [
            esriConfig,
            IdentityManager,
            WebMap,
            MapView,
            Locator,
            Graphic,
            GraphicsLayer,
            Viewpoint,
            Point,
          ],
          containerNode,
        }) => {
          const mapUrl = process.env.MAP_URL
            ? process.env.MAP_URL
            : `${document.location.protocol}//${document.location.host}`;

          esriConfig.request.interceptors.push({
            urls: 'https://geosam.get.no',
            before(params) {
              params.url = params.url.replace('/portal/', '/ias/'); // eslint-disable-line
              params.url = params.url.replace('https://geosam.get.no/', `${mapUrl}/`);

              return null;
            },
          });
          esriConfig.portalUrl = `https://geosam.get.no/ias`; // eslint-disable-line

          IdentityManager.registerToken({
            server: `${mapUrl}/server/rest/services`,
            token,
          });
          IdentityManager.registerToken({
            server: `${mapUrl}/ias/sharing/rest`,
            token,
          });
          IdentityManager.registerToken({
            server: `https://geosam.get.no/portal`,
            token,
          });

          const webmap = new WebMap({
            portalItem: {
              id: id,
            },
          });

          const locator = new Locator();

          locator.url = CONSTANT.GEOCODE_URL;
          locator.countryCode = CONSTANT.NO;

          if (customerPosition) {
            new MapView({
              container: containerNode,
              ui: { components: [CONSTANT.ATTRIBUTION] },
              viewpoint: new Viewpoint({
                targetGeometry: new Point({
                  x: UTM33[0],
                  y: UTM33[1],
                  spatialReference: { wkid: CONSTANT.WKID },
                }),
                scale: CONSTANT.SCALE,
              }),
              map: webmap,
            });

            const graphicsLayer = new GraphicsLayer();

            webmap.add(graphicsLayer);

            const point = {
              type: 'point',
              longitude: position.longitude,
              latitude: position.latitude,
            };

            const simpleMarkerSymbol = {
              type: 'simple-marker',
              color: [226, 119, 40],
              outline: {
                color: [255, 255, 255],
                width: 1,
              },
            };

            const pointGraphic = new Graphic({
              geometry: point,
              symbol: simpleMarkerSymbol,
            });

            graphicsLayer.add(pointGraphic);
          } else {
            locator
              .addressToLocations({ address: { address } })
              .then(
                res =>
                  new MapView({
                    container: containerNode,
                    ui: { components: [CONSTANT.ATTRIBUTION] },
                    viewpoint: new Viewpoint({
                      targetGeometry: res[0].location,
                      scale: CONSTANT.SCALE,
                    }),
                    map: webmap,
                  }),
              )
              .catch(error => error);
          }
        }}
      />

      {!shouldExpand ? (
        <button className="fullscreen-button--minimized" onClick={handleExpand}>
          <Icon name={EXPAND} />
        </button>
      ) : (
        <ComponentHeader
          rightIcon={
            <button onClick={handleExpand}>
              <Icon name={CROSS_LARGE} />
            </button>
          }
          mainHeading={NOB.MAP.MAP}
        />
      )}
    </div>
  );
};

EsriLoader.propTypes = {
  address: PropTypes.string.isRequired,
  handleExpand: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  position: PropTypes.objectOf(PropTypes.number).isRequired,
  shouldExpand: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

export default EsriLoader;
