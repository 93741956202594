import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import SimpleDropdown from '../../../components/Drowpdowns/SimpleDropdown';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import { useQuery } from 'react-query';
import { http } from '../../../api/apiConfig';
import {
  GET_DEVICES_FOR_PROVISIONING,
  SEND_PROVISIONING_COMMAND,
} from '../../../constants/endpoints';
import './HitDecoder.scss';
import Popup from '../../../components/Popup/containers/Popup';

function HitDecoderContainer({ customerId, isVisible, onClose }) {
  const { data: dpads } = useQuery({
    queryKey: ['dpads', customerId],
    queryFn: async () => {
      const { data } = await http.get(GET_DEVICES_FOR_PROVISIONING(customerId));

      return data;
    },
    enabled: true,
  });

  const provisioningCommands = [
    { value: 'RESET_PIN', name: 'Nullstill PIN' },
    { value: 'REAUTH_SOFTWARE_ON_DEVICE', name: 'Oppdater programvare' },
    { value: 'REFRESH_DEVICE', name: 'Refresh device' },
    { value: 'RESEND_ALL_PACKETS', name: 'Resend All Packets' },
  ];

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const handleFirstDropdownChange = selectedValue => {
    setSelectedDevice(selectedValue);
  };

  const handleSecondDropdownChange = selectedValue => {
    setSelectedCommand(selectedValue);
  };

  const handleButtonClick = () => {
    if (selectedDevice && selectedCommand) {
      const requestBody = {
        command: selectedCommand.value,
      };

      http
        .post(SEND_PROVISIONING_COMMAND(selectedDevice.value), requestBody)
        .then(() => {
          setShowSuccessModal(true);
        })
        .catch(error => {
          const errorMessage =
            error.response?.data?.message || 'Failed to send provisioning command';

          setShowErrorModal(true);
          setErrorModalMessage(errorMessage);
        });
    }
  };

  return (
    <SlideInModal
      display={isVisible}
      onClose={onClose}
      mainHeading="Reprovisjoner boks"
      fetchingSpinnerColor="black"
    >
      <div className="dropdown-container">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="dropdown-label">Boks</label>
        {dpads ? (
          <SimpleDropdown
            values={dpads.map(device => ({
              value: device.deviceId,
              name: device.modelName,
            }))}
            onChange={handleFirstDropdownChange}
            title={selectedDevice ? selectedDevice.name : 'Select Boks'}
          />
        ) : (
          <div className="fetchInfo">Loading...</div>
        )}
      </div>
      <div className="dropdown-container">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="dropdown-label">Kommando</label>
        <SimpleDropdown
          values={provisioningCommands}
          onChange={handleSecondDropdownChange}
          title={selectedCommand ? selectedCommand.name : 'Select kommando'}
        />
      </div>
      <div className="button-container">
        <SimpleButton
          color={Color.BLACK}
          title="Gjennomfør"
          onClick={() => {
            handleButtonClick();
          }}
        />
      </div>
      {showSuccessModal && (
        <Popup
          firstLine=""
          secondLine={
            'Successfully send command ' +
            selectedCommand.name +
            ' to device ' +
            selectedDevice.name
          }
          closePopup={() => setShowSuccessModal(false)}
          button={
            <div className="popup__remove-buttons">
              <button onClick={() => setShowSuccessModal(false)}>OK</button>
            </div>
          }
        />
      )}
      {showErrorModal && (
        <Popup
          firstLine="Error"
          secondLine={errorModalMessage}
          closePopup={() => setShowErrorModal(false)}
          button={
            <div className="popup__remove-buttons">
              <button onClick={() => setShowErrorModal(false)}>OK</button>
            </div>
          }
        />
      )}
    </SlideInModal>
  );
}

HitDecoderContainer.propTypes = {
  customerId: PropTypes.number,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HitDecoderContainer);
