import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions';
import { ICC_CHECK_REQUEST } from '../constants';
import { getAPI } from '../../../api/apiConfig';
import { GET_ICC_CHECK } from '../../../constants/endpoints';

export function* callIccCheck({ customerId }) {
  try {
    const url = GET_ICC_CHECK(customerId);
    const result = yield call(getAPI, url);

    yield put(actions.receiveIccCheck({ iccCheck: result }));
  } catch (error) {
    yield put(
      actions.errorIccCheck({
        errorMessage: `Could not get result from ICC Checks. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(ICC_CHECK_REQUEST, callIccCheck)];
