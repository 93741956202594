import PropTypes from 'prop-types';
import React from 'react';
import { SCAN } from '../../../constants/iconNames';
import Icon from '../../../icons/index';
import './scanner-icon.scss';

function ScannerIcon({ onClick }) {
  return (
    <button onClick={onClick} className="scanner-icon">
      <Icon name={SCAN} />
    </button>
  );
}

ScannerIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ScannerIcon;
