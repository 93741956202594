import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CAMERA, DELETE, PDF } from '../../constants/iconNames';
import ExtendedImage from '../ImageViewer/ImageViewer';
import Icon from '../../icons';
import NOB from '../../constants/language-strings';
import './addFiles.scss';
import resizeImage from '../../utils/resizeImage';
import { acceptTypes, imageTypes } from './addFilesConstants';

function AddFiles({ saveFiles, files, type }) {
  const [expand, setExpand] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(0);

  const uploadFile = event => {
    event.preventDefault();

    if (event.target.files.length > 50) {
      alert(NOB.WORK_LOG.IMAGE_ERROR); // eslint-disable-line
    } else {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const pushFile = convertedFile => {
          saveFiles(prev => [
            ...prev,
            {
              file: convertedFile,
              fileName: file.name,
              type: file.type,
            },
          ]);
        };

        if (imageTypes.includes(event.target.files[i].type)) {
          resizeImage(file, 400, 400, resizedImage => {
            pushFile(resizedImage);
          });
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            pushFile(reader.result);
          };
        }
      }
    }
  };

  const deleteFile = index => {
    const newFileArray = [...files];

    newFileArray.splice(index, 1);
    saveFiles(newFileArray);
  };

  const toggleExpandImage = item => {
    const index = files.findIndex(image => image === item);

    setExpand(!expand);
    setExpandedIndex(index);
  };

  return (
    <div className="image_file">
      <form>
        <input
          id={`image_file-${type}`}
          onChange={uploadFile}
          onClick={event => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
          type="file"
          accept={acceptTypes}
          multiple
        />
        <label htmlFor={`image_file-${type}`} id="image_file_button">
          <Icon name={CAMERA} />
        </label>
      </form>

      <div className="image_file__images">
        {files &&
          files.map((item, index) => {
            if (imageTypes.includes(item.type)) {
              return (
                <div className="image_file__images--image" key={item.fileName}>
                  <button
                    className="image_file__images--image--cross"
                    onClick={() => deleteFile(index)}
                  >
                    <Icon name={DELETE} />
                  </button>
                  <button onClick={() => toggleExpandImage(item)}>
                    <img alt="added item" src={item.file} />
                  </button>
                </div>
              );
            }

            return (
              <div className="image_file__images--image" key={item.fileName}>
                <button
                  className="image_file__images--image--cross"
                  onClick={() => deleteFile(index)}
                >
                  <Icon name={DELETE} />
                </button>
                <Icon name={PDF} title={item.type.replace('application/', '.')} />
              </div>
            );
          })}
      </div>

      {expand && (
        <ExtendedImage
          expandedIndex={expandedIndex}
          imagesFiles={files}
          toggleExpandImage={toggleExpandImage}
        />
      )}
    </div>
  );
}

AddFiles.propTypes = {
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  saveFiles: PropTypes.func.isRequired,
  type: PropTypes.string,
};

AddFiles.defaultProps = {
  type: '',
};

export default AddFiles;
