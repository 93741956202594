import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions';
import { END_DAY } from '../../../constants/endpoints';
import { postAPI } from '../../../api/apiConfig';
import { END_DAY_REQUEST } from '../constants';

export function* callEndDay() {
  try {
    const dayActive = yield call(postAPI, END_DAY);

    yield put(actions.receiveDayStatus({ dayActive }));
  } catch (error) {
    yield put(
      actions.errorEndDay({
        errorMessage: `Could not end day. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(END_DAY_REQUEST, callEndDay)];
