import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../utils/FetchingSpinner';
import './slideInPage.scss';

/* children container needs to be added to array for slide in pages in routes to work properly */

function SlideInPage({
  children,
  isFetching,
  mainHeading,
  subHeading,
  showLeftIcon,
  showRightIcon,
  rightIcon,
}) {
  useEffect(() => {
    document.body.classList.add('popup-open');

    return function cleanUp() {
      document.body.classList.remove('popup-open');
    };
  });

  return (
    <div className="slide_in_page-container">
      <ComponentHeader
        mainHeading={mainHeading}
        subHeading={subHeading}
        showLeftIcon={showLeftIcon}
        showRightIcon={showRightIcon}
        rightIcon={rightIcon}
      />
      <div className="slide_in_page-content">{isFetching ? <FetchingSpinner /> : children}</div>
    </div>
  );
}

SlideInPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  isFetching: PropTypes.bool,
  mainHeading: PropTypes.string,
  subHeading: PropTypes.string,
  showLeftIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  rightIcon: PropTypes.element,
};

SlideInPage.defaultProps = {
  isFetching: false,
  mainHeading: '',
  subHeading: '',
  showLeftIcon: false,
  showRightIcon: false,
  rightIcon: null,
};

export default SlideInPage;
