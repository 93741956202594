// Day
export const END_DAY_REQUEST = 'END_DAY_REQUEST';
export const END_DAY_FAILURE = 'END_DAY_FAILURE';
export const START_DAY_REQUEST = 'START_DAY_REQUEST';
export const START_DAY_FAILURE = 'START_DAY_FAILURE';
export const DAY_STATUS_SUCCESS = 'DAY_STATUS_SUCCESS';

// Roles
export const EXTERNAL_INSTALLER = 'ROLE_INSTALLER';
export const TECHNICIAN = 'ROLE_TECHNICIAN';

// Sign in
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const DEV_SIGN_IN_REQUEST = 'DEV_SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

// Sign out
export const SIGN_OUT_REQUEST = 'USER_LOGOUT';
export const SIGN_OUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

// Verify token
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';
export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
