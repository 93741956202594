/* Create a canvas to resize large images to smaller images
callback function will be run when completed */
function resizeImage(file, maxWidth, maxHeight, callBack) {
  const reader = new FileReader();

  reader.onloadend = function (e) {
    const image = new Image();

    image.src = e.target.result;

    image.onload = function () {
      const canvas = document.createElement('canvas');

      let { width, height } = image;

      // check if both with and height is larger than maxWidth and maxHeigth for correct scaling
      if (image.width > maxWidth && image.height > maxHeight) {
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(image, 0, 0, width, height);
      callBack(canvas.toDataURL());
    };
  };
  reader.readAsDataURL(file);
}

export default resizeImage;
