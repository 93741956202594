import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
} from '../workOrderListConstants';

const productListReducerDefaultState = {
  errorMessage: '',
  isFetching: false,
  productList: [],
};

export default (state = productListReducerDefaultState, action) => {
  const { errorMessage, productList, type } = action;

  switch (type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: productList.packingList,
        isFetching: false,
      };

    case PRODUCT_LIST_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetching: false,
      };

    default:
      return state;
  }
};
