import React from 'react';
import Textarea from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import './text-input.scss';

function TextInput({ hasError, label, value, onChange, maxLength, name, placeholder }) {
  return (
    <div className={hasError ? 'text-input--error' : 'text-input'}>
      <label htmlFor={name}>
        <span className="label">{hasError ? `* ${label}` : label}</span>
        <Textarea
          name={name}
          value={value}
          className="textarea"
          onChange={onChange}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
}

TextInput.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  maxLength: '',
  placeholder: '',
  hasError: false,
};

export default TextInput;
