import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ fill }) => (
  <svg width="30px" height="16px" viewBox="0 0 30 16">
    <title>icon/newhelsesjekk</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="icon/newhelsesjekk" fillRule="nonzero" stroke={fill} strokeWidth="1.5">
        <path d="M1,10.6182416 L4.78822159,10.6182416 C4.96951302,10.6182416 5.13660197,10.5201094 5.22490338,10.3617761 L7.42812816,6.41117189 C7.56262852,6.1699992 7.86717169,6.08352418 8.10834438,6.21802453 C8.20351401,6.27109998 8.27873926,6.353813 8.322573,6.45357704 L11.9487306,14.7065822 C12.0598112,14.9593978 12.3548073,15.0742966 12.6076229,14.963216 C12.7382818,14.9058079 12.8381819,14.7953024 12.8821608,14.6595332 L17.1966808,1.34000788 C17.281777,1.07730414 17.5637245,0.933324756 17.8264282,1.01842092 C17.9788432,1.06779187 18.0983691,1.18721051 18.1478767,1.33958117 L21.6240827,12.0383617 C21.7094146,12.300989 21.9914912,12.4447154 22.2541185,12.3593836 C22.3708459,12.3214569 22.4695462,12.2419219 22.5314218,12.135926 L24.2791274,9.14202086 C24.3687525,8.9884886 24.5331602,8.89409205 24.7109376,8.89409205 L29,8.89409205" id="Path-2" />
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
};

SVG.defaultProps = {
  fill: Variables.iconBlue,
};

export default SVG;
