import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import Item from './Item';
import colorPicker from '../../../../utils/colorPicker';
import {
  MIN_POWER,
  MAX_POWER,
  DOWNSTREAM,
  MIN_SNR,
  MAX_SNR,
  CORRECTED,
  UNCORRECTABLE,
  UPSTREAM,
} from '../../../../constants/samSelector';

const Streams = ({ channels, type, noOfConfigured, scoreVariables }) => {
  let minPower;
  let maxPower;
  let minSNR;
  let maxSNR;
  let correctables;
  let uncorrectables;

  if (channels.length > 0) {
    const powers = channels.map(transmitPower => transmitPower.Power);

    minPower = Math.min(...powers).toFixed(1);
    maxPower = Math.max(...powers).toFixed(1);

    const SNRs = channels.map(SNR => SNR.Snr);

    minSNR = Math.min(...SNRs).toFixed(1);
    maxSNR = Math.max(...SNRs).toFixed(1);

    const correctablesSum = channels.map(correctable => correctable.Corrected);
    const uncorrectablesSum = channels.map(correctable => correctable.Uncorrectable);
    const goodSum = channels.map(correctable => correctable.Good);

    const sum =
      correctablesSum.reduce((a, b) => a + b) +
      uncorrectablesSum.reduce((a, b) => a + b) +
      goodSum.reduce((a, b) => a + b);

    correctables = ((correctablesSum.reduce((a, b) => a + b) * 100) / sum).toFixed(2);
    uncorrectables = ((uncorrectablesSum.reduce((a, b) => a + b) * 100) / sum).toFixed(2);
  }

  return (
    <div>
      <Item
        value={`${channels.length}/${noOfConfigured}`}
        header={NOB.DOWNSTREAM_UPSTREAM.CHANNELS}
      />
      <Item
        value={minPower}
        secondValue={maxPower}
        statusColor={colorPicker(scoreVariables, MIN_POWER, type, channels)}
        secondColor={colorPicker(scoreVariables, MAX_POWER, type, channels)}
        header={
          type === DOWNSTREAM
            ? NOB.DOWNSTREAM_UPSTREAM.RECEIVED_POWER
            : NOB.DOWNSTREAM_UPSTREAM.TRANSMIT_POWER
        }
        unit="dBmV"
      />
      <Item
        value={minSNR}
        secondValue={maxSNR}
        statusColor={colorPicker(scoreVariables, MIN_SNR, type, channels)}
        secondColor={colorPicker(scoreVariables, MAX_SNR, type, channels)}
        header={NOB.DOWNSTREAM_UPSTREAM.SNR_MIN}
        unit="dB"
      />
      <Item
        value={correctables}
        header={NOB.DOWNSTREAM_UPSTREAM.CORRECTABLE}
        statusColor={colorPicker(scoreVariables, CORRECTED, type, channels)}
        unit="%"
      />
      <Item
        value={uncorrectables}
        header={NOB.DOWNSTREAM_UPSTREAM.UNCORRECTABLE}
        statusColor={colorPicker(scoreVariables, UNCORRECTABLE, type, channels)}
        unit="%"
      />
    </div>
  );
};

Streams.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string,
  noOfConfigured: PropTypes.number,
  scoreVariables: PropTypes.objectOf(PropTypes.any),
};

Streams.defaultProps = {
  channels: [],
  type: UPSTREAM,
  noOfConfigured: 0,
  scoreVariables: {},
};

export default Streams;
