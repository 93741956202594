import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../../../../components/InfoBox/InfoBoxError';
import Feedback from '../../../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../../../constants/feedbackNames';
import './deviceStatusList.scss';
import DeviceStatusListItem from './DeviceStatusListItem';
import NOB from '../../../../constants/language-strings';
import ListItem from '../../../../components/ListItem/ListItem';

const DeviceStatus = ({ devices, toggleDisplaySam, error }) => {
  if (error || devices.length === 0) {
    return (
      <InfoBox
        feedback={<Feedback name={FEEDBACK_NOT_FOUND} />}
        firstInfoBoxLine={NOB.HEALTH_CHECK.DEVICE_STATUS_ERROR}
        link={false}
      />
    );
  }

  return (
    <div>
      {devices.map(device => (
        <ListItem
          onClick={() => toggleDisplaySam(device)}
          key={device.description}
          clickable={device.pollable}
        >
          <DeviceStatusListItem {...device} />
        </ListItem>
      ))}
    </div>
  );
};

DeviceStatus.propTypes = {
  devices: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  toggleDisplaySam: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default DeviceStatus;
