export default function capitalize(string) {
  try {
    let returnString = string.toLowerCase();

    returnString = returnString.replace(/(^|\s)\S/g, letter => letter.toUpperCase());

    return returnString;
  } catch (TypeError) {
    return string;
  }
}
