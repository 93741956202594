import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import * as action from '../actions/popupActions';
import NOB from '../../../constants/language-strings';

const GoogleMapsButton = ({ address, togglePopup }) => {
  const { city, houseNumberAlpha, houseNumberNumeric, postalCode, street } = address;

  const fullAddress = `${street}+${houseNumberNumeric}+${houseNumberAlpha}%2C${postalCode}+${city}`;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.google.com/maps/dir/?api=1&destination=${fullAddress}&travelmode=driving`}
      className="popup__button"
      onClick={() => togglePopup(true)}
    >
      {NOB.GOOGLE_MAPS_POPUP.OPEN_MAP}
    </a>
  );
};

GoogleMapsButton.propTypes = {
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  togglePopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  address: state.workOrder.workOrder.address,
  close: state.popup.close,
});

const mapDispatchToProps = dispatch => ({
  togglePopup: close => dispatch(action.togglePopup({ close })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapsButton);
