import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SEARCH } from '../../constants/iconNames';
import Icon from '../../icons';
import markSearch from '../../utils/markSearch';
import Variables from '../../styles/variables.scss';
import './searchProductBar.scss';

const SearchProductBar = ({ options, placeholder, onChange }) => (
  <div className="search_product_bar-container">
    <span className="search_product_bar-icon">
      <Icon color={Variables.blackPrimary} name={SEARCH} />
    </span>

    <SelectSearch
      autofocus={false}
      className="search_product_bar"
      onChange={onChange}
      options={options}
      renderOption={markSearch}
      search
      placeholder={placeholder}
    />
  </div>
);

SearchProductBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchProductBar;
