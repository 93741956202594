import React from "react";
import Variables from "../styles/variables.scss";

const SVG = ({ color }) => (
  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
    <title>icon/Brukerprofil_blå</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/Brukerprofil_blå" fill={color} fillRule="nonzero">
        <circle id="Oval-4" cx="7.5" cy="4.5" r="4" />
        <path d="M1.0133726,13.2227526 C1.88360013,10.3254545 4.13208598,8.9190683 7.75883017,9.00359408 C11.3679871,9.08770997 13.5514117,10.501238 14.309104,13.2441782 L14.3090873,13.2441828 C14.456137,13.7765213 14.1437989,14.3272743 13.6114604,14.4743241 C13.5247361,14.4982802 13.4351754,14.5104227 13.3452032,14.5104227 L1.97112892,14.5104483 C1.41883003,14.5104483 0.971103309,14.0627216 0.971103309,13.5104227 C0.971103309,13.4129855 0.985343555,13.3160713 1.0133726,13.2227526 Z" id="Path-6" />
      </g>
    </g>
  </svg>
);

SVG.defaultProps = {
  color: Variables.turquoiseBlue,
}

export default SVG;
