import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExtendedImage from '../../../../components/ImageViewer/ImageViewer';
import './textBox.scss';
import Icon from '../../../../icons';
import { PDF } from '../../../../constants/iconNames';
import isImage from '../../../../utils/isImage';

function TextBox({ heading, text, attachments, languageStrings }) {
  const [expanded, setIsExpanded] = useState(false);
  const [chosenImage, setChosenImage] = useState(0);

  const attachmentList = attachments.attachmentList.map((attachment, index) => {
    if (!isImage(attachment.href)) {
      const fileEnding = attachment.href
        .substring(attachment.href.lastIndexOf('.') + 1)
        .toUpperCase();
      const title = fileEnding.length > 4 ? languageStrings.DEFAULT_FILE_NAME : fileEnding;

      return (
        <a href={attachment.href} rel="noopener noreferrer" target="_blank" key={attachment.href}>
          <Icon name={PDF} title={title} />
        </a>
      );
    }

    return (
      <button
        onClick={() => {
          setIsExpanded(true);
          setChosenImage(index);
        }}
        key={attachment.href}
      >
        <img src={attachment.href} alt="Vedlegg" />
      </button>
    );
  });
  const imageFiles = attachments.attachmentList
    .map(image => image.href)
    .filter(link => isImage(link));

  return (
    <div className="comment__container">
      <div className="comment-header">{heading}</div>
      <div className="comment__content">
        {text}
        {attachments.hasError && <div className="error">{languageStrings.ATTACHMENT_ERROR}</div>}
        {attachmentList.length > 0 && !attachments.hasError && (
          <div className="attachment-list">{attachmentList}</div>
        )}
        {expanded && (
          <ExtendedImage
            expandedIndex={chosenImage}
            imagesFiles={imageFiles}
            toggleExpandImage={() => setIsExpanded(!expanded)}
          />
        )}
      </div>
    </div>
  );
}

TextBox.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  attachments: PropTypes.objectOf(PropTypes.any),
  languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};

TextBox.defaultProps = {
  attachments: {
    hasError: false,
    attachmentList: [],
  },
};

const mapStateToProps = state => ({
  languageStrings: state.NOB.COMMENT,
});

export default connect(mapStateToProps)(TextBox);
