import React from 'react';
import PropTypes from 'prop-types';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import { FOLLOW_UP } from '../../WorkLog/workLogConstants';
import NOB from '../../../constants/language-strings';
import Color from '../../../components/Buttons/Color';

function WorkOrderCompletionButtons({
  disabled,
  handleSubmit,
  technician,
  values,
  disableCompleteButton,
  disableNotCompleteButton,
}) {
  return (
    <div>
      {technician ? (
        <SimpleButton
          onClick={() => {
            handleSubmit(!values[FOLLOW_UP]);
          }}
          disabled={disabled}
          title={NOB.CUSTOMER_NOT_HOME.REPORT}
        />
      ) : (
        <div className="complete__workorder__container--buttons">
          <SimpleButton
            onClick={() => {
              handleSubmit(false);
            }}
            disabled={disabled || disableNotCompleteButton}
            color={Color.RED}
            title={NOB.WORK_LOG.NOT_COMPLETE_BUTTON}
          />
          <SimpleButton
            onClick={() => {
              handleSubmit(true);
            }}
            disabled={disabled || disableCompleteButton}
            color={Color.YELLOW}
            title={NOB.WORK_LOG.COMPLETE_BUTTON}
          />
        </div>
      )}
    </div>
  );
}

WorkOrderCompletionButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  technician: PropTypes.bool.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  disableCompleteButton: PropTypes.bool,
  disableNotCompleteButton: PropTypes.bool,
};

WorkOrderCompletionButtons.defaultProps = {
  disableCompleteButton: false,
  disableNotCompleteButton: false,
};

export default WorkOrderCompletionButtons;
