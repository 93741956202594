import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { ACTION_TAKEN, POST_ZENDESK, STATUS } from '../../../../constants/endpoints';
import { postAPI, putAPI } from '../../../../api/apiConfig';
import { COMPLETE_WORK_ORDER_WITH_ZENDESK } from '../../workLogConstants';
import * as completeAction from '../../actions/completeStatusActions';
import { clearSavedFiles } from '../../actions/zendeskActions';
import { FRONT_PAGE_PATH } from '../../../../constants/paths';
import {
  updateDiagnosticServices,
  selectServiceType,
} from '../../../CreateNewWorkOrder/actions/serviceTypesActions';
import { DEFAULT_SERVICE_TYPE } from '../../../CreateNewWorkOrder/createNewWorkOrderConstants';

export function* callCompleteStatus({ body, status, azimuth, workOrderId }) {
  try {
    const actionTakenURL = ACTION_TAKEN(workOrderId);
    const updateStatusURL = STATUS(workOrderId);

    yield call(postAPI, POST_ZENDESK, body);

    yield call(putAPI, actionTakenURL, { body: body.commentBody });
    const requestBody = { status, azimuthValue: azimuth };

    yield call(putAPI, updateStatusURL, requestBody);

    yield put(completeAction.receiveCompleteStatusChange());
    yield put(clearSavedFiles());
    yield put(updateDiagnosticServices({ selectedDiagnosticServices: [] }));
    yield put(selectServiceType({ serviceType: DEFAULT_SERVICE_TYPE }));
    yield put(push(FRONT_PAGE_PATH));
  } catch (error) {
    yield put(
      completeAction.updateActionTakenFailure({
        errorMessage: `Could not update action taken. ${error?.message}`,
      }),
    );
  }
}

export default [takeEvery(COMPLETE_WORK_ORDER_WITH_ZENDESK, callCompleteStatus)];
