import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';

const ListItem = ({ passed, description }) => (
  <div className="health__check__list__item">
    <div className="health__check__list__item-description">{description}</div>
    {passed ? (
      <div className="health__check__list__item-status">
        <div className="health__check__list__item-status-ok" />
        {NOB.HEALTH_CHECK.STATUS_OK}
      </div>
    ) : (
      <div className="health__check__list__item-status">
        <div className="health__check__list__item-status-error" />
        {NOB.HEALTH_CHECK.STATUS_ERROR}
      </div>
    )}
  </div>
);

ListItem.propTypes = {
  passed: PropTypes.bool,
  description: PropTypes.string.isRequired,
};

ListItem.defaultProps = {
  passed: false,
};

export default ListItem;
