// Complete work order
export const COMPLETE_WORK_ORDER_WITH_ZENDESK = 'COMPLETE_WORK_ORDER_WITH_ZENDESK';

// Complete status change
export const COMPLETE_STATUS_CHANGE_REQUEST = 'COMPLETE_STATUS_CHANGE_REQUEST';
export const COMPLETE_STATUS_CHANGE_SUCCESS = 'COMPLETE_STATUS_CHANGE_SUCCESS';
export const COMPLETE_STATUS_CHANGE_FAILURE = 'COMPLETE_STATUS_CHANGE_FAILURE';

// Description
export const NO_ONE_HOME = 'Ingen Hjemme';
export const SERVICE_CALL = 'Serviceutrykning';

// Find services
export const FIND_SERVICES_REQUEST = 'FIND_SERVICES_REQUEST';
export const FIND_SERVICES_SUCCESS = 'FIND_SERVICES_SUCCESS';
export const FIND_SERVICES_ERROR = 'FIND_SERVICES_ERROR';

// Remove service
export const REMOVE_SERVICE_REQUEST = 'REMOVE_SERVICE_REQUEST';
export const REMOVE_SERVICE_ERROR = 'REMOVE_SERVICE_ERROR';
export const REMOVE_SERVICE_SUCCESS = 'REMOVE_SERVICE_SUCCESS';

// Update service
export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';

// Zendesk
export const ZENDESK_POST_REQUEST = 'ZENDESK_POST_REQUEST';
export const ZENDESK_POST_SUCCESS = 'ZENDESK_POST_SUCCESS';
export const ZENDESK_POST_ERROR = 'ZENDESK_POST_ERROR';

// Zendesk follow up
export const ZENDESK_FOLLOW_UP_POST_REQUEST = 'ZENDESK_FOLLOW_UP_POST_REQUEST';
export const ZENDESK_FOLLOW_UP_POST_SUCCESS = 'ZENDESK_FOLLOW_UP_POST_SUCCESS';
export const ZENDESK_FOLLOW_UP_POST_ERROR = 'ZENDESK_FOLLOW_UP_POST_ERROR';

export const SAVE_COMMENT = 'SAVE_COMMENT';
export const SAVE_FILE = 'SAVE_FILE';
export const CLEAR_SAVED_FILES = 'CLEAR_SAVED_FILES';
export const SAVE_FOLLOW_UP_FILE = 'SAVE_FOLLOW_UP_FILE';
export const SET_FOLLOW_UP = 'SET_FOLLOW_UP';

// Zendesk statuses
export const SOLVED = 'solved';
export const NEW = 'ny';

export const UPDATE_ACTION_TAKEN_FAILURE = 'UPDATE_ACTION_TAKEN_FAILURE';

// Follow up
export const CALLED_CUSTOMER = 'calledCustomer';
export const CONFIRMED_RIGHT_ADDRESS = 'confirmedRightAddress';
export const CUSTOMER_NOT_HOME = 'customerNotHome';
export const FOLLOW_UP = 'followUp';
export const RUNG_KNOCKED_DOOR = 'rungKnockedDoor';
export const COMMENT = 'comment';
export const AZIMUTH = 'azimuth';

// Checked categories
export const SET_CHECKED_CATEGORY = 'SET_CHECKED_CATEGORY';
export const CREATE_NEW_WORK_ORDER = 'CREATE_NEW_WORK_ORDER';

// WorkOrder servics
export const WORKORDER_SERVICES_REQUEST = 'WORKORDER_SERVICES_REQUEST';
export const WORKORDER_SERVICES_SUCCESS = 'WORKORDER_SERVICES_SUCCESS';
export const WORKORDER_SERVICES_ERROR = 'WORKORDER_SERVICES_ERROR';
