export default function filterServices(
  services,
  existingWorkOrderServiceIds,
  categories,
  isDiagnosticServices,
) {
  let filteredServices = services.filter(
    item => !existingWorkOrderServiceIds.includes(item.serviceId),
  );

  if (categories) {
    filteredServices = filteredServices.map(service => {
      const [main, sub, error] = service.description.split(' > ');
      const amount = service.servicePrices[0] ? service.servicePrices[0].amount : 0;
      const trimmedError = error ? error.trim() : error;

      return {
        main: isDiagnosticServices ? sub : main,
        sub: isDiagnosticServices ? trimmedError : sub,
        error: isDiagnosticServices ? null : error,
        id: service.serviceId,
        description: service.description,
        name: service.description,
        amount,
        timeUnitPerService: service.timeUnitPerService,
        serviceId: service.serviceId,
        value: service.serviceId,
      };
    });
  }

  return filteredServices;
}
