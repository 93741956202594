export const FRONT_PAGE_PATH = '/';
export const QA = 'qa';
export const SIGN_OUT_PATH = '/signout';
export const WORK_ORDER_ID_PATH = '/workOrder/:id';
export const CUSTOMER_INFO_PATH = '/customerInfo/:customerId';
export const ADDRESS_SEARCH = '/address-search';

export const hashes = {
  customerProducts: '#customerProducts',
  addressHistory: '#addressHistory',
  workOrderHistory: '#workOrderHistory',
  healthCheck: '#healthCheck',
  followUp: '#followUp',
  notHome: '#nothome',
};

export const WORK_LOG_PATH = `${WORK_ORDER_ID_PATH}/workLog`;
export const ADDRESS_HISTORY_URL = workOrderId => `/workOrder/${workOrderId}#addressHistory`;
export const WORK_ORDER_HISTORY_URL = workOrderId => `/workOrder/${workOrderId}#workOrderHistory`;
export const CUSTOMER_PRODUCTS_URL = workOrderId => `/workOrder/${workOrderId}#customerProducts`;
export const HEALTH_CHECK_URL = workOrderId => `/workOrder/${workOrderId}#healthCheck`;
export const WORK_LOG_URL = workOrderId => `/workOrder/${workOrderId}/workLog`;
export const FOLLOW_UP_CUSTOMER_URL = workOrderId => `/workOrder/${workOrderId}/workLog#followUp`;
export const NEW_WORK_ORDER_URL = workOrderId => `/workOrder/${workOrderId}/workLog#newWorkOrder`;
export const NOT_HOME_URL = workOrderId => `/workOrder/${workOrderId}${hashes.notHome}`;

export const CUSTOMER_INFO_URL = customerId => `/customerInfo/${customerId}`;
export const CUSTOMER_INFO_ADDRESS_HISTORY_URL = customerId =>
  `/customerInfo/${customerId}#addressHistory`;
export const CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL = customerId =>
  `/customerInfo/${customerId}#customerProducts`;
export const CUSTOMER_INFO_HEALTH_CHECK_URL = customerId =>
  `/customerInfo/${customerId}#healthCheck`;
