import React from 'react';
import PropTypes from 'prop-types';
import { LINK, TRASH } from '../../constants/iconNames';
import Icon from '../../icons';
import IconButton from '../Buttons/IconButton/IconButton';
import Variables from '../../styles/variables.scss';

function ProductItemContentActive({
  hasLinkOptions,
  name,
  showDeleteButton,
  removeProduct,
  leftText,
  serialNumbers,
}) {
  return (
    <div className="product_item--active">
      {hasLinkOptions && (
        <div className="product_item-icon">
          <Icon name={LINK} />
        </div>
      )}
      <div className="product_item--active-left">
        <span>{name}</span>
        <span className="product_item--active-status">{leftText}</span>
      </div>
      <div className="serial-number-list">
        {serialNumbers.map(serialNumber => (
          <span key={serialNumber} className="product_item--active-serial">
            {serialNumber}
          </span>
        ))}
      </div>
      <div className="product--right">
        {showDeleteButton && (
          <IconButton icon={TRASH} onClick={removeProduct} fill={Variables.blackPrimary} />
        )}
      </div>
    </div>
  );
}

ProductItemContentActive.propTypes = {
  showDeleteButton: PropTypes.bool.isRequired,
  hasLinkOptions: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  removeProduct: PropTypes.func.isRequired,
  leftText: PropTypes.string.isRequired,
  serialNumbers: PropTypes.arrayOf(PropTypes.string),
};

ProductItemContentActive.defaultProps = {
  serialNumbers: [],
};

export default ProductItemContentActive;
