import PropTypes from 'prop-types';
import React from 'react';
import NOB from '../../../../constants/language-strings';
import './customerInfo.scss';
import CustomerInfo from './CustomerInfo';
import isPast from '../../../../utils/isPastDue';

const CustomerInfoContainer = ({ address, addressDetails, customer, togglePopup, workOrder }) => {
  const isPastDue = isPast(workOrder.serviceDate);

  return (
    <div className="customer__info__container">
      <div className="customer__info__container--header">
        <div>
          <span>{NOB.CUSTOMER_INFO.CUSTOMER_ID}</span>
          &nbsp;
          <span className="customer__info__container--header--id">{customer.customerId}</span>
        </div>
        <div>
          <span>{NOB.CUSTOMER_INFO.CUSTOMER_TYPE}</span>
          &nbsp;
          <span className="customer__info__container--header--id">
            {addressDetails.addressType}
          </span>
        </div>
        <div>
          <span>{NOB.CUSTOMER_INFO.NETWORK}</span>
          &nbsp;
          <span className="customer__info__container--header--id">
            {addressDetails.networkPlatform
              .map(it => {
                switch (it.name) {
                  case 'HFC':
                    const twoWay = addressDetails.hfcTwoWayNetwork
                      ? '2-veis (TV og internett)'
                      : '1-veis (kun TV)';

                    return `HFC ${addressDetails.hfcDocsisVersion} ${twoWay}`;
                  default:
                    return it.platformName;
                }
              })
              .join(' | ')}
          </span>
        </div>
      </div>
      <div className="customer__info__container__content">
        <CustomerInfo
          address={address}
          customer={customer}
          togglePopup={togglePopup}
          isPastDue={isPastDue}
        />
      </div>
    </div>
  );
};

CustomerInfoContainer.propTypes = {
  address: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  customer: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  addressDetails: PropTypes.object,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  togglePopup: PropTypes.func.isRequired,
};

export default CustomerInfoContainer;
