import React from 'react';
import PropTypes from 'prop-types';
import SelectCategories from './content/SelectCategories';

export default function AddServiceCategoryPicker({
  categories,
  mainCategory,
  mainError,
  resetCategories,
  selectedCategory,
  showMainCategories,
  showMainErrors,
  showSubCategories,
  subCategory,
  technician,
  toggleCategoryDropdown,
}) {
  return (
    <div>
      {technician && (
        <SelectCategories
          categories={categories}
          mainCategory={mainCategory}
          mainError={mainError}
          resetCategories={resetCategories}
          toggleCategoryDropdown={toggleCategoryDropdown}
          selectedCategory={selectedCategory}
          showMainCategories={showMainCategories}
          showMainErrors={showMainErrors}
          showSubCategories={showSubCategories}
          subCategory={subCategory}
        />
      )}
    </div>
  );
}

AddServiceCategoryPicker.propTypes = {
  categories: PropTypes.func.isRequired,
  mainCategory: PropTypes.string.isRequired,
  mainError: PropTypes.string.isRequired,
  resetCategories: PropTypes.func.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  showMainCategories: PropTypes.bool.isRequired,
  showMainErrors: PropTypes.bool.isRequired,
  showSubCategories: PropTypes.bool.isRequired,
  subCategory: PropTypes.string.isRequired,
  technician: PropTypes.bool.isRequired,
  toggleCategoryDropdown: PropTypes.func.isRequired,
};
