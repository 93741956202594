import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddressHistoryDropdown from '../components/AddressHistoryDropdown';
import capitalize from '../../../utils/capitalizeString';
import Feedback from '../../../feedbacks';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import { requestAddressHistory } from '../actions/addressHistoryActions';
import './addressHistory.scss';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';

class AddressHistoryContainer extends Component {
  state = { opened: 0 };

  componentDidMount() {
    const { addressHistory, location, requestAddressHistoryFunc, workOrder } = this.props;

    if (location.pathname.indexOf('customerInfo') !== -1) return;

    const customerIds = addressHistory.map(item => item.customerId);

    if (addressHistory.length || !customerIds.includes(`${workOrder.customer.customerId}`)) {
      requestAddressHistoryFunc(location.pathname.split('/')[2]);
    }
  }

  toggleDropdown = workOrderId => {
    this.setState({ opened: workOrderId });
  };

  render() {
    const { addressHistory, isFetching, workOrder } = this.props;

    const address = `${capitalize(workOrder.address.street)}
      ${workOrder.address.houseNumberNumeric}
      ${workOrder.address.houseNumberAlpha && ` ${workOrder.address.houseNumberAlpha},`}`;

    return (
      <SlideInPage
        mainHeading={NOB.ADDRESS_HISTORY.ADDRESS_HISTORY}
        showLeftIcon
        showRightIcon={false}
        subHeading={address}
        isFetching={isFetching}
      >
        {!addressHistory.length > 0 ? (
          <InfoBox
            feedback={<Feedback />}
            firstInfoBoxLine={NOB.FEEDBACKS.SORRY}
            link={false}
            secondInfoBoxLine={NOB.ADDRESS_HISTORY.NO_ADDRESS_HISTORY}
          />
        ) : (
          <div className="address_history_container">
            <AddressHistoryDropdown
              addressHistory={addressHistory}
              opened={this.state.opened}
              toggleDropdown={this.toggleDropdown}
            />
          </div>
        )}
      </SlideInPage>
    );
  }
}

AddressHistoryContainer.propTypes = {
  addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  requestAddressHistoryFunc: PropTypes.func.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  addressHistory: state.addressHistory.addressHistory,
  isFetching: state.addressHistory.isFetching,
  workOrder: state.workOrder.workOrder,
});

const mapDispatchToProps = dispatch => ({
  requestAddressHistoryFunc: workOrderId => dispatch(requestAddressHistory({ workOrderId })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressHistoryContainer));
