import React from 'react';
import SimpleButton from '../../components/Buttons/SimpleButton/SimpleButton';
import './ErrorPage.scss';
import ErrorImage from './ErrorImage.png';
import PropTypes from 'prop-types';

const TEXT = {
  BACK_TITLE: 'Tilbake',
  CONTENT_TITLE: 'Oi sann... Noe gikk galt',
  GENERIC_ERROR:
    'Vi beklager, men tjenesten er for tiden ikke tilgjengelig. Vi jobber hardt for å få den opp og gå igjen.',
};

export const ErrorPage = props => {
  const username = localStorage.getItem('username');
  const isoDate = new Date().toISOString();

  return (
    <div className="error-page__container">
      <div className="error-page__content-box">
        <h1>{TEXT.CONTENT_TITLE}</h1>
        <img src={ErrorImage} alt="Error" className="error-page__image" />
        <p>{TEXT.GENERIC_ERROR}</p>
        <p>
          <b>Timestamp: </b> {isoDate}
        </p>
        {username && (
          <p>
            <b>Username: </b>
            {username}
          </p>
        )}
        <p>
          <b>Event ID: </b>
          {props.eventId}
        </p>
        <p>
          <b>Message: </b>
          {props.error.message}
        </p>

        <SimpleButton
          onClick={() => window.location.replace(window.location.origin)}
          title={TEXT.BACK_TITLE}
        />
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  eventId: PropTypes.string.isRequired,
  error: PropTypes.objectOf({
    message: PropTypes.string,
  }),
};
