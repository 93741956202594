import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NOB from '../../../../../constants/language-strings';
import { SIGN_OUT_PATH } from '../../../../../constants/paths';

const EndDay = ({ endDayFunction, history }) => {
  const endDayAndSignOut = () => {
    endDayFunction(true);
    history.push(SIGN_OUT_PATH);
  };

  return (
    <button className="workOrder__list__bottom__buttons--end-day" onClick={endDayAndSignOut}>
      {NOB.END_DAY.END_DAY}
    </button>
  );
};

EndDay.propTypes = {
  endDayFunction: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(EndDay);
