// Filtered customer products
export default function filterCustomerProducts(customerProducts) {
  return customerProducts
    .sort((a, b) => {
      if (a.productId < b.productId) {
        return 1;
      }

      if (a.productId > b.productId) {
        return -1;
      }

      return 0;
    })
    .sort((a, b) => {
      if (a.status > b.status) {
        return 1;
      }

      if (a.status < b.status) {
        return -1;
      }

      return 0;
    })
    .sort((a, b) => {
      if (
        a.devices.filter(device => device.serialNumber === '').length >
        b.devices.filter(device => device.serialNumber === '').length
      ) {
        return 1;
      }

      if (
        a.devices.filter(device => device.serialNumber === '').length <
        b.devices.filter(device => device.serialNumber === '').length
      ) {
        return -1;
      }

      return 0;
    });
}
