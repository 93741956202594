import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import colorPicker from '../../../../utils/colorPicker';
import { POWER, SNR, UPSTREAM } from '../../../../constants/samSelector';

const StreamChannel = ({ channels, type, scoreVariables }) => {
  const renderChannels = channels.map(response =>
    response.Snr !== -1000 && response.Frequency !== 0 ? (
      <div className="stream__channel__items" key={type + response.Channel}>
        <div>{response.Channel}</div>
        <div>{response.Frequency}</div>
        <div className={colorPicker(scoreVariables, POWER, type, response)}>{response.Power}</div>
        <div className={colorPicker(scoreVariables, SNR, type, response)}>{response.Snr}</div>
      </div>
    ) : (
      <div />
    ),
  );

  return (
    <div className="stream__channel">
      <div className="stream__channel--headings">
        <div>{NOB.DOWNSTREAM_UPSTREAM.CH}</div>
        <div>
          {NOB.DOWNSTREAM_UPSTREAM.FREQ}
          <br />
          {NOB.DOWNSTREAM_UPSTREAM.MHZ}
        </div>
        <div>
          {NOB.DOWNSTREAM_UPSTREAM.SIGNAL_POWER}
          <br />
          {NOB.DOWNSTREAM_UPSTREAM.DBMV}
        </div>
        <div>
          {NOB.DOWNSTREAM_UPSTREAM.SNR}
          <br />
          {NOB.DOWNSTREAM_UPSTREAM.DB}
        </div>
      </div>
      {renderChannels}
    </div>
  );
};

StreamChannel.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.any).isRequired,
  scoreVariables: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};

StreamChannel.defaultProps = {
  type: UPSTREAM,
};

export default StreamChannel;
