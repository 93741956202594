import {
  DEVICE_POLLING_MAC_REQUEST,
  DEVICE_POLLING_MAC_ERROR,
  DEVICE_POLLING_MAC_RECEIVE,
} from '../constants';

const devicePollingMacDefaultState = {
  devicePollingMac: {},
  isFetching: true,
  errorMessage: '',
  mainHeading: '',
};

export default (state = devicePollingMacDefaultState, { devicePollingMac, errorMessage, type }) => {
  switch (type) {
    case DEVICE_POLLING_MAC_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case DEVICE_POLLING_MAC_RECEIVE:
      return {
        ...state,
        devicePollingMac,
        isFetching: false,
      };

    case DEVICE_POLLING_MAC_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };

    default:
      return state;
  }
};
