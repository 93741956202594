import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import Icon from '../../../icons';
import { ARROW_LEFT } from '../../../constants/iconNames';
import './navigationButton.scss';
import NOB from '../../../constants/language-strings';
import Variables from '../../../styles/variables.scss';

const NavigationButton = ({
  URL,
  leftIcon,
  title,
  number,
  numberColor,
  rightIcon,
  disabled,
  isFetching,
  customClass,
  onClick,
}) => {
  const numberClassName =
    numberColor === NOB.COMPONENT.RED ? 'navigation_button_number-red' : 'navigation_button_number';

  const handleClick = e => {
    if (disabled || onClick !== null) {
      e.preventDefault();

      if (onClick !== null) {
        onClick(e);
      }
    }
  };

  return (
    <Link
      className={
        disabled
          ? 'navigation_button-link-deactivated'
          : `navigation_button ${customClass || 'navigation_button-standard'}`
      }
      to={URL}
      onClick={handleClick}
    >
      <div className="navigation_button_content-left">
        {leftIcon && <span className="navigation_button_content-left-icon">{leftIcon}</span>}
        <span className="navigation_button_content-text">{title}</span>
      </div>
      <div className="navigation_button_content-right">
        {number > 0 && (
          <div className={numberClassName}>
            <span>{number}</span>
          </div>
        )}
        <span className="navigation_button-icon">
          {isFetching ? (
            <ClipLoader size={16} color={Variables.grayButton} />
          ) : (
            <span className={rightIcon ? '' : 'navigation_button-arrow'}>
              <Icon name={rightIcon || ARROW_LEFT} />
            </span>
          )}
        </span>
      </div>
    </Link>
  );
};

NavigationButton.propTypes = {
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  leftIcon: PropTypes.objectOf(PropTypes.objectOf),
  number: PropTypes.number,
  title: PropTypes.string.isRequired,
  numberColor: PropTypes.string,
  URL: PropTypes.string.isRequired,
  rightIcon: PropTypes.string,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationButton.defaultProps = {
  disabled: false,
  leftIcon: null,
  rightIcon: null,
  numberColor: null,
  number: null,
  isFetching: false,
  customClass: '',
  onClick: null,
};
export default withRouter(NavigationButton);
