import {
  START_DAY_REQUEST,
  START_DAY_FAILURE,
  END_DAY_REQUEST,
  END_DAY_FAILURE,
  DAY_STATUS_SUCCESS,
} from '../constants';

const dayStatusReducerDefaultState = {
  dayActive: false,
  errorMessage: '',
  isFetching: false,
};

export default (state = dayStatusReducerDefaultState, action) => {
  const { dayActive, errorMessage, type } = action;

  switch (type) {
    case START_DAY_REQUEST:
      return {
        isFetching: true,
      };

    case START_DAY_FAILURE:
      return {
        isFetching: false,
        errorMessage,
      };

    case END_DAY_REQUEST:
      return {
        isFetching: true,
      };

    case END_DAY_FAILURE:
      return {
        isFetching: false,
        errorMessage,
      };

    case DAY_STATUS_SUCCESS:
      return {
        isFetching: false,
        dayActive,
      };

    default:
      return state;
  }
};
