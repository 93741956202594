import axios from 'axios';
import { TOKEN } from '../constants/localStorage';
import { AUTHORIZATION } from '../constants/endpoints';

axios.defaults.withCredentials = true;
axios.defaults.headers.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(value => {
  const isAuthRequest = value.url === AUTHORIZATION;

  if (!isAuthRequest && localStorage.getItem(TOKEN)) {
    value.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`;
  }

  return value;
});

export const http = {
  delete: (url, config) => axios.delete(url, config),
  get: (url, config) => axios.get(url, config),
  head: (url, config) => axios.head(url, config),
  patch: (url, payload, config) => axios.patch(url, payload, config),
  post: (url, payload, config) => axios.post(url, payload, config),
  put: (url, payload, config) => axios.put(url, payload, config),
  isCancel: axios.isCancel,
  cancelToken: axios.CancelToken,
};

export const getAPI = url => axios.get(url).then(response => response.data);
export const putAPI = (url, body) =>
  axios
    .put(url, body)
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
export const postAPI = (url, body) =>
  axios
    .post(url, body)
    .then(response => response.data)
    .catch(error => {
      throw error.response.data;
    });
export const deleteAPI = url => axios.delete(url).then(response => response.data);
