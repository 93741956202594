import React from 'react';
import PropTypes from 'prop-types';

function PriceRow({ title, amount }) {
  return (
    <div className="price-row-wrapper">
      <div className="row">
        <span className="title">{title}</span>
        <span className="amount">{amount},-</span>
      </div>
    </div>
  );
}

PriceRow.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default PriceRow;
