import DeviceStatus from '../constants/deviceStatus';
import Variables from '../styles/variables.scss';

function getDeviceStatusColor(deviceStatus) {
  switch (deviceStatus) {
    case DeviceStatus.OK:
      return Variables.samGreen;
    case DeviceStatus.NEUTRAL:
      return Variables.samGray;
    case DeviceStatus.WARN:
      return Variables.samOrange;
    case DeviceStatus.ERROR:
      return Variables.samRed;
    default:
      return Variables.samGray;
  }
}

export default getDeviceStatusColor;
