import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = props => (
  <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1">
    <title>icon/klokke_sort</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/klokke_sort" transform="translate(1.000000, 1.000000)" stroke={props.color}>
        <g id="Group-3">
          <circle id="Oval-115" cx="7.5" cy="7.5" r="7.5" />
          <path d="M7.5,3 L7.5,8" id="Line-38" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.5,8 L10,11" id="Line" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  color: PropTypes.string,
};

SVG.defaultProps = {
  color: Variables.blackSecondary,
};

export default SVG;
