import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './componentHeader.scss';
import IconButton from '../Buttons/IconButton/IconButton';
import { ARROW_LEFT, CROSS } from '../../constants/iconNames';

const ComponentHeader = ({
  leftIcon,
  mainHeading,
  rightIcon,
  showLeftIcon,
  showRightIcon,
  subHeading,
  history,
}) => (
  <div className="component-header">
    {showLeftIcon ? (
      <IconButton
        icon={ARROW_LEFT}
        className="component-header--left-icon"
        onClick={history.goBack}
      />
    ) : (
      <span className="component-header--left-icon">{leftIcon}</span>
    )}

    <div className="component-header__text">
      <span className={subHeading ? 'component-header__text--main' : ''}>{mainHeading}</span>
      <span className="component-header__text--sub">{subHeading}</span>
    </div>

    {showRightIcon ? (
      <IconButton icon={CROSS} onClick={history.goBack} />
    ) : (
      <span className="component-header--right-icon">{rightIcon}</span>
    )}
  </div>
);

ComponentHeader.propTypes = {
  leftIcon: PropTypes.element,
  mainHeading: PropTypes.string.isRequired,
  rightIcon: PropTypes.element,
  showLeftIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  subHeading: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

ComponentHeader.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  showLeftIcon: false,
  showRightIcon: false,
  subHeading: '',
};

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
});

export default withRouter(connect(mapStateToProps)(ComponentHeader));
