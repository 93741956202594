const NOB = {
  ACTIVATE_AGREEMENT_DETAIL: {
    ERROR: 'Aktivering feilet',
    OK: 'Ok',
  },

  ADD_PRODUCT: {
    ERROR_MESSAGE: 'Kunne ikke legge til produkt',
    ERROR_MESSAGE_FETCHING_PRODUCTS: 'Kunne ikke hente tilgjengelige produkter',
    SEARCH: 'Søk i produktlisten',
  },

  ADDRESS_SEARCH: {
    VIEW_CUSTOMER: 'View details',
  },

  ADDRESS_HISTORY: {
    ADDRESS_HISTORY: 'Adressehistorikk',
    COMMENT: 'Kommentar',
    CUSTOMER_ID: 'KundeID',
    NO_ADDRESS_HISTORY: 'Ingen historikk knyttet til denne adressen.',
    SERVICE_CODES: 'Servicekoder',
    STATUS: 'Status',
    USER: 'Bruker',
    WORKORDER: 'Work order',
    WORK_ORDER_DESCRIPTION: 'Arbeidsbeskrivelse',
  },

  ADD_SERVICE: {
    ADD: 'Legg til',
    ADD_NEW_SERVICE: 'Legg til servicekoder',
    HEADER: 'Servicekoder',
    MAIN_CATEGORY: 'Hovedkategori',
    MAIN_ERROR: 'Hovedfeil',
    RESET: 'Nullstill',
    SEARCH: 'Søk',
    SUB_CATEGORY: 'Underkategori',
  },

  APP: {
    OFFLINE: 'Du er ikke koblet til internett. Vennligst prøv igjen senere.',
  },

  CHANGE_PRODUCT: {
    ACTIVATE_UNIT: 'Skriv inn MAC/Serie-nummer for den nye enheten.',
    ACTIVATE_WIFIX: 'Skriv inn MAC/Serie-nummer for de nye WIFIX-enhetene.',
    NOT_AVAILABLE: 'Ikke tilgjengelig',
    SERIAL_MAC: 'MAC/Serie-nummer',
    SWAP_UNIT: 'BYTTING AV ENHET',
    SWAP_WIFIX: 'BYTTING AV WIFIX-ENHETER',
    REMOVE_UNIT: 'SLETT ENHET',
  },

  COMMENT: {
    COMMENT: 'Kommentar',
    DESCRIPTION_HEADING: 'Arbeidsbeskrivelse',
    ATTACHMENT_ERROR: 'Det ble funnet vedlegg til denne work orderen som ikke kunne hentes',
    DEFAULT_FILE_NAME: 'File',
  },

  COMPONENT: {
    HEADER: 'HEADER',
    RED: 'RED',
  },

  CREATE_NEW_WORK_ORDER: {
    ADD_DIAGNOSIS: 'Legg til diagnose',
    COULD_NOT_LOAD: 'Kunne ikke laste inn siden.',
    CREATE: 'Opprett',
    HOURS: 'timer',
    MAINTENANCE_AGREED: 'Maintenance Avtalt',
    REMINDER_ADD_DURATION:
      '*Husk å legge til diagnosekode med varighet for å opprette en work order',
    REMINDER_ADD_PLANNED:
      '*Husk å legge til diagnosekode med avtalt / ikke avtalt for å opprette en work order',
    REMINDER_ADD_TIME:
      '*Husk å legge til diagnosekode med estimert tid for å opprette en work order',
    NO_ACCESS: 'Du har ikke tilgang til denne siden.',
    PROBLEM_DESCRIPTION: 'Problembeskrivelse',
    REQUIRED: 'Må fylles ut',
  },

  CUSTOMER_INFO: {
    CUSTOMER_ID: 'Kunde ID:',
    CUSTOMER_TYPE: 'Type:',
    NETWORK: 'Network:',
  },

  CUSTOMER_NOT_HOME: {
    CUSTOMER_NOT_HOME: 'Kunden er ikke hjemme',
    REPORT: 'Meld inn',
  },

  CUSTOMER_PRODUCTS: {
    AGREEMENTS: 'avtaler',
    CUSTOMER_HAS: 'Kunden har',
    CUSTOMER_INFO: 'Kundeinformasjon',
    CUSTOMER_PRODUCTS: 'Kundens produkter',
    ERROR: 'This serial number is already in use.',
    PRICE_PREFIX: 'Pris/mnd:',
    MAC_ADDRESS: 'MAC adresse',
    NO_CUSTOMER_PRODUCTS: 'Ingen produkter knyttet til denne kunden.',
    NO_PRODUCTS_FOUND: 'Ingen produkter funnet',
    SERIAL_NUMBER: 'Serienummer',
  },

  DATE_PICKER: {
    DATE: 'Dato',
    OK: 'Ok',
  },

  DOWNSTREAM_UPSTREAM: {
    OVERVIEW: 'Overview',
    US_CHANNEL: 'US channel',
    DS_CHANNEL: 'DS channel',
    MODEM_INFO: 'Modem info:',
    UPSTREAMS: 'Upstreams:',
    DOWNSTREAMS: 'Downstreams:',
    CH: 'Ch',
    FREQ: 'Freq.',
    MHZ: '(MHz)',
    SIGNAL_POWER: 'Signal power',
    DBMV: '(dBmV)',
    SNR: 'SNR',
    DB: '(dB)',
    STATUS: 'Status',
    UPTIME: 'Oppetid',
    SERIAL: 'Serial',
    MAC_1: 'Mac 1',
    IP: 'IP address management',
    UPGRADE_STATUS: 'Upgrade status',
    CMTS_PACKETS: 'CMTS packets',
    CMTS: 'CMTS',
    CONFIG: 'Configuration filename',
    CHANNELS: 'Channels:',
    TRANSMIT_POWER: 'Transmit power (min/max)',
    SNR_MIN: 'SNR (min/max)',
    CORRECTABLE: 'Correctable codewords (sum)',
    UNCORRECTABLE: 'Uncorrectable codewords (sum)',
    RECEIVED_POWER: 'Received power (min/max)',
    DOWNSTREAM_UPSTREAM_ERROR: 'Kunne ikke laste inn SAM',
    CHANNEL_ERROR: 'Denne devicen er offline',
  },

  FEEDBACKS: {
    HMM: 'Hmm..',
    SORRY: 'Beklager!',
    HEALTH_CHECK_ERROR: 'Helsesjekk kunne ikke utføres',
  },

  END_DAY: {
    END_DAY: 'Avslutt dagen',
  },

  FOLLOW_UP: {
    CALLED_CUSTOMER: 'Har forsøkt å ringe kunden',
    COMMENT_LABEL: 'Kommentar',
    CONFIRMED_RIGHT_ADDRESS: 'Har bekreftet riktig adresse',
    FOLLOW_UP: 'Oppfølging av kunde',
    NOT_HOME: 'Kunden er ikke hjemme',
    NOT_HOME_COMMENT: 'Kunden er ikke hjemme.',
    RUNG_KNOCKED_DOOR: 'Har ringt på/banket på døren',
    DEFAULT_DROPDOWN_VALUE: 'Velg oppfølging',
    CREATE_BUTTON: 'Opprett',
  },

  GOOGLE_MAPS_POPUP: {
    GOOGLE_MAPS: 'Google Maps',
    QUESTION: 'Ønsker du å åpne rute i Google Maps?',
    OPEN_MAP: 'Åpne kart',
  },

  HEADER: {
    LOGOUT: 'Logg ut',
  },

  HEALTH_CHECK: {
    BUTTON: {
      UPDATE: 'Update',
      FIX_ERRORS: 'Fix provisioning',
    },
    DEVICE_STATUS: 'Device status',
    DEVICE_STATUS_ERROR: 'Ingen status funnet',
    HEALTH_CHECK: 'Helsesjekk',
    ICC_CHECKS: 'ICX Checks',
    ICC_CHECK_ERROR: 'Kunne ikke utføre ICX-sjekk!',
    NO_ACTIVATED_PRODUCTS: 'Kunden har ingen aktiverte produkter!',
    OFFLINE: 'Offline',
    ONLINE: 'Online',
    PROVISIONING_ERROR: 'Ingen provisjoneringsstatus!',
    PROVISIONING_HEADING: 'Provisioning Status',
    PROVISIONING_STATUS: 'Provisioning',
    STATUS_ERROR: 'Error',
    STATUS_OK: 'Ok',
    UNSTABLE: 'Unstable',
    NO_DEVICES_FOUND: 'Ingen devices ble funnet',
  },

  INFO_BOX: {
    NOT_EXIST: 'Denne siden eksisterer ikke.',
  },

  LINKING: {
    COULD_NOT_LINK: 'Kunne ikke endre linking av produkt.',
    LINKING: 'LINKING',
    NO_AVAILABLE: 'Ingen endringer tilgjengelig.',
    WHERE_SHALL: 'Hvor skal',
    BE_LINKED: 'linkes?',
    OTT: 'Direkte- TV via internett',
  },

  MAP: {
    BACK: 'Tilbake',
    MAP: 'Kart',
  },

  MONTHS: {
    JANURAY: 'Januar',
    FEBUARY: 'Februar',
    MARCH: 'Mars',
    APRIL: 'April',
    MAY: 'Mai',
    JUNE: 'Juni',
    JULY: 'Juli',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCOTBER: 'Oktober',
    NOVEMBER: 'November',
    DECEMBER: 'Desember',
  },

  NO_WORK_ORDERS: {
    NO_WORK_ORDERS: 'Du har ingen nye work orders!',
    WAIT_FOR_NEXT: 'Vent på din neste',
    NO_ACCESS: 'Du mangler den rette tilgangen for å hente ut work orders',
    TO_FRONT_PAGE: 'Gå tilbake til forsiden',
    DONE: 'Du har fullført alle dine work orders for i dag.',
    FAILED: 'Kunne ikke hente ut work orders',
    UNDONE: {
      TITLE: 'Bra jobba!',
      SUB_TITLE: 'Du har ingen umeldte jobber',
    },
  },

  ORDERED_PRODUCTS: {
    DIAGNOSIS: 'Diagnose',
    ESTIMATED_TIME: 'Estimert tid',
    PRODUCTS: 'Produkt / service',
    QUANTITY: 'Ant.',
    SERVICE_CODES: 'Servicekoder',
    TIME: 'Tid',
    HOURS: 't',
    MINUTES: 'm',
  },

  PRODUCT_LIST: {
    TODAYS_PRODUCT_LIST: 'Pakkeliste for dagen',
  },

  REMOVE_PRODUCT_POPUP: {
    CANCEL: 'Avbryt',
    ERROR_MESSAGE: 'Du kan ikke slette dette produktet!',
    OBS: 'OBS...',
    OK: 'OK',
    REMOVE: 'Ja, slett',
    REMOVE_PRODUCT: 'Slett produkt',
    REMOVE_PRODUCT_QUESTION: 'Ønsker du å slette produktet?',
  },

  SCANNER: {
    SCANNER: 'Skanner',
  },

  SERVICE_LIST: {
    TOTAL: 'Totalt:',
    EMPTY_SERVICE_LIST: 'Arbeidsordren må ha minst en servicekode',
  },

  SIGN_IN: {
    HEADER: 'Logg inn på HomeService',
    INPUT_USERNAME: 'Brukernavn',
    INPUT_PASSWORD: 'Passord',
    TECHNICIAN: 'Technician',
    SIGN_IN_BUTTON: 'Logg inn',
    ERROR_TEXT: 'Feil brukernavn eller passord.',
  },

  SVG: {
    DONE: 'Ferdig!',
  },

  TIME_SLOTS: {
    AVAILABLE: 'Ledig',
    NO_TIME_SLOT: 'Ingen tidspunkt tilgjengelig',
    SELECTED: 'Valgt',
    SELECT_TIME: 'Velg et tidspunkt',
    TIME: 'Tid',
    TIME_SHORT: 'kl',
  },

  WEEKDAYS: {
    MONDAY: 'Mandag',
    TUESDAY: 'Tirsdag',
    WEDNESDAY: 'Onsdag',
    THURSDAY: 'Torsdag',
    FRIDAY: 'Fredag',
    SATURDAY: 'Lørdag',
    SUNDAY: 'Søndag',
  },

  WEEKDAYS_SHORT: {
    MO: 'MA',
    TU: 'TI',
    WE: 'ON',
    TH: 'TO',
    FR: 'FR',
    SA: 'LØ',
    SU: 'SØ',
  },

  WORK_LOG: {
    COMPLETE_BUTTON: 'Fullført',
    COMPLETE_WORKORDER_BUTTON: 'Fullfør work order',
    CREATE_NEW_WORK_ORDER: 'Opprett ny work order',
    HOURS: 'timer',
    IMAGE_ERROR: 'Du kan laste opp maks 50 bilder.',
    NOT_COMPLETE_BUTTON: 'Ikke fullført',
    OTHER: 'Annet',
    WORK_ORDER_CREATED: 'Work order opprettet',
    FOLLOW_UP: 'Oppfølging av kunde',
    FOLLOW_UP_CREATED: 'Oppfølging opprettet',
    AZIMUTH_INSTALLED: 'Azimuth installert',
    AZIMUTH_PROPOSED: 'Azimuth foreslått',
    POPUP_TITLE: 'Fullfør workorder?',
    POPUP_TEXT: 'Ett eller flere produkter er ikke aktivert.',
    POPUP_CANCEL_BUTTON: 'Avbryt',
    POPUP_COMPLETE_BUTTON: 'Fullfør',
  },

  WORKORDER_BUTTON: {
    DRIVE: 'Start kjøring',
    WORK: 'Start arbeid',
    UNABLE_TO_START: 'Du må fullføre din påbegynte work order',
    ERROR: 'Could not update status: ',
    CONTACT_KETIL: 'Contact Ketil',
  },

  WORKORDER_DETAILS: {
    WORKORDER: 'Work order',
    DRIVING_TIME: 'Kjøretid: ',
    WORKING_TIME: 'Arbeidstid: ',
    ESTIMATED_TIME: 'Estimert tid: ',
    HOURS: ' timer',
    HOUR: ' time',
  },

  WORKORDER_LIST: {
    DRIVESTARTED: 'Kjøring pågår',
    WORKSTARTED: 'Arbeid pågår',
    CANCELLED_JOBS: 'Kansellerte jobber',
    COMPLETED_HEADER: 'Tidligere arbeid',
    WORKORDER: 'Work order',
    TIME_ESTIMATE: 'Est.tid',
    TODAY: 'I dag',
    YOUR_NEXT: 'Dine neste',
  },

  WORKORDER_TABS: {
    TECHNICAL_INFORMATION_HEADING: 'Teknisk informasjon',
    ADDRESS_HISTORY: 'Adressehistorikk',
  },

  PREV_WORKORDERS: {
    TITLE: 'Umeldte jobber',
  },

  WORKORDER_HISTORY: {
    WORKORDER_HISTORY: 'Arbeidsordrehistorikk',
    NO_WORKORDER_HISTORY: 'Ingen arbeidsordrehistorikk funnet.',
    WORKORDER_ID: 'Arbeidsordre-ID:',
    USER: 'Bruker',
    STATUS_ID: 'Status-ID',
    REASON: 'Årsak',
  },
};

export default Object.freeze(NOB);
