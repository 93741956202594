import removeFirstValueFromBracketString from './removeFirstValueFromBracketString';

export default function hideSelectedDiagnosticServices(
  values,
  selectedDiagnosticServices,
  removeFirstValue,
) {
  const filteredValues = values.filter(
    value =>
      !selectedDiagnosticServices
        .map(service =>
          removeFirstValue
            ? removeFirstValueFromBracketString(service.description)
            : service.description,
        )
        .includes(value.name),
  );

  return filteredValues;
}
