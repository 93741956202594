/* eslint-disable prefer-const */
import PropTypes from 'prop-types';
import Quagga from 'quagga';
import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import './scanner.scss';
import Icon from '../../icons';
import { CHECK, CROSS, CROSS_LARGE, SWITCH2 } from '../../constants/iconNames';
import SlideInPage from '../SlideInPage/SlideInPage';
import IconButton from '../Buttons/IconButton/IconButton';
import quaggaConfig from './quaggaConfig';
import SimpleButton from '../Buttons/SimpleButton/SimpleButton';
import isiOS from '../../utils/isiOS';

function Scanner({ results, onHeaderClick, totalDevices, onDetected }) {
  const [currentDeviceId, setCurrentDeviceId] = useState(0);
  const [deviceIds, setDeviceIds] = useState([]);

  const iOS = isiOS();

  const initScanner = async () => {
    // Make camera lens switching available only for Android
    if (!iOS) {
      // Get all camera sources and filter for videoinputs
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter(device => device.kind === 'videoinput');

      // console.log(cameras);

      // Filters out all camera sources not including "back" in label as most devices has this as a part of the label
      // of back camera sources, if none are found after filter we use all
      const filteredBackDeviceIds = cameras.filter(camera => camera.label.includes('back'));
      const foundCameras = filteredBackDeviceIds.length ? filteredBackDeviceIds : cameras;

      // Set all deviceIds found in component state
      const foundCameraDeviceIds = foundCameras.map(foundCamera => foundCamera.deviceId);

      setDeviceIds(foundCameraDeviceIds);

      // Try to get the remembered deviceId from localstorage. If none deviceId found in localstorage or if unknown
      // we use the first found from the deviceIds
      const rememberedDeviceId = localStorage.getItem('deviceId');
      const initDeviceIndex =
        rememberedDeviceId && foundCameraDeviceIds.includes(rememberedDeviceId)
          ? foundCameraDeviceIds.indexOf(rememberedDeviceId)
          : currentDeviceId;

      setCurrentDeviceId(initDeviceIndex);
      Quagga.init(quaggaConfig(foundCameraDeviceIds[initDeviceIndex]), () => Quagga.start());
    } else {
      Quagga.init(quaggaConfig(), () => Quagga.start());
    }
  };

  useEffect(() => {
    try {
      Quagga.stop();
    } catch (error) {
      // Do nothing, this is to ensure prev Quagga instance is stopped
    }
    initScanner();

    if (onDetected) Quagga.onDetected(onDetected);

    return () => {
      Quagga.offDetected();
      Quagga.stop();
    };
  }, []);

  const toggleCameraLens = deviceId => {
    localStorage.setItem('deviceId', deviceId);
    Quagga.stop();
    Quagga.init(quaggaConfig(deviceId), () => {
      Quagga.start();
    });
  };

  const onCameraChange = () => {
    if (currentDeviceId === deviceIds.length - 1) {
      setCurrentDeviceId(0);
      toggleCameraLens(deviceIds[0]);
    } else {
      const deviceIdIndex = currentDeviceId + 1;

      setCurrentDeviceId(deviceIdIndex);
      toggleCameraLens(deviceIds[deviceIdIndex]);
    }
  };

  return ReactDOM.createPortal(
    <SlideInPage
      mainHeading="Aktiver produkter"
      rightIcon={<IconButton onClick={onHeaderClick} icon={CROSS_LARGE} />}
    >
      <div id="scanner" className="scanner-container">
        <div className="scanner-container-overlay scanner-container-overlay--top">
          <div className="scanner-result-content">
            <span className="result-count">
              {totalDevices && (
                <div>
                  {results.filter(result => result.isActivated).length} / {totalDevices}
                </div>
              )}
            </span>
            <span className="result-list">
              {results.map(result => (
                <div key={result.serialNumber} className="list-element">
                  {result.isFetching && !result.isActivated && (
                    <ClipLoader color="#fff" size={12} />
                  )}
                  {result.isActivated && !result.isFetching && <Icon name={CHECK} fill="#fff" />}
                  {!result.isActivated && !result.isFetching && (
                    <span className="x-cross">
                      <Icon name={CROSS} fill="#fff" />
                    </span>
                  )}
                  <span
                    className={
                      !result.isActivated && !result.isFetching
                        ? 'serialnumber--error'
                        : 'serialnumber'
                    }
                  >
                    {result.serialNumber}
                  </span>
                </div>
              ))}
            </span>
          </div>
        </div>
        <div className="scanner-container-overlay scanner-container-overlay--bottom" />
      </div>
      {deviceIds.length > 1 && !iOS && (
        <div className="scanner-container__toggle">
          <div className="scanner-container__toggle__description">
            Hvis du ikke får scannet, prøv å endre kameralinse
          </div>
          <SimpleButton icon={SWITCH2} title="Endre kameralinse" onClick={onCameraChange} />
        </div>
      )}
    </SlideInPage>,
    document.getElementById('scanner-root'),
  );
}

Scanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
};

Scanner.defaultProps = {
  results: [],
};

export default Scanner;
