import React from "react";
import Variables from "../styles/variables.scss";

const SVG = ({ fill }) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>Yay</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="MVP-+-sprint-3-og-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2-helsesjekk-positiv" transform="translate(-37.000000, -104.000000)">
        <g id="Yay" transform="translate(37.000000, 104.000000)">
          <circle id="Oval" fill={fill} cx="10" cy="10" r="10" />
          <path d="M8.3452381,12.1706349 L15.5634921,4.95238095 L16.9920635,6.38095238 L8.32539683,15.047619 L6.8968254,13.6190476 L6.91666667,13.5992063 L4.00793651,10.6904762 L5.43650794,9.26190476 L8.3452381,12.1706349 Z" id="Combined-Shape" fill={Variables.whiteSecondary} />
        </g>
      </g>
    </g>
  </svg>
);

SVG.defaultProps = {
  fill: Variables.samGreen,
};

export default SVG;
