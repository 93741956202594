/**
 * Comparator for objects, sorted on the "name" property.
 *
 * @param objectA
 * @param objectB
 * @returns {number}
 */
export default (objectA, objectB) => {
  if (objectA.name > objectB.name) {
    return 1;
  } else if (objectB.name > objectA.name) {
    return -1;
  }

  return 0;
};
