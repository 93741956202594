import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Feedback from '../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../constants/feedbackNames';
import { FRONT_PAGE_PATH } from '../../constants/paths';
import NOB from '../../constants/language-strings';
import './infobox.scss';

export default function InfoBox({
  feedback,
  firstInfoBoxLine,
  link,
  secondInfoBoxLine,
  thirdInfoBoxLine,
}) {
  return (
    <div className="info-box">
      <div className="info-box__text">
        <h1>{firstInfoBoxLine}</h1>
        <h2>{secondInfoBoxLine}</h2>
        <h2>{thirdInfoBoxLine}</h2>
      </div>
      {link ? (
        <div className="info-box__feedback">
          {feedback || <Feedback name={FEEDBACK_NOT_FOUND} />}
          <Link to={FRONT_PAGE_PATH}>{NOB.NO_WORK_ORDERS.TO_FRONT_PAGE}</Link>
        </div>
      ) : (
        feedback
      )}
    </div>
  );
}

InfoBox.defaultProps = {
  feedback: null,
  firstInfoBoxLine: '',
  link: true,
  secondInfoBoxLine: NOB.INFO_BOX.NOT_EXIST,
  thirdInfoBoxLine: '',
};

InfoBox.propTypes = {
  feedback: PropTypes.element,
  firstInfoBoxLine: PropTypes.string,
  link: PropTypes.bool,
  secondInfoBoxLine: PropTypes.string,
  thirdInfoBoxLine: PropTypes.string,
};
