import { AZIMUTH } from '../WorkLog/workLogConstants';

export default (key, value, setAzimuthError) => {
  if (key === AZIMUTH) {
    if (
      // TODO: hotfix: due multi network components, we cannot know if azimuth is needed, so validate if not empty
      value &&
      (!value.match(/^[0-9]*$/g) || parseInt(value, 10) < 0 || parseInt(value, 10) > 359)
    ) {
      setAzimuthError('Azimuth må være mellom 0 og 359, uten desimaler');
    } else setAzimuthError('');
  }
};
