import PropTypes from 'prop-types';
import React from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { INCOMPLETE_WITHOUT_CODE } from '../../../constants/status-codes';
import NOB from '../../../constants/language-strings';
import '../containers/addressHistory.scss';

export default function DropdownContent({
  closeDropdown,
  spanLightText,
  singleAddressHistory: {
    actionTaken,
    associateNames,
    customerId,
    orderedItems,
    status,
    workDescription,
    workOrderId,
  },
}) {
  const incomplete = status.includes(INCOMPLETE_WITHOUT_CODE);

  return (
    <SlideDown
      className={incomplete ? 'dropdown__content--red' : 'dropdown__content--blue'}
      transitionOnAppear={false}
      closed={closeDropdown}
    >
      <div className="dropdown__content" key={workOrderId}>
        <div className="dropdown__content--table">
          <div>
            <span>{NOB.ADDRESS_HISTORY.STATUS}</span>
            <span className="dropdown__content--table--status">{status}</span>
          </div>
          <div>
            <span>{NOB.ADDRESS_HISTORY.USER}</span>
            {spanLightText(associateNames)}
          </div>
          <div>
            <span>{NOB.ADDRESS_HISTORY.WORKORDER}</span>
            {spanLightText(workOrderId)}
          </div>
          <div>
            <span>{NOB.ADDRESS_HISTORY.CUSTOMER_ID}</span>
            {spanLightText(customerId)}
          </div>
        </div>

        <div className="dropdown__content--item--left">
          <span>{NOB.ADDRESS_HISTORY.WORK_ORDER_DESCRIPTION}</span>
          {spanLightText(workDescription)}
        </div>

        <div className="dropdown__content--item--left">
          <span>{NOB.ADDRESS_HISTORY.SERVICE_CODES}</span>
          <div className="dropdown__content--item--left--service_codes">
            {orderedItems.itemSummary.map(item => (
              <span key={item.workOrderServiceId}>{item.name}</span>
            ))}
          </div>
        </div>

        {actionTaken && (
          <div className="dropdown__content--item--left">
            <span>{NOB.ADDRESS_HISTORY.COMMENT}</span>
            {spanLightText(actionTaken)}
          </div>
        )}
      </div>
    </SlideDown>
  );
}

DropdownContent.propTypes = {
  singleAddressHistory: PropTypes.objectOf(PropTypes.any).isRequired,
  closeDropdown: PropTypes.bool.isRequired,
  spanLightText: PropTypes.func.isRequired,
};
