import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import './pageSwiper.scss';

function PageSwiper({ children, containerClass, getSwiper, initialSlide }) {
  const params = {
    containerClass,
    slideActiveClass: 'swiper-slide-active',
    slideClass: 'swiper-slide',
  };

  return (
    <Swiper
      {...params}
      initialSlide={initialSlide}
      getSwiper={getSwiper}
      spaceBetween={40}
      shortSwipes={false}
      longSwipesMs={0}
      longSwipesRatio={0.2}
    >
      {children}
    </Swiper>
  );
}

PageSwiper.propTypes = {
  children: PropTypes.node.isRequired,
  containerClass: PropTypes.string.isRequired,
  getSwiper: PropTypes.func.isRequired,
  initialSlide: PropTypes.number.isRequired,
};

export default PageSwiper;
