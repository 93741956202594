import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { AGREEMENT_ID } from '../../../../../constants/constant-strings';
import { AMOUNT_MONEY } from '../../../../../constants/characters';
import { grayButton } from '../../../../../styles/variables.scss';
import NOB from '../../../../../constants/language-strings';
import priceFixed from '../../../../../utils/priceFixed';
import './agreementPrice.scss';
import { useQuery } from 'react-query';
import { http } from '../../../../../api/apiConfig';
import { CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL } from '../../../../../constants/endpoints';
import { withRouter } from 'react-router';

function AgreementPrice({
  customerId,
  agreementId,
  originalPrice,
  currentPrice,
  isFetchingPrices,
  ...props
}) {
  const [showDetailedPriceModal, setShowDetailedPriceModal] = useState(false);

  // eslint-disable-next-line react/prop-types
  const showDetailedPricesForCustomerView = props.location.pathname.includes('customerInfo');

  const {
    data: agreementsWithPrices,
    refetch,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ['agreementsWithPrices', customerId],
    queryFn: async () => {
      const { data } = await http.get(CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL(customerId));

      return data;
    },
    enabled: showDetailedPricesForCustomerView,
  });

  const { CUSTOMER_PRODUCTS } = NOB;

  return (
    <div className="customer__products__container__agreement__info">
      <span className="customer__products__container__agreement-id">
        {`${AGREEMENT_ID} ${agreementId}`}
      </span>

      {isFetchingPrices ? (
        <ClipLoader size={16} color={grayButton} />
      ) : (
        <span className="customer__products__container__price">
          {`${CUSTOMER_PRODUCTS.PRICE_PREFIX} ${priceFixed(currentPrice)}${AMOUNT_MONEY}`}
          {currentPrice !== originalPrice && (
            <span className="customer__products__container__price--original">
              {`${priceFixed(originalPrice)}${AMOUNT_MONEY}`}
            </span>
          )}
          {showDetailedPricesForCustomerView && (
            <button
              className="details"
              onClick={() => {
                void refetch();
                setShowDetailedPriceModal(state => !state);
              }}
            >
              Detaljer
            </button>
          )}
        </span>
      )}
      {showDetailedPriceModal && showDetailedPricesForCustomerView && (
        <div className="modal">
          <div className="modalHeader">
            <div className="fetchInfo">
              {!isLoading && isFetching ? <>Refetching prices...</> : <></>}
            </div>
            <button
              className="closeButton"
              onClick={() => setShowDetailedPriceModal(state => !state)}
            >
              Close
            </button>
          </div>
          {agreementsWithPrices ? (
            agreementsWithPrices.agreements.map(item => {
              if (item.agreementId !== agreementId) {
                return <></>;
              } else {
                return (
                  <>
                    <div className="singleProduct tableHeading">
                      <div className="productName">Name</div>
                      <div className="productPrice">Monthly Price</div>
                      <div className="productPrice">Installation Price</div>
                    </div>
                    {!!item.products.length ? (
                      item.products.map(product => (
                        <div className="singleProduct" key={product.name}>
                          <div className="productName">{product.name}</div>
                          <div className="productPrice">{product.monthlyPrice}</div>
                          <div className="productPrice">{product.installationPrice}</div>
                        </div>
                      ))
                    ) : (
                      <>No products found</>
                    )}
                  </>
                );
              }
            })
          ) : (
            <div className="fetchInfo">Loading...</div>
          )}
        </div>
      )}
    </div>
  );
}

AgreementPrice.propTypes = {
  agreementId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  originalPrice: PropTypes.number.isRequired,
  currentPrice: PropTypes.number.isRequired,
  isFetchingPrices: PropTypes.bool.isRequired,
};

export default withRouter(AgreementPrice);
