import {
  UPDATE_SERVICE_ERROR,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
} from '../workLogConstants';

export const requestUpdateService = ({
  added,
  quantity,
  serviceId,
  workOrderId,
  workOrderServiceId,
}) => ({
  type: UPDATE_SERVICE_REQUEST,
  workOrderId,
  serviceId,
  quantity,
  added,
  workOrderServiceId,
});

export const errorUpdateService = ({ errorMessage }) => ({
  type: UPDATE_SERVICE_ERROR,
  errorMessage,
});

export const successUpdateService = ({ workOrder }) => ({
  type: UPDATE_SERVICE_SUCCESS,
  workOrder,
});
