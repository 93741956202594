import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/addressHistoryActions';
import { ADDRESS_HISTORY, CUSTOMER_ADDRESS_HISTORY } from '../../../constants/endpoints';
import {
  ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST,
  ADDRESS_HISTORY_REQUEST,
} from '../addressHistoryConstants';
import { getAPI } from '../../../api/apiConfig';

export function* callAddressHistory({ workOrderId }) {
  try {
    const url = ADDRESS_HISTORY(workOrderId);
    const result = yield call(getAPI, url);

    yield put(actions.receiveAddressHistory({ addressHistory: result }));
  } catch (error) {
    yield put(
      actions.errorAddressHistory({
        errorMessage: `Could not receive address history. ${error.message}`,
      }),
    );
  }
}

export function* callAddressHistoryByAddressId({ validAddressId }) {
  try {
    const url = CUSTOMER_ADDRESS_HISTORY(validAddressId);
    const result = yield call(getAPI, url);

    yield put(actions.receiveAddressHistory({ addressHistory: result }));
  } catch (error) {
    yield put(
      actions.errorAddressHistory({
        errorMessage: `Could not receive address history by validAddressId: ${validAddressId}. Error: ${error.message}`,
      }),
    );
  }
}
export default [
  takeLatest(ADDRESS_HISTORY_REQUEST, callAddressHistory),
  takeLatest(ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST, callAddressHistoryByAddressId),
];
