import {
  MAP_ID_FAILURE,
  MAP_ID_REQUEST,
  MAP_ID_SUCCESS,
  MAP_TOKEN_FAILURE,
  MAP_TOKEN_REQUEST,
  MAP_TOKEN_SUCCESS,
} from '../constants';

export const requestMapId = () => ({
  type: MAP_ID_REQUEST,
});

export const receiveMapId = ({ mapId }) => ({
  type: MAP_ID_SUCCESS,
  mapId,
});

export const errorMapId = ({ errorMessage }) => ({
  type: MAP_ID_FAILURE,
  errorMessage,
});

export const requestMapToken = () => ({
  type: MAP_TOKEN_REQUEST,
});

export const receiveMapToken = ({ mapToken }) => ({
  type: MAP_TOKEN_SUCCESS,
  mapToken,
});

export const errorMapToken = ({ errorMessage }) => ({
  type: MAP_TOKEN_FAILURE,
  errorMessage,
});
