import {
  HEALTH_CHECK_REQUEST,
  HEALTH_CHECK_RECEIVE,
  HEALTH_CHECK_ERROR,
  UPDATE_HEALTH_CHECK_REQUEST,
  ICC_CHECK_ERROR,
  ICC_CHECK_RECEIVE,
  ICC_CHECK_REQUEST,
} from '../constants';

const healthCheckDefaultState = {
  provisioning: {
    healthCheckResponses: [],
  },
  iccCheck: {},
  isFetching: true,
  isIccFetching: true,
  errorMessage: '',
};

export default (state = healthCheckDefaultState, action) => {
  switch (action.type) {
    case HEALTH_CHECK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case HEALTH_CHECK_RECEIVE:
      return {
        ...state,
        provisioning: action.provisioning,
        isFetching: false,
      };
    case HEALTH_CHECK_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      };
    case UPDATE_HEALTH_CHECK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ICC_CHECK_REQUEST:
      return {
        ...state,
        isIccFetching: true,
      };
    case ICC_CHECK_RECEIVE:
      return {
        ...state,
        iccCheck: action.iccCheck,
        isIccFetching: false,
      };
    case ICC_CHECK_ERROR:
      return {
        ...state,
        isIccFetching: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};
