import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Quagga from 'quagga';
import Scanner from '../../components/Scanner/Scanner';
import { putAPI } from '../../api/apiConfig';
import { ACTIVATE_PRODUCT } from '../../constants/endpoints';
import {
  updateSingleProduct,
  requestCustomerProductsBackground,
} from '../CustomerProducts/actions/customerProducts';

function DeviceScannerContainer({
  dpadIdsToActivate,
  productId,
  updateProduct,
  onClose,
  onActivation,
  onSerialNumberFound,
  customerId,
  updateAllProducts,
}) {
  // List over completed activation requests
  const [results, setResults] = useState([]);
  // List over scanned serialNumbers
  const [scannerResults, setScannerResults] = useState([]);

  // This effect is used to coompare the scannedResults againts the results from the activation request
  useEffect(() => {
    const newResults = scannerResults.map(result => {
      if (results.find(prevResult => prevResult.serialNumber === result.serialNumber)) {
        const isActivated = result.isActivated
          ? result.isActivated
          : results.find(prevResult => prevResult.serialNumber === result.serialNumber).isActivated;

        return { ...result, isActivated, isFetching: false };
      }

      return result;
    });

    setScannerResults(newResults);
  }, [results]);

  const activateProduct = async (requestBody, newScannerResultList) => {
    await putAPI(ACTIVATE_PRODUCT(productId), requestBody)
      .then(result => {
        onClose();
        onActivation();
        updateAllProducts(customerId);

        return updateProduct(result);
      })
      .catch(error => {
        const activatedSerialNumbers = error.data.length
          ? error.data.filter(data => !data.isValid).map(data => data.serialNumber)
          : [];
        const activationResultList = newScannerResultList.map(result => ({
          ...result,
          isActivated: activatedSerialNumbers.includes(result.serialNumber),
          isFetching: false,
        }));

        onSerialNumberFound(
          activationResultList.filter(result => result.isActivated),
          error.data,
        );
        setResults(activationResultList);
      });
  };

  // Adds a scanned result to the result list and performs a product activation request
  const onDetected = result => {
    const resultShouldBeAdded = !scannerResults
      .map(({ serialNumber }) => serialNumber)
      .includes(result.codeResult.code.toUpperCase());

    if (resultShouldBeAdded) {
      const regex = new RegExp('^[A-Z0-9]*$');

      if (regex.test(result.codeResult.code)) {
        Quagga.offDetected(onDetected);
        const newScannerResult = {
          serialNumber: result.codeResult.code.toUpperCase(),
          isActivated: false,
          isFetching: true,
        };
        const newScannerResultList = [...scannerResults, newScannerResult];

        setScannerResults(newScannerResultList);
        const activationRequest = dpadIdsToActivate.map(id => ({
          dpadId: id,
          serialNumbers: newScannerResultList.map(({ serialNumber }) => serialNumber),
        }));

        activateProduct(activationRequest, newScannerResultList);
      }
    }
  };

  useEffect(() => {
    Quagga.onDetected(onDetected);

    return () => Quagga.offDetected(onDetected);
  }, [scannerResults]);

  return (
    <Scanner
      results={scannerResults}
      onDetected={onDetected}
      onHeaderClick={onClose}
      totalDevices={dpadIdsToActivate.length}
    />
  );
}

DeviceScannerContainer.propTypes = {
  dpadIdsToActivate: PropTypes.arrayOf(PropTypes.number).isRequired,
  productId: PropTypes.number.isRequired,
  updateProduct: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onActivation: PropTypes.func.isRequired,
  onSerialNumberFound: PropTypes.func,
  updateAllProducts: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
};

DeviceScannerContainer.defaultProps = {
  onSerialNumberFound: () => {},
};

const mapStateToProps = state => {
  const { customerId } = state.workOrder.workOrder.customer;

  return { customerId };
};

const mapDispatchToProps = dispatch => ({
  updateProduct: newProduct => dispatch(updateSingleProduct(newProduct)),
  updateAllProducts: customerId => dispatch(requestCustomerProductsBackground(customerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceScannerContainer);
