import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import {
  FRONT_PAGE_PATH,
  SIGN_OUT_PATH,
  WORK_ORDER_ID_PATH,
  WORK_LOG_PATH,
  ADDRESS_SEARCH,
  CUSTOMER_INFO_PATH,
} from '../constants/paths';
import InfoBox from '../components/InfoBox/InfoBox';
import SignOut from '../modules/Authentication/containers/SignOutContainer';
import WorkOrderContainer from '../modules/Workorder/containers/workOrder/WorkOrderContainer';
import WorkOrderListContainer from '../modules/WorkorderList/containers/WorkOrderListContainer';
import WorkLogContainer from '../modules/WorkLog/containers/WorkLogContainer';
import { AddressSearch } from '../features/address-search';
import CustomerPage from '../modules/Workorder/containers/customerPage/CustomerPage';

const Routes = () => (
  <Switch>
    <Route exact path={FRONT_PAGE_PATH} component={WorkOrderListContainer} />
    <Route
      exact
      path={WORK_ORDER_ID_PATH}
      render={({ match }) => <WorkOrderContainer workOrderId={+match.params.id} />}
    />
    <Route
      exact
      path={CUSTOMER_INFO_PATH}
      render={({ match }) => <CustomerPage customerId={+match.params.customerId} />}
    />
    <Route exact path={SIGN_OUT_PATH} component={SignOut} />
    <Route exact path={WORK_LOG_PATH} component={WorkLogContainer} />
    <Route exact path={ADDRESS_SEARCH} render={() => <AddressSearch />} />

    <Route
      path="*"
      render={() => (
        <div className="no__know__route">
          <InfoBox />
        </div>
      )}
    />
  </Switch>
);

export default Routes;
