import React, { useState } from 'react';
import NOB from '../../constants/language-strings';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 498px;
  margin: 48px 16px;
  padding: 64px 80px;
  background: white;
  border-radius: 0.33em;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 46px;
  margin: 4px 0 10px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
`;

const StyledError = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 10px;
  color: rgb(190, 0, 64);
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 16px 24px;
  margin-top: 20px;
  background-color: #29003e;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 99px;
  border: 1px solid #29003e !important;
  outline: 0;
  transition: all 150ms ease;
  &:hover {
    background: #5e0092;
    color: white;
  }
`;

export const AuthenticationDev = ({ requestDevSignIn, signInError }) => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    technician: '',
  });

  const { username, password, technician } = credentials;

  const handleChange = event => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    requestDevSignIn({
      credentials: {
        username,
        password,
        technician,
      },
    });
  };

  return (
    <StyledWrapper>
      <StyledForm onSubmit={e => handleSubmit(e)}>
        <h1>{NOB.SIGN_IN.HEADER}</h1>
        <div>
          <label htmlFor="username">{NOB.SIGN_IN.INPUT_USERNAME}</label>
          <StyledInput
            name="username"
            type="text"
            autoComplete="on"
            value={username}
            onChange={e => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="password">{NOB.SIGN_IN.INPUT_PASSWORD}</label>
          <StyledInput
            name="password"
            type="password"
            autoComplete="on"
            value={password}
            onChange={e => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="technician">{NOB.SIGN_IN.TECHNICIAN}</label>
          <StyledInput
            name="technician"
            type="text"
            autoComplete="on"
            value={technician}
            onChange={e => handleChange(e)}
          />
        </div>
        {signInError && <StyledError>{NOB.SIGN_IN.ERROR_TEXT}</StyledError>}
        <StyledButton type="submit">{NOB.SIGN_IN.SIGN_IN_BUTTON} </StyledButton>
      </StyledForm>
    </StyledWrapper>
  );
};

AuthenticationDev.propTypes = {
  signInError: PropTypes.bool.isRequired,
  requestDevSignIn: PropTypes.func.isRequired,
};
