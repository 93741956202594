export default function sortMinToMax(list, sortBy) {
  const sorted = list.sort((a, b) => {
    if (a[sortBy] > b[sortBy]) return 1;

    if (a[sortBy] < b[sortBy]) return -1;

    return 0;
  });

  return sorted;
}
