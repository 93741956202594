import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/TextInput/TextInput';
import {
  COMMENT,
  FOLLOW_UP,
  CUSTOMER_NOT_HOME,
  CONFIRMED_RIGHT_ADDRESS,
  RUNG_KNOCKED_DOOR,
  CALLED_CUSTOMER,
  AZIMUTH,
} from '../../WorkLog/workLogConstants';
import NOB from '../../../constants/language-strings';
import Checkbox from '../../../components/Checkbox/Checkbox';
import NumberInput from '../../../components/NumberInput/NumberInput';
import DatafieldRow from '../../../components/DatafieldRow/DatafieldRow';

function WorkOrderCompletionFields({
  handleChange,
  values,
  technician,
  azimuthErrorMessage,
  azimuthProposed,
  isFWA,
}) {
  return (
    <div className="content">
      <TextInput
        onChange={handleChange}
        maxLength="500"
        name={COMMENT}
        value={values[COMMENT] || ''}
        label={NOB.FOLLOW_UP.COMMENT_LABEL}
      />
      {azimuthProposed && isFWA && (
        <DatafieldRow
          title={NOB.WORK_LOG.AZIMUTH_PROPOSED}
          value={azimuthProposed}
          symbol="&deg;"
        />
      )}
      {isFWA && (
        <NumberInput
          onChange={handleChange}
          value={values[AZIMUTH] || ''}
          name={AZIMUTH}
          placeholder="0 - 359"
          label={NOB.WORK_LOG.AZIMUTH_INSTALLED}
          icon="&#176;"
          errorMessage={azimuthErrorMessage}
        />
      )}
      {!technician && (
        <Checkbox
          onClick={handleChange}
          name={FOLLOW_UP}
          label={NOB.FOLLOW_UP.FOLLOW_UP}
          checked={values[FOLLOW_UP]}
        />
      )}

      <div className="not__home__checkbox__group">
        <Checkbox
          checked={values[CUSTOMER_NOT_HOME]}
          onClick={handleChange}
          label={NOB.FOLLOW_UP.NOT_HOME}
          name={CUSTOMER_NOT_HOME}
        />

        {values[CUSTOMER_NOT_HOME] && (
          <div className="not-home-checkboxes--sub">
            <Checkbox
              checked={values[CONFIRMED_RIGHT_ADDRESS]}
              onClick={handleChange}
              label={NOB.FOLLOW_UP.CONFIRMED_RIGHT_ADDRESS}
              name={CONFIRMED_RIGHT_ADDRESS}
            />

            <Checkbox
              checked={values[RUNG_KNOCKED_DOOR]}
              onClick={handleChange}
              label={NOB.FOLLOW_UP.RUNG_KNOCKED_DOOR}
              name={RUNG_KNOCKED_DOOR}
            />

            <Checkbox
              checked={values[CALLED_CUSTOMER]}
              onClick={handleChange}
              label={NOB.FOLLOW_UP.CALLED_CUSTOMER}
              name={CALLED_CUSTOMER}
            />
          </div>
        )}
      </div>
    </div>
  );
}

WorkOrderCompletionFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  technician: PropTypes.bool.isRequired,
  azimuthErrorMessage: PropTypes.string,
  azimuthProposed: PropTypes.string,
  isFWA: PropTypes.bool,
};

WorkOrderCompletionFields.defaultProps = {
  azimuthErrorMessage: '',
  azimuthProposed: '',
  isFWA: false,
};

export default WorkOrderCompletionFields;
