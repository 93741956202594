import {
  DEVICE_POLLING_CUSTOMER_ERROR,
  DEVICE_POLLING_CUSTOMER_RECEIVE,
  DEVICE_POLLING_CUSTOMER_REQUEST,
} from '../constants';

const devicePollingDefaultState = {
  isFetching: true,
  devicePollingCustomer: {
    checkPassed: false,
    platform: '',
    deviceStatus: [],
  },
  errorMessage: '',
};

export default (
  state = devicePollingDefaultState,
  { devicePollingCustomer, errorMessage, type },
) => {
  switch (type) {
    case DEVICE_POLLING_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case DEVICE_POLLING_CUSTOMER_RECEIVE:
      return {
        ...state,
        devicePollingCustomer,
        isFetching: false,
      };

    case DEVICE_POLLING_CUSTOMER_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };

    default:
      return state;
  }
};
