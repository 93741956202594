import {
  UPDATE_DEVICE_POLLING_MAC_ERROR,
  UPDATE_DEVICE_POLLING_MAC_RECEIVE,
  UPDATE_DEVICE_POLLING_MAC_REQUEST,
} from '../constants';

const updateDevicePollingMacDefaultState = {
  devicePollingMac: {},
  isFetching: false,
  errorMessage: '',
};

export default (state = updateDevicePollingMacDefaultState, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_POLLING_MAC_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_DEVICE_POLLING_MAC_RECEIVE:
      return {
        ...state,
        devicePollingMac: action.devicePollingMac,
        isFetching: false,
      };
    case UPDATE_DEVICE_POLLING_MAC_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};
