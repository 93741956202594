export default function subMainErrorCategoryValues(
  showSubCategories,
  showMainErrors,
  name,
  subCategory,
  mainError,
) {
  let subCategoryValue = '';
  let mainErrorValue = '';

  if (showSubCategories) {
    subCategoryValue = name;
  } else if (showMainErrors) {
    subCategoryValue = subCategory;
    mainErrorValue = name;
  } else {
    subCategoryValue = subCategory;
    mainErrorValue = mainError;
  }

  return { subCategoryValue, mainErrorValue };
}
