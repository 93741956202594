import React from 'react';
import { TabLink, Tabs, TabContent } from 'react-tabs-redux';
import PropTypes from 'prop-types';
import HealthCheckTabContent from './HealthCheckTabContent';
import './healthCheck.scss';

function HealthCheckTabs({ categories }) {
  return (
    <Tabs className="health-check-wrapper">
      <div className="tabs-wrapper">
        {categories.map(category => (
          <TabLink to={category.name} key={category.name}>
            {category.name}
          </TabLink>
        ))}
      </div>
      {categories.map((category, index) => (
        <TabContent for={category.name} default={index === 0} key={category.name}>
          <HealthCheckTabContent categoryValues={category.mainCategoryValues} />
        </TabContent>
      ))}
    </Tabs>
  );
}

HealthCheckTabs.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      mainCategoryValues: PropTypes.arrayOf(
        PropTypes.shape({
          subcategoryValues: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              propertyValues: PropTypes.arrayOf(
                PropTypes.shape({
                  type: PropTypes.string.isRequired,
                  value: PropTypes.objectOf(
                    PropTypes.shape({
                      first: PropTypes.objectOf(
                        PropTypes.shape({
                          value: PropTypes.string.isRequired,
                          valueStatus: PropTypes.string.isRequired,
                        }),
                      ),
                      second: PropTypes.objectOf(
                        PropTypes.shape({
                          value: PropTypes.string.isRequired,
                          valueStatus: PropTypes.string.isRequired,
                        }),
                      ),
                    }),
                  ).isRequired,
                }),
              ).isRequired,
            }),
          ),
          name: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HealthCheckTabs;
