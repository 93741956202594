import React from "react";
import FeedbackHmm from "./FeedbackHmm";
import FeedbackJuggle from "./FeedbackJuggle";
import FeedbackNotFound from "./FeedbackNotFound";
import FeedbackPopcorn from "./FeedbackPopcorn";
import FeedbackThinking from "./FeedbackThinking";
import * as FEEDBACK from "../constants/feedbackNames";

const Feedback = props => {
  switch (props.name) {
    case FEEDBACK.FEEDBACK_JUGGLE:
      return <FeedbackJuggle {...props} />;
    case FEEDBACK.FEEDBACK_NOT_FOUND:
      return <FeedbackNotFound {...props} />;
      case FEEDBACK.FEEDBACK_POPCORN:
        return <FeedbackPopcorn {...props} />;
    case FEEDBACK.FEEDBACK_THINKING:
      return <FeedbackThinking {...props} />;
    default:
      return <FeedbackHmm {...props} />;
  }
};

export default Feedback;
