import React from 'react';
import PropTypes from 'prop-types';
import '../../sam.scss';
import NOB from '../../../../constants/language-strings';
import Item from './Item';

const type = 'Modem info';
const ModemInfo = ({ devicePollingMac }) => (
  <div className="overview__section__items--value--modem">
    <Item
      value={devicePollingMac.Status}
      header={NOB.DOWNSTREAM_UPSTREAM.STATUS}
      statusColor={devicePollingMac.Online ? 'status--good' : 'status--error'}
      type={type}
    />
    <Item
      value={devicePollingMac.UpTimeDescription}
      header={NOB.DOWNSTREAM_UPSTREAM.UPTIME}
      type={type}
    />
    <Item value={devicePollingMac.Serial} header={NOB.DOWNSTREAM_UPSTREAM.SERIAL} type={type} />
    <Item value={devicePollingMac.MacAddress} header={NOB.DOWNSTREAM_UPSTREAM.MAC_1} type={type} />
    <Item value={devicePollingMac.Ipv4} header={NOB.DOWNSTREAM_UPSTREAM.IP} type={type} />
    <Item value={devicePollingMac.Cmts} header={NOB.DOWNSTREAM_UPSTREAM.CMTS} type={type} />
    <Item value={devicePollingMac.ConfigFile} header={NOB.DOWNSTREAM_UPSTREAM.CONFIG} type={type} />
  </div>
);

ModemInfo.propTypes = {
  devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModemInfo;
