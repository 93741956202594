import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NOB from '../../../../constants/language-strings';
import './notHome.scss';
import WorkOrderCompletionFormContainer from '../../../WorkOrderCompletionForm/containers/WorkOrderCompletionFormContainer';
import SlideInPage from '../../../../components/SlideInPage/SlideInPage';
import { requestStatusChange } from '../../actions/workOrder';
import { TECHNICIAN } from '../../../Authentication/constants';
import { TRAVELING_STATUS } from '../../../../constants/status-codes';

const NotHome = ({ statusChangeRequest, role, workOrder: { workOrder } }) => {
  useEffect(() => {
    if (role === TECHNICIAN && workOrder.status === TRAVELING_STATUS) {
      statusChangeRequest(workOrder.status);
    }
  }, [workOrder.status]);

  return (
    <SlideInPage mainHeading={NOB.CUSTOMER_NOT_HOME.CUSTOMER_NOT_HOME} showLeftIcon>
      <div className="not_home">
        <WorkOrderCompletionFormContainer customerNotHome />
      </div>
    </SlideInPage>
  );
};

NotHome.propTypes = {
  statusChangeRequest: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  role: state.authentication.role,
  workOrder: state.workOrder,
});

const mapDispatchToProps = dispatch => ({
  statusChangeRequest: currentStatus => dispatch(requestStatusChange(currentStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotHome);
