import React from 'react';
import PropTypes from 'prop-types';

function InfoText({ text }) {
  return <div className="info-text">{text}</div>;
}

InfoText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoText;
