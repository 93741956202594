export default function setCategoriesValues(multiples, singles, type) {
  const values = [];

  const singleNames = singles.map(single => single[type]);

  const multipleArray = multiples.map(category => ({
    name: category[type],
    value: category.id,
  }));

  singles.map(category => {
    values.push({
      name: category[type],
      value: category.id,
    });

    return values;
  });

  // Only show duplicate categories once
  const names = multipleArray
    .map(category => category.name)
    .filter((value, id, array) => array.indexOf(value) === id && !singleNames.includes(value));

  if (names.length) {
    for (let i = 0; i <= names.length; i++) {
      values.push({
        name: names[i],
        value: i + 1,
      });
    }
  }

  return values;
}
