import React from "react";
import Variables from "../styles/variables.scss";

const SVG = ({ color }) => (
  <svg width="14px" height="20px" viewBox="0 0 11 15">
    <title>Telefoni_bl</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/Telefoni_blå" fill={color} stroke={color}>
        <g id="Telefoni_blå">
          <path d="M7.87198832,10.4386036 C7.80081391,10.4727694 7.7275025,10.5079486 7.65425965,10.5430782 C7.65309947,10.5436347 7.65309947,10.5436347 7.65193929,10.5441911 C7.23476481,10.7442793 7.01780432,10.8478878 6.99911381,10.8556348 C5.003084,11.803505 1.6701221,5.43745507 3.65650005,4.39598709 L4.50626116,3.98662519 L2.76505919,0.661138818 C2.69945219,0.692630996 2.62315264,0.729160594 2.53498085,0.771359962 C2.53482944,0.771433056 2.53482944,0.771433056 2.53467804,0.771506151 C2.04411277,1.00835659 2.04411277,1.00835659 1.87928822,1.08778718 C1.53905815,1.26123449 1.44298343,1.31538827 1.2781867,1.44143382 C0.0519188871,2.37935043 0.0549457081,4.51931022 2.40780645,9.01411406 C5.03798836,14.0395238 6.76750742,15.0154481 8.47921372,14.2924251 C8.55456375,14.2605973 8.9083027,14.1010226 8.77604905,14.1601672 C8.80190058,14.1486031 9.05728104,14.0265314 9.42813717,13.8485655 C9.42923833,13.8480371 9.42923833,13.8480371 9.43033949,13.8475086 C9.49642287,13.8157964 9.56259323,13.78403 9.62730049,13.7529566 L7.87198832,10.4386036 Z M9.18425385,15.0730575 C9.18420997,15.0730772 9.18416634,15.0730967 9.18412295,15.0731161 C9.18416691,15.0730964 9.18421084,15.0730768 9.18425471,15.0730571 Z" id="Imported-Layers-6" />
        </g>
      </g>
    </g>
  </svg>
);

SVG.defaultProps = {
  color: Variables.turquoiseBlue
}

export default SVG;
