import PropTypes from 'prop-types';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import Icon from '../../../../icons/index';
import { STATUS_ERROR, STATUS_OK } from '../../../../constants/iconNames';
import DeviceStatus from '../../../../constants/deviceStatus';
import getDeviceStatusColor from '../../../../utils/getDeviceColorStatus';

export default function StatusCheck({ checkTitle, isFetching, deviceStatus }) {
  return (
    <div className="health__check__status__container--item">
      {isFetching ? (
        <ClipLoader size={20} />
      ) : (
        <div>
          {deviceStatus === DeviceStatus.OK || deviceStatus === DeviceStatus.NEUTRAL ? (
            <Icon name={STATUS_OK} fill={getDeviceStatusColor(deviceStatus)} />
          ) : (
            <Icon name={STATUS_ERROR} />
          )}
        </div>
      )}
      {checkTitle}
    </div>
  );
}

StatusCheck.propTypes = {
  checkTitle: PropTypes.string.isRequired,
  deviceStatus: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
};

StatusCheck.defaultProps = {
  deviceStatus: '',
};
