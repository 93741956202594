import PropTypes from 'prop-types';
import React from 'react';
import ClickOutHandler from 'react-onclickout';
import { CROSS_LARGE } from '../../constants/iconNames';
import './serialNumberInput.scss';
import ScannerIcon from '../Buttons/ScannerIcon/ScannerIcon';
import IconButton from '../Buttons/IconButton/IconButton';

function SerialNumberInput({
  errorMessage,
  value,
  showScannerIcon,
  onScannerClick,
  onReset,
  handleChange,
  handleSubmit,
  id,
  isFetching,
}) {
  const onClick = e => {
    if (e.key === 'Enter') handleSubmit(e);

    if (e.key === 'Escape') onReset(id);
  };

  const onOutsideClick = () => (!errorMessage && value ? handleSubmit() : null);

  return (
    <ClickOutHandler onClickOut={onOutsideClick}>
      <div className={errorMessage ? 'serial-number-input__invalid' : 'serial-number-input'}>
        <div className="text-area">
          <div className="input-field">
            <input
              type="text"
              value={value || ''}
              name={id}
              placeholder="Mac/serienummer"
              onChange={handleChange}
              onKeyDown={onClick}
              disabled={isFetching}
            />
          </div>
          {errorMessage && !isFetching && <div className="error">{errorMessage}</div>}
        </div>
        {value && (
          <IconButton
            onClick={() => {
              onReset(id);
            }}
            fill={errorMessage ? undefined : '#000'}
            className={errorMessage ? 'icon__button--error' : ''}
            icon={CROSS_LARGE}
          />
        )}
        {!value && showScannerIcon && <ScannerIcon onClick={onScannerClick} />}
      </div>
    </ClickOutHandler>
  );
}

SerialNumberInput.propTypes = {
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showScannerIcon: PropTypes.bool,
  onReset: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onScannerClick: PropTypes.func,
  isFetching: PropTypes.bool,
};

SerialNumberInput.defaultProps = {
  showScannerIcon: false,
  onScannerClick: () => {},
  errorMessage: '',
  value: '',
  onReset: () => {},
  isFetching: false,
};

export default SerialNumberInput;
