import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ fill }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Checkbox_huket-av2" fill={fill}>
        <g id="Checkbox_selected" transform="translate(5.000000, 6.000000)">
          <g id="Group-9">
            <polygon id="Rectangle-199" transform="translate(3.622855, 8.959061) rotate(-315.000000) translate(-3.622855, -8.959061) " points="0.0108152673 7.72585937 7.23489553 7.72585937 7.23489553 10.1922625 0.0108152673 10.1922625" />
            <polygon id="Rectangle-199" transform="translate(9.582418, 6.343045) rotate(-585.000000) translate(-9.582418, -6.343045) " points="2.3583373 5.10984377 16.8064978 5.10984377 16.8064978 7.57624693 2.3583373 7.57624693" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
};

SVG.defaultProps = {
  fill: Variables.iconBlue,
};

export default SVG;
