import {
  STATUS_CHANGE_REQUEST,
  STATUS_CHANGE_SUCCESS,
  STATUS_CHANGE_FAILURE,
  STATUS_CHANGE_CHECK_SKIP,
  WORKORDER_REQUEST,
  WORKORDER_SUCCESS,
  WORKORDER_FAILURE,
  WORKORDER_REQUEST_BY_CUSTOMER_ID,
} from '../workOrderConstants';

import {
  REMOVE_SERVICE_SUCCESS,
  REMOVE_SERVICE_ERROR,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_ERROR,
} from '../../WorkLog/workLogConstants';

function emptyWorkOrder() {
  return {
    workOrderId: 0,
    customer: {
      customerId: 0,
      firstName: '',
      phoneNumber: '',
      surname: '',
    },
    address: {},
    status: '',
    workDescription: '',
    technicalDetails: {},
    associateId: '',
    actionTaken: '',
    attachments: {
      attachmentList: [],
      hasError: false,
    },
    capacity: {
      data: [],
    },
  };
}

const workOrderReducerDefaultState = {
  workOrder: emptyWorkOrder(),
  isFetching: false,
  isPrimaryFetching: false,
  errorMessage: '',
  statusChangeError: '',
};

export default (
  state = workOrderReducerDefaultState,
  { close, errorMessage, token, type, workOrder, status },
) => {
  switch (type) {
    case STATUS_CHANGE_REQUEST:
      return {
        ...state,
        isFetching: false,
        isPrimaryFetching: true,
        statusChangeError: '',
      };

    case STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          status,
        },
        isFetching: false,
        isPrimaryFetching: false,
      };

    case STATUS_CHANGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isPrimaryFetching: false,
        statusChangeError: errorMessage,
      };

    case STATUS_CHANGE_CHECK_SKIP:
      return {
        ...state,
        isFetching: false,
        isPrimaryFetching: false,
        close,
      };

    case WORKORDER_REQUEST_BY_CUSTOMER_ID:
    case WORKORDER_REQUEST:
      return {
        ...state,
        token,
        isFetching: true,
        isPrimaryFetching: false,
      };

    case WORKORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isPrimaryFetching: false,
        workOrder,
        errorMessage: '',
      };

    case WORKORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isPrimaryFetching: false,
        errorMessage,
        workOrder: emptyWorkOrder(),
      };

    case REMOVE_SERVICE_SUCCESS:
      return {
        ...state,
        workOrder,
      };

    case REMOVE_SERVICE_ERROR:
      return {
        ...state,
        errorMessage,
      };

    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        workOrder,
      };

    case UPDATE_SERVICE_ERROR:
      return {
        ...state,
        errorMessage,
      };

    default:
      return state;
  }
};
