import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({
  bodyFill,
  coverColor,
  darkStroke,
  darkestStroke,
  eyeFill,
  handFill,
}) => (
  <svg width="147px" height="84px" viewBox="0 0 147 84" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/tilbakemelding_hmm">
            <g id="Group-41" transform="translate(25.000000, 4.000000)">
                <g id="Group-40">
                    <path
                      id="Fill-6"
                      fill={bodyFill}
                      d="M67.8238112,83.8418701 L26.2172185,77.5177662 C22.3134964,76.9239481 19.6053042,73.2499221 20.2000773,69.3524675 L27.0142384,24.6631688 C27.6090115,20.7657143 31.2901424,18.0618701 35.1926678,18.6544935 L76.7992605,24.9785974 C80.7041793,25.5724156 83.4111748,29.2464416 82.8164017,33.1450909 L76.0022406,77.8331948 C75.4086642,81.7306494 71.7275333,84.4356883 67.8238112,83.8418701"
                    />
                    <path
                      id="Stroke-8"
                      stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      d="M67.8238112,83.8418701 L26.2172185,77.5177662 C22.3134964,76.9239481 19.6053042,73.2499221 20.2000773,69.3524675 L27.0142384,24.6631688 C27.6090115,20.7657143 31.2901424,18.0618701 35.1926678,18.6544935 L76.7992605,24.9785974 C80.7041793,25.5724156 83.4111748,29.2464416 82.8164017,33.1450909 L76.0022406,77.8331948 C75.4086642,81.7306494 71.7275333,84.4356883 67.8238112,83.8418701 Z"
                    />
                    <path
                      id="Fill-10"
                      fill={eyeFill}
                      d="M42.2671169,39.5506805 C42.0852145,40.7442909 40.9686686,41.5651221 39.7731387,41.3823169 C38.5776089,41.2007065 37.7554577,40.0859532 37.9385569,38.8923429 C38.1192626,37.6999273 39.2370052,36.8790961 40.4325351,37.0607065 C41.6268682,37.2423169 42.4478226,38.3570701 42.2671169,39.5506805"
                    />
                    <path
                      id="Fill-12"
                      fill={eyeFill}
                      d="M68.1475258,43.4844571 C67.9656233,44.6780675 66.8478807,45.4977039 65.6535476,45.3160935 C64.4580177,45.1344831 63.6358666,44.0197299 63.817769,42.8261195 C63.9996715,41.6325091 65.1174141,40.8128727 66.3117472,40.9944831 C67.5072771,41.1760935 68.3282315,42.2908468 68.1475258,43.4844571"
                    />
                    <path
                      id="Stroke-14"
                      stroke={darkStroke} strokeWidth="2" strokeLinecap="round"
                      d="M61.8833802,55.5154286 C55.9631738,53.8618182 46.145229,52.207013 40.1197108,52.207013"
                    />
                    <path
                      id="Fill-16"
                      fill={coverColor}
                      d="M99.0742912,77.1793974 L99.0742912,90.8049558 C99.0742912,91.4656831 98.0020242,91.999761 96.6808381,91.999761 L6.33635895,91.999761 C5.01397609,91.999761 3.94290581,91.4656831 3.94290581,90.8049558 L3.94290581,77.1793974"
                    />
                    <g id="Group-21" transform="translate(3.590180, 76.467532)">
                        <path
                          id="Fill-18"
                          fill={bodyFill}
                          d="M0.352486746,0.711864935 L95.4838722,0.711864935"
                        />
                        <path
                          id="Stroke-20"
                          stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                          d="M0.352486746,0.711864935 L95.4838722,0.711864935"
                        />
                    </g>
                    <path
                      id="Fill-22"
                      fill={handFill}
                      d="M101.468821,23.487839 C101.468821,23.487839 108.403852,23.6802026 111.449521,18.5353714 C114.49519,13.3917351 111.140766,12.9018649 108.212376,13.9927221 C105.383314,15.0477351 102.505187,19.4828519 101.468821,23.487839"
                    />
                    <path
                      id="Stroke-24"
                      stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      d="M101.468821,23.487839 C101.468821,23.487839 108.403852,23.6802026 111.449521,18.5353714 C114.49519,13.3917351 111.140766,12.9018649 108.212376,13.9927221 C105.383314,15.0477351 102.505187,19.4828519 101.468821,23.487839 Z"
                    />
                    <path
                      id="Stroke-26"
                      stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      d="M81.9729488,47.8016468 C81.9729488,47.8016468 107.599652,56.1079325 104.934542,43.359361 C102.198825,30.2846078 103.151419,36.8249714 101.741675,23.9210753"
                    />
                    <path
                      id="Fill-28"
                      fill={handFill}
                      d="M9.5101975,11.9639429 C9.5101975,11.9639429 2.66133132,10.8539688 0.636469961,5.23121558 C-1.3883914,-0.391537662 1.99954153,-0.244576623 4.66943851,1.37557922 C7.25077773,2.94077403 9.2445242,7.83589091 9.5101975,11.9639429"
                    />
                    <path
                      id="Stroke-30"
                      stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      d="M9.5101975,11.9639429 C9.5101975,11.9639429 2.66133132,10.8539688 0.636469961,5.23121558 C-1.3883914,-0.391537662 1.99954153,-0.244576623 4.66943851,1.37557922 C7.25077773,2.94077403 9.2445242,7.83589091 9.5101975,11.9639429 Z"
                    />
                    <path
                      id="Stroke-32" 
                      stroke={darkestStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      d="M24.0918321,39.4981091 C24.0918321,39.4981091 -2.64184281,42.8567065 2.37363825,30.8333818 C7.51477561,18.5029922 5.35109397,24.7494338 9.16027465,12.3389922"
                    />
                    <path 
                      id="Stroke-34"
                      stroke={darkStroke} strokeWidth="2" strokeLinecap="round"
                      d="M88.3960197,18.1917455 L84.7615611,22.1357974"
                    />
                    <path
                      id="Stroke-36"
                      stroke={darkStroke} strokeWidth="2" strokeLinecap="round"
                      d="M92.4139095,26.4188156 L87.9249881,27.5264"
                    />
                    <path
                      id="Stroke-38"
                      stroke={darkStroke} strokeWidth="2" strokeLinecap="round"
                      d="M79.8752068,14.7297974 L79.1188756,19.4349403"
                    />
                </g>
            </g>
        </g>
    </g>
</svg>
);

SVG.propTypes = {
  bodyFill: PropTypes.string,
  coverColor: PropTypes.string,
  darkStroke: PropTypes.string,
  darkestStroke: PropTypes.string,
  eyeFill: PropTypes.string,
  handFill: PropTypes.string,
};

SVG.defaultProps = {
  bodyFill: Variables.whiteSecondary,
  coverColor: Variables.grayPrimary,
  darkStroke: Variables.feedbackDarkerGray,
  darkestStroke: Variables.feedbackDarkestGray,
  eyeFill: Variables.blackSecondary,
  handFill: Variables.yellowOnBlack,
};

export default SVG;
