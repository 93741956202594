import React from 'react';

import capitalize from './capitalizeString';
import NOB from '../constants/language-strings';
import Status, { COMPLETE_WITHOUT_CODE, CANCELLED } from '../constants/status-codes';
import setStatusTimeDiv from './setStatusTimeDiv';
import { hours, minutes } from './timeCalculator';

export default function setStatusTimeValue(workOrder, filteredWorkOrderStatus, isPastDue) {
  let workOrderStatus = `${NOB.WORKORDER_LIST.TIME_ESTIMATE}: 
  ${hours(workOrder.totalTimeEstimate)} ${minutes(workOrder.totalTimeEstimate)}`;

  if (filteredWorkOrderStatus.includes(COMPLETE_WITHOUT_CODE)) {
    workOrderStatus = (
      <span className="status_time--bold-status">{capitalize(filteredWorkOrderStatus)}</span>
    );
  } else if (filteredWorkOrderStatus === CANCELLED) {
    workOrderStatus = setStatusTimeDiv(
      filteredWorkOrderStatus,
      workOrderStatus,
      true,
      workOrder.serviceDate,
      isPastDue,
    );
  } else if (Status.ALLOCATED.includes(workOrder.status)) {
    const stringToShow = `kl.${workOrder.timeSlot}`;

    workOrderStatus = setStatusTimeDiv(
      stringToShow,
      workOrderStatus,
      false,
      workOrder.serviceDate,
      isPastDue,
    );
  } else if (Status.TRAVELING.includes(workOrder.status)) {
    workOrderStatus = setStatusTimeDiv(
      NOB.WORKORDER_LIST.DRIVESTARTED,
      workOrderStatus,
      true,
      workOrder.serviceDate,
      isPastDue,
    );
  } else if (Status.ON_SITE.includes(workOrder.status)) {
    workOrderStatus = setStatusTimeDiv(
      NOB.WORKORDER_LIST.WORKSTARTED,
      workOrderStatus,
      true,
      workOrder.serviceDate,
      isPastDue,
    );
  }

  return workOrderStatus;
}
