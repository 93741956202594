import { ACTIVATE_PRODUCT_SUCCESS, REMOVE_PRODUCT_SUCCESS } from '../customerProductsConstants';

export const activateProductSuccess = newProduct => ({
  type: ACTIVATE_PRODUCT_SUCCESS,
  newProduct,
});

export const removeProductSuccess = removedProductId => ({
  type: REMOVE_PRODUCT_SUCCESS,
  removedProductId,
});
