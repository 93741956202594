exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes change-color{0%{border:solid 2px #086670}100%{border:solid 2px #fdfdfd}}@-moz-keyframes change-color{0%{border:solid 2px #086670}100%{border:solid 2px #fdfdfd}}@keyframes change-color{0%{border:solid 2px #086670}100%{border:solid 2px #fdfdfd}}@-webkit-keyframes animate-add-service-button{0%{margin-right:0px}100%{margin-right:calc(100% - 194px)}}@-moz-keyframes animate-add-service-button{0%{margin-right:0px}100%{margin-right:calc(100% - 194px)}}@keyframes animate-add-service-button{0%{margin-right:0px}100%{margin-right:calc(100% - 194px)}}.circle-loader,.circle-loader-black,.circle-loader-green,.circle-loader-white{position:absolute;left:0;font-size:2px;border-radius:50%;animation-name:loader-spin;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear;display:inline-block;vertical-align:top}@keyframes loader-spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.circle-loader-black{border-left:2px solid #1e1c1e;border-top:2px solid #1e1c1e;border-right:2px solid #1e1c1e;border-bottom:2px solid transparent;margin:0px 0 2px 2px;width:6em;height:6em}.circle-loader-green{margin:2px 0 0px 2px;border-left:2px solid #fdfdfd;border-top:2px solid #fdfdfd;border-right:2px solid #fdfdfd;border-bottom:2px solid transparent;background-color:#086670;z-index:2;width:8em;height:8em}.circle-loader-white{margin:2px 0 0px 2px;border-left:2px solid #086670;border-top:2px solid #086670;border-right:2px solid #086670;border-bottom:2px solid transparent;width:8em;height:8em}.linking{width:100%;margin-bottom:40px}.linking .linking--error-message{width:calc(100% - 30px);max-width:750px;margin:30px 0;color:#f41c50}.linking ul{width:100%;max-width:750px;list-style:none;margin:0;padding:0}.linking ul li{padding-top:15px;margin-left:15px}\n", ""]);

// exports
exports.locals = {
	"backgroundFadedGreen": "#d0dedf",
	"backgroundGray": "#F2F2F2",
	"blackPrimary": "#1e1c1e",
	"blackSecondary": "#000",
	"buttonRed": "#f41c50",
	"darkStormyGray": "#444242",
	"errorRed": "#BF1000",
	"feedbackBlack": "#1d1b1d",
	"feedbackDarkestGray": "#221f1f",
	"feedbackDarkerGray": "#231f20",
	"feedbackBlue": "#7fdae4",
	"feedbackDarkGray": "#363335",
	"feedbackGreen": "#7dab7e",
	"feedbackPurple": "#a0025a",
	"grayButton": "#777",
	"grayHeading": "#f4f4f4",
	"grayPrimary": "#e8e8e8",
	"iconBlue": "#086670",
	"lightGrayButton": "#e9e8e7",
	"opacityWhiteLight": "rgba(255,255,255,0.9)",
	"redRemove": "#e91145",
	"samGreen": "#1e851e",
	"samOrange": "#f5a623",
	"samRed": "#e81418",
	"samGray": "#777",
	"turquoiseBlue": "#008391",
	"warningOrange": "#f46200",
	"whitePrimary": "#fdfdfd",
	"whiteSecondary": "#fff",
	"yellowOnBlack": "#ffe500",
	"yellowPrimary": "#ffdc00"
};