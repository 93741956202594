import moment from 'moment/moment';
import dateFromToday from './dateFromToday';
import { CANCELLED, COMPLETE_WITHOUT_CODE } from '../constants/status-codes';
import { DASH_YEAR_MONTH_DAY, DAYS } from '../constants/dateFormats';
import { SERVICE_DATE } from '../constants/elements';
import sortMinToMaxObject from './sortMinToMaxObject';

export default function workOrderDays(workOrders) {
  // Sorted list of serviceDates
  const serviceDates = sortMinToMaxObject(workOrders, SERVICE_DATE).map(
    workOrder => workOrder.serviceDate,
  );

  // Last day with work orders
  const maxDaysAhead = moment(serviceDates[serviceDates.length - 1]).diff(
    moment(dateFromToday().date, DASH_YEAR_MONTH_DAY),
    DAYS,
  );

  // List of days ahead
  const days = [...Array(maxDaysAhead + 1).keys()];

  let allWorkOrders = [];
  let dates = [];

  // Goes through all days and pushes days with values to array for all work orders
  days.map(day => {
    // List of work orders for a specific day in array
    const workOrderDay = workOrders.filter(
      workOrder => workOrder.serviceDate === dateFromToday(day).date,
    );

    // List of cancelled work orders
    const workOrdersCancelled = workOrderDay.filter(workOrder =>
      workOrder.status.includes(CANCELLED),
    );

    // List of not done work orders
    const workOrdersNotDone = workOrderDay.filter(
      workOrder =>
        !workOrder.status.includes(CANCELLED) && !workOrder.status.includes(COMPLETE_WITHOUT_CODE),
    );

    // List of done work orders
    const workOrdersDone = workOrderDay.filter(workOrder =>
      workOrder.status.includes(COMPLETE_WITHOUT_CODE),
    );

    // check if there are no work orders for the day
    const isWorkOrderListEmpty = !workOrderDay.length;

    // check if all work orders for the day are done
    const areAllWorkOrdersDone =
      workOrdersNotDone.length === 0 && (workOrdersDone.length > 0 || workOrdersCancelled > 0);

    // Only push day to array if the day has any work orders
    if (!isWorkOrderListEmpty || day === 0) {
      allWorkOrders = [
        ...allWorkOrders,
        {
          date: dateFromToday(day).date,
          workOrdersNotDone,
          workOrdersDone,
          workOrdersCancelled,
          isWorkOrderListEmpty,
          areAllWorkOrdersDone,
        },
      ];
    }

    dates = [...dates, dateFromToday(day).date];

    return allWorkOrders;
  });

  /*
    allWorkOrders: list of work orders sorted by date
    dates: list of dates from today to max day ahead
  */
  return { allWorkOrders, dates };
}
