import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ header, value, statusColor, secondValue, secondColor, unit, type }) => (
  <div className="overview__section__items">
    <div>{header}</div>
    <div
      className={
        type.includes('Modem')
          ? 'overview__section__items--value--modem'
          : 'overview__section__items--value'
      }
    >
      <span className={statusColor}>{` ${value} `}</span>
      {secondValue && (
        <span>
          /<span className={secondColor}>{` ${secondValue} `}</span>
        </span>
      )}
      {unit}
    </div>
  </div>
);

Item.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.string,
  statusColor: PropTypes.string,
  secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  secondColor: PropTypes.string,
  type: PropTypes.string,
};

Item.defaultProps = {
  value: 0,
  header: '',
  statusColor: '',
  secondValue: '',
  unit: '',
  secondColor: 'status--good',
  type: '',
};

export default Item;
