import React from 'react';
import PropTypes from 'prop-types';
import CategoryDropdown from './CategoryDropdown';
import { CATEGORY_ERROR, CATEGORY_MAIN, CATEGORY_SUB } from '../../addServiceCodeConstants';
import { ERROR, MAIN, SUB } from '../../../../constants/elements';
import NOB from '../../../../constants/language-strings';

const SelectCategories = ({
  categories,
  mainCategory,
  mainError,
  resetCategories,
  selectedCategory,
  showMainCategories,
  showMainErrors,
  showSubCategories,
  subCategory,
  toggleCategoryDropdown,
}) => {
  const showAddButton =
    mainError ||
    (mainCategory !== '' && !categories(CATEGORY_SUB).length) ||
    (subCategory && !categories(CATEGORY_ERROR).length);

  return (
    <div className="add__service__categories">
      <CategoryDropdown
        categories={categories(CATEGORY_MAIN)}
        toggleCategoryDropdown={toggleCategoryDropdown}
        selectedCategory={selectedCategory}
        show={showMainCategories}
        type={MAIN}
        value={mainCategory || NOB.ADD_SERVICE.MAIN_CATEGORY}
      />

      {mainCategory !== '' && (
        <CategoryDropdown
          categories={categories(CATEGORY_SUB)}
          toggleCategoryDropdown={toggleCategoryDropdown}
          selectedCategory={selectedCategory}
          show={showSubCategories}
          type={SUB}
          value={subCategory || NOB.ADD_SERVICE.SUB_CATEGORY}
        />
      )}

      {subCategory !== '' && (
        <CategoryDropdown
          categories={categories(CATEGORY_ERROR)}
          toggleCategoryDropdown={toggleCategoryDropdown}
          selectedCategory={selectedCategory}
          show={showMainErrors}
          type={ERROR}
          value={mainError || NOB.ADD_SERVICE.MAIN_ERROR}
        />
      )}
      {showAddButton && (
        <div className="add__service__categories--button-container">
          <button className="add__service__categories--button" onClick={resetCategories}>
            {NOB.ADD_SERVICE.RESET}
          </button>
        </div>
      )}
    </div>
  );
};

SelectCategories.propTypes = {
  categories: PropTypes.func.isRequired,
  mainCategory: PropTypes.string.isRequired,
  mainError: PropTypes.string.isRequired,
  resetCategories: PropTypes.func.isRequired,
  toggleCategoryDropdown: PropTypes.func.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  showMainCategories: PropTypes.bool.isRequired,
  showMainErrors: PropTypes.bool.isRequired,
  showSubCategories: PropTypes.bool.isRequired,
  subCategory: PropTypes.string.isRequired,
};

export default SelectCategories;
