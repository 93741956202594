import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from '../actions/agreementPriceActions';
import { AGREEMENT_PRICE } from '../../../constants/endpoints';
import { AGREEMENTS_PRICE_REQUEST } from '../customerProductsConstants';
import { getAPI } from '../../../api/apiConfig';

function* callAgreements() {
  try {
    const customerId = yield select(state => state.workOrder.workOrder.customer.customerId);
    const agreements = yield select(state => state.agreements.agreements);
    const url = AGREEMENT_PRICE(customerId);
    const agreementPrices = yield call(getAPI, url);

    const agreementsWithUpdatedPrice = agreements.map(agreement => ({
      ...agreement,
      currentPrice: agreementPrices.find(
        agreementPrice => agreement.agreementId === agreementPrice.agreementId,
      ).agreementPrice,
    }));

    yield put(actions.receiveAgreementsPrice({ agreements: agreementsWithUpdatedPrice }));
  } catch (error) {
    yield put(
      actions.errorAgreementsPrice({
        errorMessage: `Could not receive agreement prices. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(AGREEMENTS_PRICE_REQUEST, callAgreements)];
