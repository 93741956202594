import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/TextInput/TextInput';
import useForm from '../../../utils/customHooks/useForm';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import InfoText from './InfoText';
import NOB from '../../../constants/language-strings';
import { INPUT_TYPES } from '../FollowUpConstans';
import AddFiles from '../../../components/addFiles/addFiles';

function FollowUpForm({ files, form, onSubmit, isSubmitted, saveFiles }) {
  const { values, handleChange, handleSubmit } = useForm(() => {
    onSubmit(values);
  });
  const inputs = form.inputs.map(input => {
    switch (input.type) {
      case INPUT_TYPES.TEXT:
        return (
          <TextInput
            onChange={handleChange}
            label={input.headline}
            value={values[input.headline] ? values[input.headline] : ''}
            name={input.headline}
            placeholder={input.placeholder}
            key={input.headline}
            hasError={isSubmitted && !values[input.headline]}
          />
        );
      case INPUT_TYPES.CHECKBOX: {
        return (
          <Checkbox
            onClick={handleChange}
            name={input.option}
            label={input.option}
            key={input.option}
            checked={values[input.option]}
          />
        );
      }
      case INPUT_TYPES.RADIO:
        return (
          <RadioButtonGroup
            title={input.title}
            values={input.options}
            chosenValue={values[input.title]}
            onChange={handleChange}
            hasError={isSubmitted && !values[input.title]}
            key={input.title}
          />
        );
      case INPUT_TYPES.INFO_TEXT:
        return <InfoText text={input.headline} key={input.headline} />;
      default:
        return null;
    }
  });

  return (
    <div className="follow-up-form">
      {inputs}
      <AddFiles saveFiles={saveFiles} files={files} type="FollowUp" />
      <SimpleButton
        color={Color.BLACK}
        onClick={handleSubmit}
        title={NOB.FOLLOW_UP.CREATE_BUTTON}
      />
    </div>
  );
}

FollowUpForm.propTypes = {
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  saveFiles: PropTypes.func.isRequired,
};

export default FollowUpForm;
