import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

function Overlay({ children }) {
  return ReactDOM.createPortal(
    <div className="overlay">{children}</div>,
    document.getElementById('modal-root'),
  );
}

Overlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Overlay;
