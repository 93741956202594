import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { buttonRed } from '../../../../styles/variables.scss';
import { TRASH } from '../../../../constants/iconNames';
import ChangeProduct from '../../components/product/changeProduct/ChangeProduct';
import NOB from '../../../../constants/language-strings';
import * as changeAction from '../../actions/changeProductActions';
import './singleProduct.scss';
import IconButton from '../../../../components/Buttons/IconButton/IconButton';
import Popup from '../../../../components/Popup/containers/Popup';
import LinkingContainer from '../Linking/LinkingContainer';
import SlideInModal from '../../../../components/SlideInModal/SlideInModal';
import deviceSerialNumbersToString from '../../../../utils/products';
import { linkProductFailure } from '../../actions/linkProductActions';

function SingleProductContainer({
  changeProductState,
  clearErrorMessage,
  close,
  cleanUpLinkErrorMessage,
  display,
  isFetchingInBackground,
  isFetchingWorkOrder,
  requestChangeProduct,
  product,
  onDelete,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <SlideInModal
      display={display}
      onClose={close}
      mainHeading={product.name}
      subHeading={deviceSerialNumbersToString(product.devices)}
      isFetching={isFetchingWorkOrder || isFetchingInBackground}
      cleanUp={cleanUpLinkErrorMessage}
    >
      <div className="product__container">
        {showDeleteModal && (
          <Popup
            firstLine={NOB.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT}
            secondLine={NOB.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT_QUESTION}
            closePopup={() => setShowDeleteModal(false)}
            // TODO: dette er copy-pastet ved refaktorering, Popup komponenten bør refaktorers slik at knapper ikke lages her.
            // Eventuelt skifte navn på propsene slik at de gir mer mening
            button={
              <div className="popup__remove-buttons">
                <button onClick={() => setShowDeleteModal(false)}>
                  {NOB.REMOVE_PRODUCT_POPUP.CANCEL}
                </button>
                <button
                  onClick={() => {
                    setShowDeleteModal(false);
                    close();
                    onDelete();
                  }}
                >
                  {NOB.REMOVE_PRODUCT_POPUP.REMOVE}
                </button>
              </div>
            }
          />
        )}
        {<LinkingContainer productId={product.productId} />}

        {product.devices.length > 0 && (
          <ChangeProduct
            changeProductState={changeProductState}
            clearErrorMessage={clearErrorMessage}
            product={product}
            requestChangeProduct={requestChangeProduct}
          />
        )}

        {product.canBeRemoved && (
          <IconButton
            className="product__container--remove"
            onClick={() => setShowDeleteModal(true)}
            icon={TRASH}
            fill={buttonRed}
            text={NOB.CHANGE_PRODUCT.REMOVE_UNIT}
          />
        )}
      </div>
    </SlideInModal>
  );
}

SingleProductContainer.propTypes = {
  changeProductState: PropTypes.objectOf(PropTypes.any).isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  requestChangeProduct: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isFetchingInBackground: PropTypes.bool.isRequired,
  isFetchingWorkOrder: PropTypes.bool.isRequired,
  cleanUpLinkErrorMessage: PropTypes.func.isRequired,
  product: PropTypes.shape({
    canBeRemoved: PropTypes.bool.isRequired,
    devices: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  changeProductState: state.changeProduct,
  customerId: state.workOrder.workOrder.customer.customerId,
  customerProducts: state.customerProducts.customerProducts,
  isFetching: state.customerProducts.isFetching,
  isFetchingInBackground: state.customerProducts.isFetchingInBackground,
  isFetchingWorkOrder: state.workOrder.isFetching,
  isWorkOrderFetching: state.workOrder.isFetching,
  removeProductState: state.removeProduct,
  popupType: state.popup.popupType,
  closePopup: state.popup.close,
});

const mapDispatchToProps = dispatch => ({
  clearErrorMessage: errorProducts => dispatch(changeAction.clearErrorMessage({ errorProducts })),
  requestChangeProduct: (productId, dpadId, serialNumber, reload) =>
    dispatch(changeAction.requestChangeProduct(productId, dpadId, serialNumber, reload)),
  cleanUpLinkErrorMessage: () => dispatch(linkProductFailure('')),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleProductContainer));
