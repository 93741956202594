import React from 'react';
import PropTypes from 'prop-types';
import { SlideToggle } from 'react-slide-toggle';
import DropdownButton from './DropdownButton';
import Products from './Products';
import * as Animations from '../../../../../constants/animation';

export default function ProductList({ productList }) {
  return (
    <SlideToggle
      collapsed
      duration={Animations.DURATION}
      easeCollapse={Animations.COLLAPSE}
      easeExpand={Animations.EXPAND}
      render={({ onToggle, setCollapsibleElement, toggleState }) => (
        <div className="product__list">
          <DropdownButton onToggle={onToggle} toggleState={toggleState} />
          <div className="product__list__container" ref={setCollapsibleElement}>
            <div className="product__list__container--items">
              <Products productList={productList} />
            </div>
          </div>
        </div>
      )}
    />
  );
}

ProductList.propTypes = {
  productList: PropTypes.objectOf(PropTypes.any).isRequired,
};
