import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ stroke }) => (
  <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1">
    <title>icon/arbeid_outlined copy</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Symbols" stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/arbeid_blå-copy" transform="translate(-1.000000, 0.000000)" stroke={stroke}>
        <path d="M7.76923077,7.53410531 C6.16229907,7.01266855 5,5.49766615 5,3.70997851 C5,2.03855742 6.01602963,0.60550551 7.46153846,2.20351515e-13 L7.46153846,3.10132796 L8.7697612,4.15724515 C8.95307847,4.30520762 9.21481038,4.30513692 9.39804769,4.15707545 L10.7046154,3.10132796 L10.7046154,0.0741225378 C12.061225,0.716980983 13,2.10335745 13,3.70997851 C13,5.49766615 11.8377009,7.01266855 10.2307692,7.53410531 L10.2307692,15.7692308 C10.2307692,16.4489658 9.67973508,17 9,17 C8.32026492,17 7.76923077,16.4489658 7.76923077,15.7692308 L7.76923077,7.53410531 Z" id="Combined-Shape" transform="translate(9.000000, 8.500000) rotate(-45.000000) translate(-9.000000, -8.500000) " />
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  stroke: Variables.blackSecondary,
};

export default SVG;
