import {
  FOLLOW_UP_FORM_FAILURE,
  FOLLOW_UP_FORM_REQUEST,
  FOLLOW_UP_FORM_SUCCESS,
} from '../FollowUpConstans';

const followUpFormsReducerDefaultState = {
  isFetching: false,
  errorMessage: '',
  forms: [],
};

export default (state = followUpFormsReducerDefaultState, { errorMessage, forms, type }) => {
  switch (type) {
    case FOLLOW_UP_FORM_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FOLLOW_UP_FORM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        forms,
      };

    case FOLLOW_UP_FORM_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };

    default:
      return state;
  }
};
