import {
  REMOVE_SERVICE_ERROR,
  REMOVE_SERVICE_REQUEST,
  REMOVE_SERVICE_SUCCESS,
} from '../workLogConstants';

export const requestRemoveService = action => ({
  type: REMOVE_SERVICE_REQUEST,
  workOrderId: action.workOrderId,
  workOrderServiceId: action.workOrderServiceId,
});

export const errorRemoveService = action => ({
  type: REMOVE_SERVICE_ERROR,
  errorMessage: action.errorMessage,
});

export const successRemoveService = action => ({
  type: REMOVE_SERVICE_SUCCESS,
  workOrder: action.workOrder,
});
