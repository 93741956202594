import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WorkOrderDetails from '../WorkOrderDetails';
import { TRAVEL_AND_WORK_DURATION } from '../../../../../constants/endpoints';
import { getAPI } from '../../../../../api/apiConfig';

export default function WorkOrderDetailsContainer({
  done,
  workOrder,
  totalTimeEstimate,
  inWorkLog,
}) {
  const [travelDuration, setTravelDuration] = useState(0);
  const [workDuration, setWorkDuration] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [startTimeError, setStartTimeError] = useState(false);

  const requestTravelAndWorkDuration = workOrderId => {
    const url = TRAVEL_AND_WORK_DURATION(workOrderId);

    getAPI(url)
      .then(result => {
        setTravelDuration(result.travelDuration);
        setWorkDuration(result.workDuration);
        setIsFetching(false);

        return result;
      })
      .catch(() => {
        setStartTimeError(true);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    requestTravelAndWorkDuration(workOrder.workOrderId);
  }, []);

  return (
    <div className="workOrder__details__wrapper">
      {!startTimeError && (
        <WorkOrderDetails
          isFetching={isFetching}
          done={done}
          workOrder={workOrder}
          totalTimeEstimate={totalTimeEstimate}
          travelDuration={travelDuration}
          workDuration={workDuration}
          inWorkLog={inWorkLog}
        />
      )}
    </div>
  );
}

WorkOrderDetailsContainer.propTypes = {
  done: PropTypes.bool.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  totalTimeEstimate: PropTypes.number.isRequired,
  inWorkLog: PropTypes.bool.isRequired,
};
