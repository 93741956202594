// Paths
export const CANCEL = '/cancel';
export const STARTDRIVE = '/startdrive';
export const STARTWORK = '/startwork';

// Status
export const STATUS_CHANGE_CHECK_SKIP = 'STATUS_CHANGE_CHECK_SKIP';
export const STATUS_CHANGE_REQUEST = 'STATUS_CHANGE_REQUEST';
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS';
export const STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE';

// Work order
export const WORKORDER_REQUEST = 'WORKORDER_REQUEST';
export const WORKORDER_REQUEST_BY_CUSTOMER_ID = 'WORKORDER_REQUEST_BY_CUSTOMER_ID';
export const WORKORDER_SUCCESS = 'WORKORDER_SUCCESS';
export const WORKORDER_FAILURE = 'WORKORDER_FAILURE';
