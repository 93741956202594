import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import FetchingSpinner from '../utils/FetchingSpinner';
import { QA } from '../constants/paths';
import { requestTokenVerification } from '../modules/Authentication/actions/verifyTokenActions';
import Routes from './routes';
import SignIn from '../modules/Authentication/containers/SignInContainer';
import { TOKEN, URL } from '../constants/localStorage';
import '../modules/WorkorderList/components/workOrderListBottom/workOrderListBottom.scss';
import './offline.scss';
import Header from './Header/Header';
import SlideInPagePortal from '../components/SlideInPagePortal/SlideInPagePortal';
import ScrollToTop from '../components/ScrollTopTop/ScrollToTop';
import { ErrorPage } from '../modules/ErrorPage';
import AuthenticationDev from '../modules/authentication-dev/authentication-dev-container';
import RedirectionModal from '../modules/RedirectionModal/redirection-modal';

const PROD_ENV = 'getservice.get.no';
const PROD_ENV_AWS = 'homeservice.telia.no';
const DEV_ENV_AWS = 'homeservice-test.telia.io';
const hostname = window.location.hostname;

const StyledButton = styled.button`
  position: fixed;
  top: 12px;
  left: 16px;
  z-index: 10;
`;

let oldToken = '';

class app extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devLogin: false,
      showRedirectionModal: true,
    };
  }

  handleClickButton = () => {
    this.setState(prev => ({ devLogin: !prev.devLogin }));
  };

  handleCloseModal = () => {
    this.setState(prev => ({ showRedirectionModal: !prev.showRedirectionModal }));
  };

  componentDidMount = () => {
    const { requestTokenVerificationFunc } = this.props;

    const token = localStorage.getItem(TOKEN);

    if (token) {
      if (token !== oldToken) {
        oldToken = token;
        requestTokenVerificationFunc({ token: token });
      }
    }
  };

  render() {
    const { location, isAuthenticated, isAuthenticatedFetching } = this.props;

    if (window.location.host.includes(QA)) {
      window.onload = () => {
        document.body.id = 'qa';
      };
    } else {
      window.onload = () => {
        document.body.id = 'notQa';
      };
    }

    localStorage.setItem(URL, location.pathname + location.hash);

    const showDevLoginButton = () =>
      !isAuthenticated && hostname !== PROD_ENV && hostname !== PROD_ENV_AWS;

    const showDevLoginForm = () =>
      !isAuthenticated &&
      !isAuthenticatedFetching &&
      this.state.devLogin &&
      hostname !== PROD_ENV &&
      hostname !== PROD_ENV_AWS;

    const showRedirectModal = () =>
      this.state.showRedirectionModal &&
      hostname !== PROD_ENV_AWS &&
      hostname !== DEV_ENV_AWS &&
      hostname !== 'localhost';

    return (
      <Sentry.ErrorBoundary fallback={ErrorPage}>
        <div className="app__main">
          {localStorage.getItem(URL) && localStorage.getItem(URL) !== location.pathname}
          <Header />
          {showDevLoginButton() && (
            <StyledButton onClick={() => this.handleClickButton()}>
              {this.state.devLogin ? 'Regular Login' : 'Dev Login'}
            </StyledButton>
          )}
          <div className="main__main-container">
            {showDevLoginForm() && <AuthenticationDev />}
            {!isAuthenticated && !isAuthenticatedFetching && !this.state.devLogin && <SignIn />}
            {isAuthenticatedFetching && <FetchingSpinner />}
            {isAuthenticated && !isAuthenticatedFetching && (
              <ScrollToTop>
                <Routes location={location} />
              </ScrollToTop>
            )}
            <SlideInPagePortal hash={location.hash} />
          </div>
          {showRedirectModal() && (
            <RedirectionModal
              onCloseModal={this.handleCloseModal}
              hostname={hostname === PROD_ENV ? PROD_ENV_AWS : DEV_ENV_AWS}
            />
          )}
        </div>
      </Sentry.ErrorBoundary>
    );
  }
}

app.propTypes = {
  close: PropTypes.bool,
  requestTokenVerificationFunc: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticatedFetching: PropTypes.bool.isRequired,
  isFetchingWorkOrder: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  popupType: PropTypes.string,
};

app.defaultProps = {
  close: true,
  popupType: '',
};

const mapStateToProps = state => ({
  close: state.popup.close,
  isAuthenticated: state.authentication.isAuthenticated,
  isAuthenticatedFetching: state.authentication.isFetching,
  popupType: state.popup.popupType,
  isFetchingWorkOrder: state.workOrder.isFetching,
});

const mapDispatchToProps = dispatch => ({
  requestTokenVerificationFunc: token => dispatch(requestTokenVerification(token)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(app));
