import {
  AGREEMENTS_FAILURE,
  AGREEMENTS_REQUEST,
  AGREEMENTS_SUCCESS,
  AGREEMENTS_PRICE_FAILURE,
  AGREEMENTS_PRICE_SUCCESS,
  AGREEMENTS_PRICE_REQUEST,
} from '../customerProductsConstants';

const customerProductsReducerDefaultState = {
  agreements: [],
  customerProducts: [],
  errorMessage: '',
  isFetching: true,
  selectedAgreementId: 0,
  isFetchingPrices: false,
};

export default (state = customerProductsReducerDefaultState, action) => {
  const { agreements, errorMessage, type } = action;

  switch (type) {
    case AGREEMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements,
        isFetching: false,
      };

    case AGREEMENTS_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetching: false,
      };

    case AGREEMENTS_PRICE_REQUEST:
      return {
        ...state,
        isFetchingPrices: true,
        isFetching: false,
      };

    case AGREEMENTS_PRICE_SUCCESS:
      return {
        ...state,
        agreements,
        isFetchingPrices: false,
      };

    case AGREEMENTS_PRICE_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetchingPrices: false,
      };

    default:
      return state;
  }
};
