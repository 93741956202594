import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../icons/index';
import './iconButton.scss';

const IconButton = ({ className, onClick, icon, text, fill }) => (
  <button className={`icon__button ${className}`} onClick={onClick}>
    <Icon name={icon} fill={fill} />
    <span>{text}</span>
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
  text: '',
  fill: undefined,
};

export default IconButton;
