import React from 'react';
import PropTypes from 'prop-types';

export default function Heading({ icon, mainHeading, subHeading }) {
  return (
    <div className="product__container__heading">
      <div>
        {icon}
        <span>{mainHeading}</span>
      </div>
      <div>{subHeading}</div>
    </div>
  );
}

Heading.propTypes = {
  icon: PropTypes.element.isRequired,
  mainHeading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};
