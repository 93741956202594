import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import './styles/index.scss';
import App from './app/App';
import NOB from './constants/language-strings';
import configureStore from './store';
import { currentVersion } from './version';
import { getEnvironment } from './utils/getEnvironment';

const history = createHashHistory();
const store = configureStore({ NOB }, history);

Sentry.init({
  release: currentVersion,
  dsn: 'https://540a5e5fd6b44ee985dbb6a531b99dad@o47373.ingest.sentry.io/2851732',
  integrations: [new BrowserTracing()],
  environment: getEnvironment(),
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);

document.getElementById('version').innerText = currentVersion;
