import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ stroke }) => (
  <svg width="20px" height="22px" viewBox="0 0 20 22" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/bytte-av-enhet" stroke={stroke} strokeWidth="1.5">
            <g id="Group-Copy" transform="translate(5.000000, 12.500000) scale(-1, -1) translate(-5.000000, -12.500000) translate(0.000000, 3.000000)">
                <polyline id="Path-2" transform="translate(1.004892, 2.995185) rotate(-90.000000) translate(-1.004892, -2.995185) " points="-1 3.99036957 1.00018223 2 3.00978356 3.99036957"></polyline>
                <path d="M2.69962797,18.8114698 C6.30631824,18.0358793 9.009707,14.8287913 9.009707,10.990293 C9.009707,6.57201501 5.42798499,2.990293 1.009707,2.990293" id="Oval"></path>
            </g>
            <g id="Group-Copy-2" transform="translate(10.000000, 0.000000)">
                <polyline id="Path-2" transform="translate(1.004892, 2.995185) rotate(-90.000000) translate(-1.004892, -2.995185) " points="-1 3.99036957 1.00018223 2 3.00978356 3.99036957"></polyline>
                <path d="M2.69962797,18.8114698 C6.30631824,18.0358793 9.009707,14.8287913 9.009707,10.990293 C9.009707,6.57201501 5.42798499,2.990293 1.009707,2.990293" id="Oval"></path>
            </g>
        </g>
    </g>
</svg>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  stroke: Variables.iconBlue,
};

export default SVG;
