import { useState } from 'react';

const useForm = callback => {
  const [values, setValues] = useState({});

  const handleSubmit = event => {
    if (event) event.preventDefault();
    callback();
  };

  const handleChange = event => {
    event.persist();

    if (event.target.type === 'checkbox')
      setValues({ ...values, [event.target.name]: event.target.checked });
    else if (!event.target.value) {
      delete values[event.target.name];
      setValues({ ...values });
    } else setValues({ ...values, [event.target.name]: event.target.value });
  };

  const overrideValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const overrideValues = (...namesWithValue) => {
    const overridddenValues = { ...values };

    namesWithValue.forEach(nameAndValue => {
      overridddenValues[nameAndValue.name] = nameAndValue.value;
    });
    setValues(overridddenValues);
  };

  return {
    handleChange,
    handleSubmit,
    values,
    overrideValue,
    overrideValues,
  };
};

export default useForm;
