import { call, put, takeLatest } from 'redux-saga/effects';
import { postAPI, getAPI } from '../../../api/apiConfig';
import { START_DAY, SHIFT_STATUS } from '../../../constants/endpoints';
import { START_DAY_REQUEST } from '../constants';
import * as action from '../actions';

function* callStartDay() {
  try {
    const shiftStatus = yield call(getAPI, SHIFT_STATUS);

    if (!shiftStatus) {
      const dayActive = yield call(postAPI, START_DAY);

      yield put(action.receiveDayStatus({ dayActive }));
    }
  } catch (error) {
    yield put(
      action.errorStartDay({
        errorMessage: `Could not start day. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(START_DAY_REQUEST, callStartDay)];
