import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ color }) => (
  <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon/søk">
        <g id="Group-2-Copy-3" transform="translate(1.000000, 1.000000)">
          <rect id="Rectangle-9" fill={color} transform="translate(14.252938, 13.202969) rotate(-50.000000) translate(-14.252938, -13.202969) " x="13.0654383" y="8.96452768" width="2.375" height="8.47688311" rx="1" />
          <ellipse id="Oval-2" stroke={color} strokeWidth="1.5" cx="6.53125" cy="6.6" rx="6.53125" ry="6.6" />
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  color: PropTypes.string,
};

SVG.defaultProps = {
  color: Variables.whiteSecondary,
};

export default SVG;
