import { call, put, takeEvery } from 'redux-saga/effects';
import { errorWorkOrder, receiveWorkOrder, checkForStatusSkip } from '../../actions/workOrder';
import { getAPI } from '../../../../api/apiConfig';
import { requestAgreements } from '../../../CustomerProducts/actions/agreementsActions';
import { requestCustomerProducts } from '../../../CustomerProducts/actions/customerProducts';
import { WORKORDER, WORKORDER_BY_CUSTOMER_ID } from '../../../../constants/endpoints';
import { WORKORDER_REQUEST, WORKORDER_REQUEST_BY_CUSTOMER_ID } from '../../workOrderConstants';
import { requestAddressHistoryByAddressId } from '../../../AddressHistory/actions/addressHistoryActions';

export function* callWorkorder(request) {
  try {
    const url = WORKORDER({ workOrderId: request.workOrderId });
    const result = yield call(getAPI, url);

    yield put(receiveWorkOrder({ workOrder: result }));
    yield put(checkForStatusSkip(result.status));
    yield put(requestCustomerProducts({ customerId: result.customer.customerId }));
    yield put(requestAgreements({ customerId: result.customer.customerId }));
  } catch (error) {
    yield put(
      errorWorkOrder({
        errorMessage: `Could not receive work order. ${error.message}`,
      }),
    );
  }
}

export function* callCustomerInfo(request) {
  try {
    const url = WORKORDER_BY_CUSTOMER_ID(request.customerId);
    const result = yield call(getAPI, url);

    yield put(requestAddressHistoryByAddressId(result.address.validAddressId));

    yield put(receiveWorkOrder({ workOrder: result }));
    yield put(requestCustomerProducts({ customerId: result.customer.customerId }));
    yield put(requestAgreements({ customerId: result.customer.customerId }));
  } catch (error) {
    yield put(
      errorWorkOrder({
        errorMessage: `Could not receive customer info by customerId: ${request.customerId}. Error: ${error.message}`,
      }),
    );
  }
}

export default [
  takeEvery(WORKORDER_REQUEST, callWorkorder),
  takeEvery(WORKORDER_REQUEST_BY_CUSTOMER_ID, callCustomerInfo),
];
