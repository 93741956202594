import React from 'react';
import capitalize from './capitalizeString';
import getFormattedDate from './getFormattedDate';

export default function statusTimeDiv(status, time, bold, date, isPastDue) {
  const formattedDate = getFormattedDate(date);
  const statusTime = (
    <div className="status_time">
      <span className={`${bold ? 'status_time--bold-status' : ''} ${isPastDue ? 'warning' : ''}`}>
        <span className="date">{formattedDate}</span>
        <span className="status">{capitalize(status)}</span>
      </span>
      <span>{time}</span>
    </div>
  );

  return statusTime;
}
