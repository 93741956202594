import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from './DropdownButton';
import DropdownContent from './DropdownContent';
import '../containers/addressHistory.scss';

const AddressHistoryDropdown = ({ addressHistory, opened, toggleDropdown }) => {
  const spanLightText = content => (
    <span className="dropdown__content--item--light">{content}</span>
  );

  const dropdown = addressHistory.map(singleAddressHistory => {
    const open = opened === 0 ? addressHistory[0].workOrderId : opened;
    const closeDropdown = open !== singleAddressHistory.workOrderId;

    return (
      <div className="address_history__dropdown" key={singleAddressHistory.workOrderId}>
        <DropdownButton
          closeDropdown={closeDropdown}
          open={open}
          singleAddressHistory={singleAddressHistory}
          toggleDropdown={toggleDropdown}
        />
        <DropdownContent
          closeDropdown={closeDropdown}
          spanLightText={spanLightText}
          singleAddressHistory={singleAddressHistory}
        />
      </div>
    );
  });

  return <div className="dropdown">{dropdown}</div>;
};

AddressHistoryDropdown.propTypes = {
  addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
  opened: PropTypes.number.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

export default AddressHistoryDropdown;
