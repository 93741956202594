import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from '../actions/mapIdActions';
import { MAP_TOKEN_REQUEST, ORIGIN, DEVELOP } from '../constants';
import { MAP_TOKEN } from '../../../constants/endpoints';
import { getAPI } from '../../../api/apiConfig';

export function* callMapToken() {
  try {
    const origin = process.env.NODE_ENV === DEVELOP ? ORIGIN : window.location.origin;

    const URL = MAP_TOKEN(origin);
    const result = yield call(getAPI, URL);

    yield put(actions.receiveMapToken({ mapToken: result.token }));
  } catch (error) {
    yield put(
      actions.errorMapToken({
        errorMessage: `Could not receive token. ${error.message}`,
      }),
    );
  }
}

export default [takeEvery(MAP_TOKEN_REQUEST, callMapToken)];
