import React from 'react';
import PropTypes from 'prop-types';
import './radio-button.scss';

function RadioButton({ checked, name, onClick, label, value, isFetching }) {
  return (
    <label htmlFor={`${label}${name}`} className="radio-button">
      <span className={checked ? 'label--checked' : 'label'}>{label}</span>

      <input
        id={`${label}${name}`}
        checked={checked}
        value={value}
        type="radio"
        onClick={onClick}
        name={name}
        readOnly
      />
      <span className={isFetching ? 'circle-loader-black' : 'radio-checkmark'} />
    </label>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  isFetching: PropTypes.bool,
};

RadioButton.defaultProps = {
  isFetching: false,
};

export default RadioButton;
