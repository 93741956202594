import React from 'react';
import PropTypes from 'prop-types';

export default function Products({ productList }) {
  const products = Object.entries(productList).map(([key, value]) => (
    <div className="product__list__container--items--item" key={key}>
      <span>{value}</span>
      <span>{key}</span>
    </div>
  ));

  return products;
}

Products.propTypes = {
  productList: PropTypes.objectOf(PropTypes.any).isRequired,
};
