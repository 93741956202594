import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPullToRefresh from 'rmc-pull-to-refresh';
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import { ClipLoader } from '../../../../node_modules/react-spinners';
import { DOWNSTREAM, UPSTREAM } from '../../../constants/samSelector';
import Feedback from '../../../feedbacks';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import Overview from '../components/sam/Overview';
import {
  requestDevicePollingMac,
  requestUpdateDevicePollingMac,
} from '../actions/devicePollingMacActions';
import samValueCorrector from '../../../utils/samValueCorrector';
import StreamChannel from '../components/sam/StreamChannel';
import '../sam.scss';

const Sam = ({
  errorMessage,
  devicePollingMac,
  isReceiveFetching,
  isUpdateFetching,
  requestDevicePollingMacFunc,
  requestUpdateDevicePollingMacFunc,
  pollIdentifier,
  pollUrl,
}) => {
  const [topPosition, setTopPosition] = useState(0);
  const setTopPositionValue = () => setTopPosition(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', setTopPositionValue);
    requestDevicePollingMacFunc(pollUrl);

    return () => window.removeEventListener('scroll', setTopPositionValue);
  }, []);

  const refresh = () =>
    new Promise(() => {
      if (!isReceiveFetching && !errorMessage && pollIdentifier) {
        requestUpdateDevicePollingMacFunc(pollIdentifier);
      } else {
        requestDevicePollingMacFunc(pollUrl);
      }
    });

  if (isReceiveFetching) {
    return (
      <div className="downstream__upstream">
        <FetchingSpinner />
      </div>
    );
  }

  const devicePollingMacCorrected = devicePollingMac
    ? samValueCorrector(devicePollingMac)
    : { Success: false };

  return (
    <div className="downstream__upstream">
      {!devicePollingMacCorrected.Success || errorMessage !== '' ? (
        <ReactPullToRefresh
          onRefresh={refresh}
          className="info-box-content"
          refreshing={isUpdateFetching}
        >
          <InfoBox
            feedback={<Feedback />}
            firstInfoBoxLine={NOB.FEEDBACKS.SORRY}
            link={false}
            secondInfoBoxLine={NOB.HEALTH_CHECK.DEVICE_STATUS_ERROR}
          />
        </ReactPullToRefresh>
      ) : (
        <Tabs className="downstream__upstream__tabs">
          <div
            className={
              topPosition === 0
                ? 'downstream__upstream__tabs__navigation'
                : 'downstream__upstream__tabs__navigation--overlay'
            }
          >
            <TabLink to="overview" default>
              {NOB.DOWNSTREAM_UPSTREAM.OVERVIEW}
            </TabLink>

            <TabLink to="ds_channel">{NOB.DOWNSTREAM_UPSTREAM.DS_CHANNEL}</TabLink>

            <TabLink to="us_channel">{NOB.DOWNSTREAM_UPSTREAM.US_CHANNEL}</TabLink>
          </div>

          <ReactPullToRefresh onRefresh={refresh} className="content" refreshing={isUpdateFetching}>
            <div className={isUpdateFetching ? 'show-loading' : 'hide-loading'}>
              {isUpdateFetching && <ClipLoader />}
            </div>

            <TabContent className="tab-content" for="overview">
              <Overview devicePollingMac={devicePollingMacCorrected} />
            </TabContent>

            <TabContent className="tab-content" for="ds_channel">
              <StreamChannel
                channels={devicePollingMacCorrected.DsChannels}
                type={DOWNSTREAM}
                scoreVariables={devicePollingMacCorrected.ScoreVariables}
              />
            </TabContent>

            <TabContent className="tab-content" for="us_channel">
              <StreamChannel
                channels={devicePollingMacCorrected.UsChannels}
                type={UPSTREAM}
                scoreVariables={devicePollingMacCorrected.ScoreVariables}
              />
            </TabContent>
          </ReactPullToRefresh>
        </Tabs>
      )}
    </div>
  );
};

Sam.propTypes = {
  devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
  errorMessage: PropTypes.string.isRequired,
  isReceiveFetching: PropTypes.bool.isRequired,
  isUpdateFetching: PropTypes.bool,
  requestDevicePollingMacFunc: PropTypes.func.isRequired,
  requestUpdateDevicePollingMacFunc: PropTypes.func.isRequired,
  pollIdentifier: PropTypes.string.isRequired,
  pollUrl: PropTypes.string.isRequired,
};

Sam.defaultProps = {
  isUpdateFetching: false,
};

const mapStateToProps = state => ({
  devicePollingMac: state.devicePollingMac.devicePollingMac,
  errorMessage: state.devicePollingMac.errorMessage,
  isReceiveFetching: state.devicePollingMac.isFetching,
  isUpdateFetching: state.updateDevicePollingMac.isFetching,
});

const mapDispatchToProps = dispatch => ({
  requestDevicePollingMacFunc: pollUrl => dispatch(requestDevicePollingMac(pollUrl)),
  requestUpdateDevicePollingMacFunc: macAddress =>
    dispatch(requestUpdateDevicePollingMac({ macAddress })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sam));
