/* eslint-disable */
const baseurl = process.env.HOST + '/api';

// Adresses
export const ADDRESS_HISTORY = workOrderId => `${baseurl}/addresses/history?workOrderId=${workOrderId}`;

// Authentization
export const AUTHORIZATION = `${baseurl}/auth`;
export const DEV_AUTHORIZATION = `${baseurl}/auth/technician`;

// Customers
export const AGREEMENTS = customerId => `${baseurl}/customers/${customerId}/agreements`;
export const LINK_PRODUCT = (customerId, agreementDetailId) =>
  `${baseurl}/customers/${customerId}/products/${agreementDetailId}/link`;

// Devices
export const CHANGE_PRODUCT = (oldSerial, newSerial) => `${baseurl}/devices/${oldSerial}/swap/${newSerial}`;

// Employee
export const START_DAY = `${baseurl}/employee/login`;
export const END_DAY = `${baseurl}/employee/logout`;
export const SHIFT_STATUS = `${baseurl}/employee/login-status`;

// Health checks
export const GET_ICC_CHECK = customerId => `${baseurl}/health-check/icc-checks/${customerId}`;
export const HEALTH_CHECK = customerId => `${baseurl}/health-check/provisioning/${customerId}`;
export const GET_DEVICES_FOR_PROVISIONING = customerId => `${baseurl}/customers/${customerId}/devices`;
export const SEND_PROVISIONING_COMMAND = deviceId => `${baseurl}/devices/${deviceId}/command`;
export const DEVICE_POLLING_CUSTOMER = customerId => `${baseurl}/health-check/device-polling/all?customerId=${customerId}`;
export const DEVICE_POLLING_MAC = macAdddress => `${baseurl}/health-check/device-polling/${macAdddress}`;

export const DEVICE_POLL_URL = pollUrl => {
    return `${baseurl}${pollUrl}`
}
// Maps
export const MAP_ID = `${baseurl}/maps`;
export const MAP_TOKEN = referer => `${baseurl}/maps/auth?referer=${referer}`;

// Products
export const AVAILABLE_PRODUCTS = agreementId => `${baseurl}/products/available?agreementId=${agreementId}`;
export const POST_PRODUCT = agreementId => `${baseurl}/products?agreementId=${agreementId}`;
export const PRODUCTS_INSTALLER = customerId => `${baseurl}/products/filtered-current?customerId=${customerId}`;
export const PRODUCTS_TECHNICIAN = customerId => `${baseurl}/products/current?customerId=${customerId}`;
export const REMOVE_PRODUCT = agreementDetailId => `${baseurl}/customer/product?agreementDetailId=${agreementDetailId}`;
export const ACTIVATE_AGREEMENT_DETAIL = agreementDetailId => `${baseurl}/products/activate?agreementDetailId=${agreementDetailId}`;
export const ACTIVATE_PRODUCT = (agreementDetailId) => `${baseurl}/products/${agreementDetailId}/devices/activate`;

// Price
export const AGREEMENT_PRICE = customerId => `${baseurl}/price/agreements?customerId=${customerId}`;

// Workorders
export const WORKORDER = ({ workOrderId }) => `${baseurl}/work-orders/${workOrderId}`;
export const WORKORDERS_EXTERNAL_INSTALLER = `${baseurl}/work-orders`;
export const WORKORDERS_TECHNICIAN = `${baseurl}/work-orders/technician`;
export const PRODUCT_LIST = `${baseurl}/work-orders/products`;
export const ACTION_TAKEN = workOrderId => `${baseurl}/work-orders/${workOrderId}/action-taken`;
export const STATUS = workOrderId => `${baseurl}/work-orders/${workOrderId}/status`;
export const FIND_SERVICES = ({ workOrderId }) => `${baseurl}/work-orders/${workOrderId}/services`;
export const FIND_SERVICE_TYPES = validAddressId => `${baseurl}/work-orders/service-types?validAddressId=${validAddressId}`;
export const REMOVE_SERVICE = (workOrderId, workOrderServiceId) => `${baseurl}/work-orders/${workOrderId}/services/${workOrderServiceId}`;
export const UPDATE_SERVICE = (workOrderId) => `${baseurl}/work-orders/${workOrderId}/services`;
export const WORKORDER_SERVICES = ({ workOrderId }) => `${baseurl}/work-orders/${workOrderId}/services/active`;
export const WORKORDER_PREVIOUS = `${baseurl}/work-orders/previous`;
export const TRAVEL_AND_WORK_DURATION = workOrderId => `${baseurl}/work-orders/${workOrderId}/travel-time`;

//Work Orders History
export const WORK_ORDER_HISTORY = (workOrderId) => `${baseurl}/work-orders/${workOrderId}/history`;

// Zendesk
export const POST_ZENDESK = `${baseurl}/zendesk`;
export const FOLLOW_UP_FORMS = `${baseurl}/zendesk/forms`;


// Customer Info
export const WORKORDER_BY_CUSTOMER_ID = customerId => `${baseurl}/customer/${customerId}`;
export const CUSTOMER_ADDRESS_HISTORY = validAddressId => `${baseurl}/address/${validAddressId}/history`;

export const CUSTOMER_SEARCH_API_URL = `${baseurl}/customer/search`;
export const CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL = customerId => `${baseurl}/customer/${customerId}/agreements `;
