import { COMPLETE_STATUS_CHANGE_SUCCESS, UPDATE_ACTION_TAKEN_FAILURE } from '../workLogConstants';

export const receiveCompleteStatusChange = () => ({
  type: COMPLETE_STATUS_CHANGE_SUCCESS,
});

export const updateActionTakenFailure = ({ errorMessage }) => ({
  type: UPDATE_ACTION_TAKEN_FAILURE,
  errorMessage,
});
