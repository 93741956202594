import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/workOrderHistoryActions';
import { WORK_ORDER_HISTORY } from '../../../constants/endpoints';
import { getAPI } from '../../../api/apiConfig';
import { WORK_ORDER_HISTORY_REQUEST } from '../workOrderHistoryConstants';

export function* callWorkOrderHistory({ workOrderId }) {
  try {
    const URL = WORK_ORDER_HISTORY(workOrderId);
    const result = yield call(getAPI, URL);

    yield put(actions.receiveWorkOrderHistory({ workOrderHistory: result }));
  } catch (error) {
    yield put(actions.errorWorkOrderHistory(error.message));
  }
}

export default [takeLatest(WORK_ORDER_HISTORY_REQUEST, callWorkOrderHistory)];
