import {
  FIND_SERVICES_REQUEST,
  FIND_SERVICES_SUCCESS,
  FIND_SERVICES_ERROR,
} from '../workLogConstants';

const findServicesReducerDefaultState = {
  isFetchingServices: false,
  errorMessage: '',
  services: [],
};

export default (state = findServicesReducerDefaultState, { errorMessage, services, type }) => {
  switch (type) {
    case FIND_SERVICES_REQUEST:
      return {
        ...state,
        isFetchingServices: true,
      };

    case FIND_SERVICES_SUCCESS:
      return {
        ...state,
        isFetchingServices: false,
        services,
      };

    case FIND_SERVICES_ERROR:
      return {
        ...state,
        isFetchingServices: false,
        errorMessage,
      };

    default:
      return state;
  }
};
