import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

const Checkbox = ({ checked, isFetching, onClick, label, name }) => (
  <label htmlFor={name} className="checkbox">
    <span className={checked ? 'label--checked' : 'label'}>{label}</span>

    <input
      id={name}
      checked={checked}
      value={checked}
      type="checkbox"
      name={name}
      onClick={onClick}
      readOnly
    />

    <span className="checkmark">
      {isFetching && (
        <span className={`${checked ? 'circle-loader-green' : 'circle-loader-white'}`} />
      )}
    </span>
  </label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  checked: false,
  isFetching: false,
};

export default Checkbox;
