import {
  ADDRESS_HISTORY_REQUEST,
  ADDRESS_HISTORY_SUCCESS,
  ADDRESS_HISTORY_FAILURE,
  ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST,
} from '../addressHistoryConstants';

export const requestAddressHistory = action => ({
  type: ADDRESS_HISTORY_REQUEST,
  workOrderId: action.workOrderId,
});
export const requestAddressHistoryByAddressId = validAddressId => ({
  type: ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST,
  validAddressId: validAddressId,
});

export const receiveAddressHistory = action => ({
  type: ADDRESS_HISTORY_SUCCESS,
  addressHistory: action.addressHistory,
});
export const errorAddressHistory = action => ({
  type: ADDRESS_HISTORY_FAILURE,
  errorMessage: action.errorMessage,
});
