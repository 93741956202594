import { call, put, takeEvery } from 'redux-saga/effects';
import { POST_ZENDESK } from '../../../../constants/endpoints';
import { postAPI } from '../../../../api/apiConfig';
import { ZENDESK_POST_REQUEST, ZENDESK_FOLLOW_UP_POST_REQUEST } from '../../workLogConstants';
import * as zendeskActions from '../../actions/zendeskActions';

export function* callZendesk({ body }) {
  try {
    yield call(postAPI, POST_ZENDESK, body);
    yield put(zendeskActions.postZendeskSuccess({ zendesk: 'Posted to zendesk' }));
  } catch (error) {
    yield put(
      zendeskActions.postZendeskError({
        errorMessage: `Could not post to zendesk. ${error.message}`,
      }),
    );
  }
}

export function* callZendeskFollowUp({ body }) {
  try {
    yield call(postAPI, POST_ZENDESK, body);
    yield put(zendeskActions.postZendeskFollowUpSuccess(body.workOrderId));
  } catch (error) {
    yield put(
      zendeskActions.postZendeskFollowUpError({
        errorMessage: `Could not post to zendesk. ${error.message}`,
      }),
    );
  }
}
export default [
  takeEvery(ZENDESK_POST_REQUEST, callZendesk),
  takeEvery(ZENDESK_FOLLOW_UP_POST_REQUEST, callZendeskFollowUp),
];
