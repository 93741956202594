import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkOrderList from './workOrderList/WorkOrderList';
import Feedback from '../../../feedbacks';
import {
  feedbackBlue,
  feedbackGreen,
  feedbackPurple,
  yellowOnBlack,
} from '../../../styles/variables.scss';
import { FEEDBACK_POPCORN, FEEDBACK_JUGGLE } from '../../../constants/feedbackNames';
import NOB from '../../../constants/language-strings';
import InfoBox from '../../../components/InfoBox/InfoBox';

export default function WorkOrderListContent({
  isWorkOrderListEmpty,
  areAllWorkOrdersDone,
  isTechnician,
  isExternalInstaller,
  workOrdersCancelled,
  workOrdersDone,
  workOrdersNotDone,
}) {
  const [clickCount, setClickCount] = useState({ count: 0, reverse: false });

  const countClick = () => {
    const { count, reverse } = clickCount;

    if (count === 3 && !reverse) {
      setClickCount({ count: count + 1, reverse: true });
    } else if (count === 1 && reverse) {
      setClickCount({ count: count - 1, reverse: false });
    } else if (count < 5 && reverse) {
      setClickCount({ count: count - 1, reverse });
    } else {
      setClickCount({ count: count + 1, reverse });
    }
  };

  const juggleButton = (
    <button onClick={countClick}>
      <Feedback
        name={FEEDBACK_JUGGLE}
        reverse={clickCount.reverse}
        ball1Fill={clickCount.reverse ? feedbackBlue : feedbackGreen}
        ball3Fill={clickCount.reverse ? feedbackPurple : yellowOnBlack}
      />
    </button>
  );

  return (
    <div className="workOrder__list__content">
      {/* Show undone work orders if not empty */}
      {workOrdersNotDone.length > 0 && <WorkOrderList workOrders={workOrdersNotDone} />}

      {/* Show feedback with popcorn if external installer has completed all work orders */}
      {areAllWorkOrdersDone && isExternalInstaller && (
        <InfoBox
          feedback={<Feedback name={FEEDBACK_POPCORN} />}
          link={false}
          secondInfoBoxLine={NOB.NO_WORK_ORDERS.DONE}
        />
      )}

      {/* Show feedback with juggler if technician has no work orders */}
      {isWorkOrderListEmpty && isTechnician && (
        <InfoBox
          feedback={juggleButton}
          link={false}
          secondInfoBoxLine={NOB.NO_WORK_ORDERS.NO_WORK_ORDERS}
          thirdInfoBoxLine={NOB.NO_WORK_ORDERS.WAIT_FOR_NEXT}
        />
      )}

      {/* Show done work orders with heading if not empty */}
      {workOrdersDone.length > 0 && (
        <div className="workOrder__list--complete">
          <span className="workOrder__list--complete--heading">
            {NOB.WORKORDER_LIST.COMPLETED_HEADER}
          </span>
          <WorkOrderList workOrders={workOrdersDone} />
        </div>
      )}

      {/* Show cancelled work orders with heading if not empty */}
      {workOrdersCancelled.length > 0 && (
        <div className="workOrder__list--complete">
          <span className="workOrder__list--complete--heading">
            {NOB.WORKORDER_LIST.CANCELLED_JOBS}
          </span>
          <WorkOrderList workOrders={workOrdersCancelled} />
        </div>
      )}

      {/* Show feedback for no work orders assigned to external installer */}
      {isWorkOrderListEmpty && isExternalInstaller && (
        <InfoBox
          feedback={<Feedback />}
          firstInfoBoxLine={NOB.FEEDBACKS.HMM}
          link={false}
          secondInfoBoxLine={NOB.NO_WORK_ORDERS.NO_WORK_ORDERS}
        />
      )}
    </div>
  );
}

WorkOrderListContent.propTypes = {
  isWorkOrderListEmpty: PropTypes.bool,
  areAllWorkOrdersDone: PropTypes.bool,
  isTechnician: PropTypes.bool,
  isExternalInstaller: PropTypes.bool,
  workOrdersCancelled: PropTypes.arrayOf(PropTypes.any),
  workOrdersDone: PropTypes.arrayOf(PropTypes.any),
  workOrdersNotDone: PropTypes.arrayOf(PropTypes.any).isRequired,
};

WorkOrderListContent.defaultProps = {
  isWorkOrderListEmpty: false,
  isTechnician: false,
  isExternalInstaller: false,
  areAllWorkOrdersDone: false,
  workOrdersCancelled: [],
  workOrdersDone: [],
};
