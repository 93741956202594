import PropTypes from 'prop-types';
import React from 'react';

import NOB from '../../../../constants/language-strings';
import { PASSED } from '../../constants';

const UpdateHealthCheck = ({ healthCheck, updateHealthCheck, devicePollingCheckPassed }) => {
  const passed =
    healthCheck.provisioning.AllChecksPassed &&
    healthCheck.iccCheck.deviceLinks === PASSED &&
    healthCheck.iccCheck.devicePairing === PASSED &&
    devicePollingCheckPassed;

  return (
    <div className="health__check__list__container--button">
      <button
        className={passed ? 'button--passed--true' : 'button--passed--false'}
        onClick={updateHealthCheck}
      >
        {passed ? NOB.HEALTH_CHECK.BUTTON.UPDATE : NOB.HEALTH_CHECK.BUTTON.FIX_ERRORS}
      </button>
    </div>
  );
};

UpdateHealthCheck.propTypes = {
  devicePollingCheckPassed: PropTypes.bool.isRequired,
  healthCheck: PropTypes.objectOf(PropTypes.any).isRequired,
  updateHealthCheck: PropTypes.func.isRequired,
};

export default UpdateHealthCheck;
