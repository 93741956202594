import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../constants/language-strings';

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    const { username, password } = this.state;

    this.props.requestSignIn({ credentials: { username, password } });
    event.preventDefault();
  }

  render() {
    const { signInError } = this.props;

    return (
      <div className="login__container">
        <form onSubmit={this.handleSubmit}>
          <h1>{NOB.SIGN_IN.HEADER}</h1>
          <div className="login__container__input">
            <label htmlFor="username" className="login__container__input--labels">
              {NOB.SIGN_IN.INPUT_USERNAME}
            </label>
            <input
              name="username"
              type="text"
              autoComplete="on"
              value={this.state.username}
              onChange={this.handleChange}
              className={signInError ? 'login__container__input--error' : ''}
            />
          </div>

          <div className="login__container__input">
            <label htmlFor="password" className="login__container__input--labels">
              {NOB.SIGN_IN.INPUT_PASSWORD}
            </label>
            <input
              name="password"
              type="password"
              autoComplete="on"
              value={this.state.password}
              onChange={this.handleChange}
              className={signInError ? 'login__container__input--error' : ''}
            />

            {signInError}
          </div>

          {signInError && (
            <div className="login__container__error-message">{NOB.SIGN_IN.ERROR_TEXT}</div>
          )}

          <button className="button button--primary" type="submit">
            {NOB.SIGN_IN.SIGN_IN_BUTTON}
          </button>
        </form>
      </div>
    );
  }
}

SignInForm.propTypes = {
  signInError: PropTypes.bool.isRequired,
  requestSignIn: PropTypes.func.isRequired,
};

export default SignInForm;
