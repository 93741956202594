import PropTypes from 'prop-types';
import React from 'react';
import capitalize from '../../../../utils/capitalizeString';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import { HOUSE, TELEPHONE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons';
import './customerInfo.scss';
import Variables from '../../../../styles/variables.scss';

const CustomerInfo = ({
  address: { city, flatOrApartment, houseNumberAlpha, houseNumberNumeric, postalCode, street },
  customer: { firstName, phoneNumber, email, surname, birthDate },
  togglePopup,
  isPastDue,
}) => {
  const color = isPastDue ? Variables.redRemove : undefined;

  const birthDateExtracted = birthDate.split('T')[0].split('-');
  const formatedBirthDate = `${birthDateExtracted[2]}-${birthDateExtracted[1]}-${birthDateExtracted[0]}`; // dd-mm-yyyy

  return (
    <div className="customer__info__container__items">
      <button
        className={isPastDue ? 'address--red' : 'address'}
        onClick={() => togglePopup(false, GOOGLE_MAPS)}
      >
        <Icon name={HOUSE} houseColor={color} />

        <div className="customer__info__container__items__item--address">
          <span>{`${capitalize(street)} ${houseNumberNumeric}${houseNumberAlpha}, `}</span>
          <span>{`${postalCode} ${capitalize(city)} `}</span>
          {flatOrApartment && <span>{flatOrApartment}</span>}
        </div>
      </button>

      <div className="customer__info__container__items__item">
        <div className="customer__info__container__items__item--name">
          <span>
            <Icon name={USER} color={color} />
            {`${capitalize(firstName)} ${capitalize(surname)}`}
          </span>

          <div className="phoneAndDobContainer">
            <div>{formatedBirthDate}</div>
            <div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className={isPastDue ? 'telephone--red' : 'telephone'}>
              <span className="customer__telephone__icon">
                <Icon name={TELEPHONE} color={color} />
              </span>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  togglePopup: PropTypes.func.isRequired,
  isPastDue: PropTypes.bool.isRequired,
};

export default CustomerInfo;
