import React from 'react';

export default function markSearch(option, input) {
  if (input && input.search) {
    const regex = new RegExp(input.search.trim(), 'i');
    const segments = option.name.split(regex);
    const match = option.name.match(regex);

    // Make the searched word(s) bold
    if (match) {
      return (
        <div>
          {segments[0]}
          <span className="searched">{match}</span>
          {segments[1]}
        </div>
      );
    }
  }

  return option.name;
}
