import {
  WORKORDER_LIST_REQUEST,
  WORKORDER_LIST_FAILURE,
  WORKORDER_LIST_SUCCESS,
  EMPTY_WORKORDER_LIST,
  WORKORDER_STATUS_UPDATE,
  END_DAY,
} from '../workOrderListConstants';
import Status from '../../../constants/status-codes';
import dateFromToday from '../../../utils/dateFromToday';

const today = dateFromToday();

const workOrderListReducerDefaultState = {
  endDay: false,
  errorMessage: '',
  isFetching: false,
  workOrders: [],
  startedWorkOrder: [],
};

export default (
  state = workOrderListReducerDefaultState,
  { endDay, errorMessage, newStatus, type, workOrderId, workOrders },
) => {
  switch (type) {
    case WORKORDER_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case WORKORDER_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
        workOrders,
        selectedDay: today.weekday,
        selectedDate: today.date,
        startedWorkOrder: workOrders
          .filter(
            workOrder =>
              Status.TRAVELING.includes(workOrder.status) ||
              Status.ON_SITE.includes(workOrder.status),
          )
          .map(workOrder => workOrder.workOrderId),
      };

    case WORKORDER_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };

    case WORKORDER_STATUS_UPDATE:
      // Update to new status for selected workorder.
      return {
        ...state,
        workOrders: state.workOrders.map(item => {
          if (item.workOrderId === workOrderId) {
            return {
              ...item,
              status: newStatus,
            };
          }

          return item;
        }),
      };

    case EMPTY_WORKORDER_LIST:
      return {
        ...state,
        isFetching: false,
        workOrders: [],
      };

    case END_DAY:
      return {
        ...state,
        endDay,
      };

    default:
      return state;
  }
};
