import React from 'react';
import PropTypes from 'prop-types';
import './numberInput.scss';

function NumberInput({ onChange, label, name, errorMessage, value, placeholder, icon }) {
  return (
    <div className={errorMessage ? 'number-input--error' : 'number-input'}>
      <label htmlFor={name}>
        <span className="label">{label}</span>
        <input
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
        />
        {icon && <span className="icon">{icon}</span>}
      </label>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

NumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
};

NumberInput.defaultProps = {
  name: null,
  errorMessage: '',
  value: '',
  placeholder: '',
  icon: null,
};

export default NumberInput;
