import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ARROW_DOWN } from '../../../constants/iconNames';
import { INCOMPLETE_WITHOUT_CODE } from '../../../constants/status-codes';
import { DOT_DAY_MONTH_YEAR } from '../../../constants/dateFormats';
import Icon from '../../../icons';
import Variables from '../../../styles/variables.scss';
import '../containers/addressHistory.scss';

export default function DropdownButton({
  closeDropdown,
  open,
  singleAddressHistory: { registeredDate, status, workOrderId, serviceTypeName },
  toggleDropdown,
}) {
  return (
    <button
      onClick={() => toggleDropdown(open === workOrderId ? 1 : workOrderId)}
      className={
        status.includes(INCOMPLETE_WITHOUT_CODE)
          ? 'address_history__dropdown--red'
          : 'address_history__dropdown--blue'
      }
    >
      <div>
        <span>{serviceTypeName} </span>

        <div className="address_history__dropdown--right">
          <span>{moment(registeredDate.slice(0, 10)).format(DOT_DAY_MONTH_YEAR)}</span>

          <span className={closeDropdown ? 'rotate-down' : 'rotate-up'}>
            <Icon stroke={Variables.blackPrimary} name={ARROW_DOWN} />
          </span>
        </div>
      </div>
    </button>
  );
}

DropdownButton.propTypes = {
  singleAddressHistory: PropTypes.objectOf(PropTypes.any).isRequired,
  closeDropdown: PropTypes.bool.isRequired,
  open: PropTypes.number.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};
