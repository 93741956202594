import { COMPLETE_WORK_ORDER_WITH_ZENDESK } from '../workLogConstants';

const completeWorkOrderWithZendesk = (body, status, workOrderId, azimuth) => ({
  type: COMPLETE_WORK_ORDER_WITH_ZENDESK,
  body,
  status,
  workOrderId,
  azimuth,
});

export default completeWorkOrderWithZendesk;
