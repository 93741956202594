import PropTypes from 'prop-types';
import React from 'react';
import { SlideToggle } from 'react-slide-toggle';

import * as Animations from '../../../../constants/animation';
import { ARROW_DOWN } from '../../../../constants/iconNames';
import Icon from '../../../../icons';
import NOB from '../../../../constants/language-strings';
import Variables from '../../../../styles/variables.scss';
import './technicalDetails.scss';

const TechnicalDetails = ({ technicalDetails, capacity }) => {
  const technicalDetailsList = Object.keys(technicalDetails);

  const Details = () => {
    const Azimuth5G = () => {
      return (
        <div className="technical__details__container--items" key="5G">
          <div className="technical__details__container--item">
            <div className="technical__details__container--item--key">
              Anbefalt retning i grader (5G)
            </div>
            <div>{capacity[1]?.direction ?? '-'}</div>
          </div>
          <div className="technical__details__container--item">
            <div className="technical__details__container--item--key">Kapasitet / dekning</div>
            <div>
              {!capacity[1]
                ? 'Ingen dekning'
                : capacity[1]?.capacityLeft === 0
                ? 'Ingen kapasitet'
                : capacity[1]?.capacityLeft}
            </div>
          </div>
        </div>
      );
    };

    const Azimuth4G = () => {
      return (
        <div className="technical__details__container--items" key="4G">
          <div className="technical__details__container--item">
            <div className="technical__details__container--item--key">
              Alternativ retning i grader (4G)
            </div>
            <div>{capacity[0]?.direction ?? '-'}</div>
          </div>
          <div className="technical__details__container--item">
            <div className="technical__details__container--item--key">Kapasitet / dekning</div>
            <div>
              {!capacity[0]
                ? 'Ingen dekning'
                : capacity[0]?.capacityLeft === 0
                ? 'Ingen kapasitet'
                : capacity[0]?.capacityLeft}
            </div>
          </div>
        </div>
      );
    };

    const AllTechnicalDetails = () => {
      return technicalDetailsList
        .filter(it => it.key != 'Azimuth_proposed')
        .map((key, index) => {
          return (
            <div className="technical__details__container--items" key={`${key}${index}`}>
              {technicalDetails[key] && (
                <div className="technical__details__container--item">
                  <div className="technical__details__container--item--key">{key}</div>
                  <div>{technicalDetails[key]}</div>
                </div>
              )}
            </div>
          );
        });
    };

    return (
      <>
        <AllTechnicalDetails />
        {capacity && (
          <>
            <Azimuth5G />
            <Azimuth4G />
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {technicalDetailsList.length > 0 && (
        <SlideToggle
          duration={Animations.DURATION}
          collapsed
          easeExpand={Animations.EXPAND}
          easeCollapse={Animations.COLLAPSE}
          render={({ onToggle, setCollapsibleElement, toggleState }) => (
            <div className="technical__details">
              <button onClick={onToggle} className="technical__details__dropdown">
                <div>
                  <span className="technical__details__dropdown--text">
                    {NOB.WORKORDER_TABS.TECHNICAL_INFORMATION_HEADING}
                  </span>

                  <span
                    className={
                      toggleState === Animations.EXPANDING || toggleState === Animations.EXPANDED
                        ? 'rotate-up'
                        : 'rotate-down'
                    }
                  >
                    <Icon name={ARROW_DOWN} stroke={Variables.iconBlue} />
                  </span>
                </div>
              </button>

              <div className="technical__details__container" ref={setCollapsibleElement}>
                <Details />
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};

TechnicalDetails.propTypes = {
  technicalDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  capacity: PropTypes.any,
};

export default TechnicalDetails;
