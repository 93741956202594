import { NDS_MIKRO_SMARTCARD } from '../constants/elements';

function deviceSerialNumbersToString(devices) {
  let subHeading = null;
  let deviceIndex;

  if (devices.length > 0) {
    deviceIndex = devices.findIndex(device => !device.name.includes(NDS_MIKRO_SMARTCARD));
    subHeading =
      devices.length < 3
        ? devices[deviceIndex].serialNumber
        : `${devices[0].serialNumber}, 
        ${devices[1].serialNumber}, 
        ${devices[2].serialNumber}`;
  }

  return subHeading;
}

export default deviceSerialNumbersToString;
