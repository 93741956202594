import {
  DEVICE_POLLING_MAC_REQUEST,
  DEVICE_POLLING_MAC_RECEIVE,
  DEVICE_POLLING_MAC_ERROR,
  UPDATE_DEVICE_POLLING_MAC_REQUEST,
  UPDATE_DEVICE_POLLING_MAC_RECEIVE,
  UPDATE_DEVICE_POLLING_MAC_ERROR,
} from '../constants';

export const requestDevicePollingMac = pollUrl => ({
  type: DEVICE_POLLING_MAC_REQUEST,
  pollUrl,
});

export const receiveDevicePollingMac = ({ devicePollingMac }) => ({
  type: DEVICE_POLLING_MAC_RECEIVE,
  devicePollingMac,
});

export const errorDevicePollingMac = ({ errorMessage }) => ({
  type: DEVICE_POLLING_MAC_ERROR,
  errorMessage,
});

export const requestUpdateDevicePollingMac = ({ macAddress }) => ({
  type: UPDATE_DEVICE_POLLING_MAC_REQUEST,
  macAddress,
});

export const receiveUpdateDevicePollingMac = ({ devicePollingMac }) => ({
  type: UPDATE_DEVICE_POLLING_MAC_RECEIVE,
  devicePollingMac,
});

export const errorUpdateDevicePollingMac = ({ errorMessage }) => ({
  type: UPDATE_DEVICE_POLLING_MAC_ERROR,
  errorMessage,
});
