import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './slideInModal.scss';
import IconButton from '../Buttons/IconButton/IconButton';
import { CROSS_LARGE } from '../../constants/iconNames';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../utils/FetchingSpinner';

function SlideInModal({
  children,
  onClose,
  cleanUp,
  display,
  mainHeading,
  subHeading,
  leftIcon,
  isFetching,
  fetchingSpinnerColor,
  onBackClick,
}) {
  useEffect(() => {
    if (display) document.body.classList.add('popup-open');

    return function cleanup() {
      document.body.classList.remove('popup-open');
      cleanUp();
    };
  }, [display]);

  const closeIcon = <IconButton onClick={onClose} icon={CROSS_LARGE} className="fullscreen_icon" />;

  return ReactDOM.createPortal(
    <div className={display ? 'overlay' : null}>
      <CSSTransition
        className="appear fullscreen_popup_container"
        timeout={450}
        in={display}
        unmountOnExit
      >
        <div className="fullscreen_popup_container">
          <ComponentHeader
            rightIcon={closeIcon}
            mainHeading={mainHeading}
            subHeading={subHeading}
            leftIcon={leftIcon}
            onClick={onBackClick}
          />
          <div className="fullscreen_popup_content">
            {isFetching && <FetchingSpinner color={fetchingSpinnerColor} />}
            {display && !isFetching && children}
          </div>
        </div>
      </CSSTransition>
    </div>,
    document.getElementById('modal-root'),
  );
}

SlideInModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  cleanUp: PropTypes.func,
  display: PropTypes.bool.isRequired,
  mainHeading: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
  subHeading: PropTypes.string,
  leftIcon: PropTypes.objectOf(PropTypes.any),
  isFetching: PropTypes.bool,
  fetchingSpinnerColor: PropTypes.string,
};

SlideInModal.defaultProps = {
  mainHeading: '',
  subHeading: '',
  leftIcon: null,
  isFetching: false,
  cleanUp: () => {},
};

export default SlideInModal;
