import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";

const SVG = ({ stroke, fill, grayFill }) => (
  <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
    <defs>
      <filter x="-36.1%" y="-29.5%" width="172.2%" height="159.1%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/calender">
        <g id="Group-3" filter="url(#filter-1)" transform="translate(1.000000, 1.000000)">
          <g id="Group-2" transform="translate(0.000000, 3.176471)" stroke={fill}>
            <rect id="Rectangle-7" fill={grayFill} x="0.5" y="0.5" width="17" height="17" rx="2" />
            <path d="M0.5,4.79411765 L17.5,4.79411765 L17.5,2 C17.5,1.17157288 16.8284271,0.5 16,0.5 L2,0.5 C1.17157288,0.5 0.5,1.17157288 0.5,2 L0.5,4.79411765 Z" id="Rectangle-7-Copy" fill={fill} />
          </g>
          <rect id="Rectangle-7-Copy-2" stroke={stroke} strokeWidth="0.5" fill={fill} x="2.36764706" y="0.25" width="1.61764706" height="4.79411765" rx="0.5" />
          <rect id="Rectangle-7-Copy-3" stroke={stroke} strokeWidth="0.5" fill={fill} x="14.0147059" y="0.25" width="1.61764706" height="4.79411765" rx="0.5" />
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  grayFill: PropTypes.string,
  stroke: PropTypes.string,
};

SVG.defaultProps = {
  fill: Variables.blackPrimary,
  grayFill: Variables.grayHeading,
  stroke: Variables.whiteSecondary,
};

export default SVG;
