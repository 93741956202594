export default deviceId => {
  if (deviceId) {
    return {
      frequency: 10,
      numOfWorkers: navigator.hardwareConcurrency,
      locate: false,
      decoder: {
        readers: ['code_128_reader', 'code_39_reader'],
      },
      locator: {
        patchSize: 'small',
      },
      singleChannel: true,
      inputStream: {
        constraints: {
          deviceId,
        },
        target: document.getElementById('scanner'),
        area: {
          top: '45%',
          right: '0%',
          left: '0%',
          bottom: '45%',
        },
      },
    };
  }

  return {
    frequency: 10,
    numOfWorkers: navigator.hardwareConcurrency,
    locate: false,
    decoder: {
      readers: ['code_128_reader', 'code_39_reader'],
    },
    locator: {
      patchSize: 'small',
    },
    singleChannel: true,
    inputStream: {
      target: document.getElementById('scanner'),
      constraints: {
        width: 1280,
        height: 720,
      },
      area: {
        top: '45%',
        right: '0%',
        left: '0%',
        bottom: '45%',
      },
    },
  };
};
