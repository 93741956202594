import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import capitalize from '../../../../utils/capitalizeString';
import { HOUSE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons/index';
import NOB from '../../../../constants/language-strings';
import setStatusTimeValue from '../../../../utils/setStatusTimeValue';
import setStatusClassName from '../../../../utils/setStatusClassName';
import Status, { CANCELLED, INCOMPLETE_WITHOUT_CODE } from '../../../../constants/status-codes';
import Variables from '../../../../styles/variables.scss';
import isPast from '../../../../utils/isPastDue';

export default function WorkOrderList({ workOrders }) {
  const workOrderStatus = workOrder =>
    /\d/.test(workOrder.status)
      ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
      : workOrder.status;

  const startedStatuses = [...Status.TRAVELING, ...Status.ON_SITE];

  const houseColor = (status, isPastDue) => {
    if (status.includes(INCOMPLETE_WITHOUT_CODE) || isPastDue) return Variables.buttonRed;
    else if (status === CANCELLED) return Variables.blackPrimary;

    return Variables.iconBlue;
  };

  const workOrderList = workOrders.map(workOrder => {
    const {
      address: { city, flatOrApartment, houseNumberAlpha, houseNumberNumeric, postalCode, street },
      serviceTypeName,
      workOrderId,
      serviceDate,
      customer: { firstName, surname },
    } = workOrder;
    const isPastDue = isPast(serviceDate);

    return (
      <Link
        key={workOrderId}
        to={`/workOrder/${workOrderId}`}
        className={`${setStatusClassName(startedStatuses, workOrderStatus(workOrder), workOrder)} ${
          isPastDue ? 'workorder-warning' : ''
        }`}
      >
        <div className="workOrder__container--top--left">
          <div>{serviceTypeName}</div>
          <div className="new-text">
            <Icon name={USER} color={houseColor(workOrderStatus(workOrder), isPastDue)} />
            {firstName} {surname}
          </div>
        </div>

        <div className="workOrder__container--top--right">
          <span>{`${NOB.WORKORDER_LIST.WORKORDER}: `}</span>
          <span>{workOrderId}</span>
        </div>
        <div className="workOrder__container--bottom--left">
          <Icon name={HOUSE} houseColor={houseColor(workOrderStatus(workOrder), isPastDue)} />

          <div className="workOrder__container--bottom--left--address">
            <div>
              {`
                ${capitalize(street)}
                ${houseNumberNumeric}
                ${houseNumberAlpha && ` ${houseNumberAlpha}, `}
              `}
            </div>

            <div className="workOrder__container--bottom--left--address--postalCode">
              <span>{`${postalCode} ${capitalize(city)}`}</span>
              <span>{flatOrApartment}</span>
            </div>
          </div>
        </div>
        <div className="workOrder__container__bottom--right">
          {setStatusTimeValue(workOrder, workOrderStatus(workOrder), isPastDue)}
        </div>
      </Link>
    );
  });

  return <div className="workOrder__list__container">{workOrderList}</div>;
}

WorkOrderList.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
};
