export const HEALTH_CHECK_REQUEST = 'HEALTH_CHECK_REQUEST';
export const HEALTH_CHECK_RECEIVE = 'HEALTH_CHECK_RECEIVE';
export const HEALTH_CHECK_ERROR = 'HEALTH_CHECK_ERROR';
export const UPDATE_HEALTH_CHECK_REQUEST = 'UPDATE_HEALTH_CHECK_REQUEST';
export const ICC_CHECK_REQUEST = 'ICC_CHECK_REQUEST';
export const ICC_CHECK_RECEIVE = 'ICC_CHECK_RECEIVE';
export const ICC_CHECK_ERROR = 'ICC_CHECK_ERROR';

export const DEVICE_POLLING_CUSTOMER_REQUEST = 'DEVICE_POLLING_CUSTOMER_REQUEST';
export const DEVICE_POLLING_CUSTOMER_RECEIVE = 'DEVICE_POLLING_CUSTOMER_RECEIVE';
export const DEVICE_POLLING_CUSTOMER_ERROR = 'DEVICE_POLLING_CUSTOMER_ERROR';

export const SAM = '#healthCheck#sam';

export const DEVICE_POLLING_MAC_REQUEST = 'DEVICE_POLLING_MAC_REQUEST';
export const DEVICE_POLLING_MAC_RECEIVE = 'DEVICE_POLLING_MAC_RECEIVE';
export const DEVICE_POLLING_MAC_ERROR = 'DEVICE_POLLING_MAC_ERROR';

export const PASSED = 'Passed';

export const UPDATE_DEVICE_POLLING_MAC_REQUEST = 'UPDATE_DEVICE_POLLING_MAC_REQUEST';
export const UPDATE_DEVICE_POLLING_MAC_RECEIVE = 'UPDATE_DEVICE_POLLING_MAC_RECEIVE';
export const UPDATE_DEVICE_POLLING_MAC_ERROR = 'UPDATE_DEVICE_POLLING_MAC_ERROR';
