import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ARROW_LEFT } from '../../constants/iconNames';
import { ADDRESS_SEARCH, FRONT_PAGE_PATH, SIGN_OUT_PATH } from '../../constants/paths';
import TeliaLogo from '../../images/Telia_Primary_Logo_RGB.png';
import Icon from '../../icons';
import NOB from '../../constants/language-strings';
import Variables from '../../styles/variables.scss';
import './Header.scss';
import styled from 'styled-components';
import { useSearchStore } from '../../features/address-search/useSearchStore';

const AddressSearchLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #ffdc00;
  margin-left: 16px;
`;

const Header = ({ errorMessage, isAuthenticated, router, workOrder, location, history }) => {
  const previousPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const isWorkOrderPath = router.location.pathname.match(/workOrder\/([0-9]{7})/);
  const isAuthenticatedAndInWorkOrder = isAuthenticated && isWorkOrderPath;

  const workOrderHeader = (
    <div className="header">
      {errorMessage === '' && (
        <div className="header__service-type">{workOrder.serviceTypeName}</div>
      )}

      <Link className="header__button-back" to={previousPath === '/workOrder' ? '/' : previousPath}>
        <Icon stroke={Variables.yellowPrimary} name={ARROW_LEFT} />
      </Link>
    </div>
  );

  function onGoBackClicked(e) {
    e.preventDefault();
    history.goBack();
  }

  const customerInfoHeader = (
    <div className="header">
      {errorMessage === '' && <div className="header__service-type">Kunde</div>}

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="header__button-back" onClick={e => onGoBackClicked(e)} href="#">
        <Icon stroke={Variables.yellowPrimary} name={ARROW_LEFT} />
      </a>
    </div>
  );

  const { clearState } = useSearchStore();

  const defaultHeader = (
    <div className="header">
      {isAuthenticated && (
        <AddressSearchLink to={ADDRESS_SEARCH} onClick={() => clearState()}>
          Kundesøk
        </AddressSearchLink>
      )}

      <Link className="header__logo" to={FRONT_PAGE_PATH}>
        <img src={TeliaLogo} alt="Telia logo" className="header__logo" />
      </Link>

      {isAuthenticated && (
        <Link className="header__logout" to={SIGN_OUT_PATH}>
          {NOB.HEADER.LOGOUT}
        </Link>
      )}
    </div>
  );

  const header = (() => {
    if (!isAuthenticated) return defaultHeader;

    if (isAuthenticatedAndInWorkOrder) return workOrderHeader;

    if (router.location.pathname.indexOf('customerInfo') !== -1) return customerInfoHeader;

    return defaultHeader;
  })();

  return <div>{header}</div>;
};

const mapStateToProps = state => ({
  errorMessage: state.workOrder.errorMessage,
  isAuthenticated: state.authentication.isAuthenticated,
  router: state.router,
  user: state.user,
  workOrder: state.workOrder.workOrder,
});

Header.defaultProps = {
  errorMessage: '',
  workOrder: '',
};

Header.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAuthenticated: PropTypes.bool.isRequired,
  router: PropTypes.objectOf(PropTypes.any).isRequired,
  workOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(connect(mapStateToProps)(Header));
