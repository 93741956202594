export const DATES = 'dates';
export const DIAGNOSIS = 'Diagnose >';
export const DPAD_ID = 'dpadid';
export const DURATION = 'Varighet >';
export const ERROR = 'error';
export const HOME_SERVICE = 'Hjemlevering';
export const MAIN = 'main';
export const NDS_MIKRO_SMARTCARD = 'NDS mikro Smartcard';
export const NO_DEVICES = 'Found no devices to poll';
export const NOT_PLANNED = 'Ikke avtalt >';
export const PLANNED = 'Avtalt >';
export const SERVICE_DATE = 'serviceDate';
export const SERVICE_IDS = 'serviceIds';
export const SERVICE_TYPE_ID = 'serviceTypeId';
export const SUB = 'sub';
