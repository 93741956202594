import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { followUpFormsRequest } from './actions/followUpFormActions';
import SimpleDropdown from '../../components/Drowpdowns/SimpleDropdown';
import './follow-up.scss';
import FollowUpForm from './components/FollowUpForm';
import validateZendeskForm from '../../utils/validateZendeskForm';
import NOB from '../../constants/language-strings';
import { postZendeskFollowUpRequest, saveFollowUpFile } from '../WorkLog/actions/zendeskActions';
import zendeskBody from '../../utils/zendeskBody';
import { INPUT_TYPES } from './FollowUpConstans';
import { WORK_LOG_URL } from '../../constants/paths';
import SlideInPage from '../../components/SlideInPage/SlideInPage';

class FollowUpFormsContainer extends React.Component {
  state = {
    chosenForm: NOB.FOLLOW_UP.DEFAULT_DROPDOWN_VALUE,
    touched: false,
  };

  componentDidMount() {
    this.props.requestForms();
  }

  getChosenForm = () => this.props.forms.find(form => form.name === this.state.chosenForm);

  handleFormChange = event => {
    this.setState({ chosenForm: event.value, touched: false });
  };

  handleSubmit = values => {
    const zendesk = {
      followUpFormValues: values,
      files: this.props.followUp.files,
    };
    const body = zendeskBody(this.props.workOrder, zendesk, false);

    this.setState({ touched: true });

    if (validateZendeskForm(values, this.getChosenForm())) {
      this.props.postZendeskRequest(body);
      this.props.push(WORK_LOG_URL(this.props.workOrder.workOrderId));
    }
  };

  render() {
    const { files, isFetching } = this.props.followUp;

    const formList = this.props.forms.map(form => ({ name: form.name, value: form.name }));
    const chosenForm = this.getChosenForm();

    return (
      <SlideInPage
        showLeftIcon
        mainHeading={NOB.FOLLOW_UP.FOLLOW_UP}
        showRightIcon={false}
        isFetching={isFetching}
      >
        <div className="followup-content-heading-label">{INPUT_TYPES.HEADING} </div>
        <SimpleDropdown
          isWhite
          values={formList}
          onChange={this.handleFormChange}
          title={this.state.chosenForm}
        />
        {chosenForm && (
          <FollowUpForm
            form={chosenForm}
            files={files}
            onSubmit={this.handleSubmit}
            isSubmitted={this.state.touched}
            saveFiles={this.props.saveFiles}
          />
        )}
      </SlideInPage>
    );
  }
}

FollowUpFormsContainer.propTypes = {
  requestForms: PropTypes.func.isRequired,
  forms: PropTypes.arrayOf(PropTypes.any).isRequired,
  saveFiles: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  postZendeskRequest: PropTypes.func.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  followUp: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  followUp: state.zendesk.followUpForm,
  forms: state.followUpForms.forms,
  workOrder: state.workOrder.workOrder,
});

const mapDispatchToProps = dispatch => ({
  requestForms: () => dispatch(followUpFormsRequest()),
  postZendeskRequest: body => dispatch(postZendeskFollowUpRequest(body)),
  saveFiles: files => dispatch(saveFollowUpFile(files)),
  push: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpFormsContainer);
