import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions';
import { getAPI } from '../../../api/apiConfig';
import { HEALTH_CHECK } from '../../../constants/endpoints';
import { HEALTH_CHECK_REQUEST } from '../constants';

export function* callHealthCheck({ customerId }) {
  try {
    const url = HEALTH_CHECK(customerId);
    const result = yield call(getAPI, url);

    yield put(actions.receiveHealthCheck({ provisioning: result }));
  } catch (error) {
    yield put(
      actions.errorHealthCheck({
        errorMessage: `Error in health check response. ${error.message}`,
      }),
    );
  }
}

export default [takeLatest(HEALTH_CHECK_REQUEST, callHealthCheck)];
