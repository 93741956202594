import React from 'react';
import PropTypes from 'prop-types';
import '../../sam.scss';
import NOB from '../../../../constants/language-strings';
import ModemInfo from './ModemInfo';
import Streams from './Streams';
import { UPSTREAM, DOWNSTREAM } from '../../../../constants/samSelector';

const Overview = ({ devicePollingMac }) => (
  <div className="overview">
    <div className="overview__section">
      <div className="overview__section--heading">{NOB.DOWNSTREAM_UPSTREAM.MODEM_INFO}</div>
      <ModemInfo devicePollingMac={devicePollingMac} />
    </div>
    <div className="overview__section">
      <div className="overview__section--heading">{NOB.DOWNSTREAM_UPSTREAM.DOWNSTREAMS}</div>
      <Streams
        type={DOWNSTREAM}
        channels={devicePollingMac.DsChannels}
        noOfConfigured={devicePollingMac.NoOfDsConfigured}
        scoreVariables={devicePollingMac.ScoreVariables}
      />
    </div>
    <div className="overview__section">
      <div className="overview__section--heading">{NOB.DOWNSTREAM_UPSTREAM.UPSTREAMS}</div>
      <Streams
        type={UPSTREAM}
        channels={devicePollingMac.UsChannels}
        noOfConfigured={devicePollingMac.NoOfUsConfigured}
        scoreVariables={devicePollingMac.ScoreVariables}
      />
    </div>
  </div>
);

Overview.propTypes = {
  devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Overview;
