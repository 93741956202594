import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../Buttons/IconButton/IconButton';
import { TRASH } from '../../constants/iconNames';
import Variables from '../../styles/variables.scss';

function ProductItemContentInactive({
  onActivate,
  name,
  showDeleteButton,
  removeProduct,
  leftText,
}) {
  return (
    <div className="product_item--inactive">
      <button className="product_item--inactive-left" onClick={onActivate}>
        <span>{name}</span>
        <span className="product_item--inactive-status">{leftText}</span>
      </button>
      <div className="product--right">
        {showDeleteButton && (
          <IconButton icon={TRASH} onClick={removeProduct} fill={Variables.blackPrimary} />
        )}
      </div>
    </div>
  );
}

ProductItemContentInactive.propTypes = {
  onActivate: PropTypes.func,
  name: PropTypes.string.isRequired,
  showDeleteButton: PropTypes.bool,
  removeProduct: PropTypes.func,
  leftText: PropTypes.string.isRequired,
};

ProductItemContentInactive.defaultProps = {
  onActivate: null,
  showDeleteButton: false,
  removeProduct: null,
};

export default ProductItemContentInactive;
