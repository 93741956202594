import React from 'react';
import PropTypes from 'prop-types';
import './deviceStatusList.scss';
import DeviceStatus from '../../../../constants/deviceStatus';
import getDeviceStatusDescription from '../../../../utils/getDeviceStatusDescription';

function DeviceStatusListItem({ statusDescription, description, deviceStatus }) {
  const className = () => {
    switch (deviceStatus) {
      case DeviceStatus.OK:
        return 'device__status__container__button--status--good';
      case DeviceStatus.NEUTRAL:
        return 'device__status__container__button--status--neutral';
      case DeviceStatus.WARN:
        return 'device__status__container__button--status--warning';
      case DeviceStatus.ERROR:
        return 'device__status__container__button--status--error';
      default:
        return '';
    }
  };

  return (
    <div className="device_status_item">
      <div className="device_status_item-left">
        {description}
        <span className="device_status_item-description">{statusDescription}</span>
      </div>
      <div className="health_check_device_status-right">
        <div>
          <span className={className()} />
          {getDeviceStatusDescription(deviceStatus)}
        </div>
      </div>
    </div>
  );
}

DeviceStatusListItem.propTypes = {
  statusDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deviceStatus: PropTypes.string.isRequired,
};

export default DeviceStatusListItem;
