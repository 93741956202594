import React from 'react';
import PropTypes from 'prop-types';
import EndDayButton from './endDay/EndDayButton';
import { TECHNICIAN } from '../../../Authentication/constants';
import './workOrderListBottom.scss';

export default function WorkOrderListBottom({ endDayFunction, role }) {
  return (
    <div className="workOrder__list__bottom">
      {role === TECHNICIAN && (
        <div className="workOrder__list__bottom__buttons">
          <EndDayButton endDayFunction={endDayFunction} />
        </div>
      )}
    </div>
  );
}

WorkOrderListBottom.propTypes = {
  endDayFunction: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  togglePopup: PropTypes.func.isRequired,
};
