export const FOLLOW_UP_FORM_REQUEST = 'FOLLOW_UP_FORM_REQUEST';
export const FOLLOW_UP_FORM_SUCCESS = 'FOLLOW_UP_FORM_SUCCESS';
export const FOLLOW_UP_FORM_FAILURE = 'FOLLOW_UP_FORM_FAILURE';

export const INPUT_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radioButtons',
  TEXT: 'textInput',
  INFO_TEXT: 'informationText',
  HEADING: 'Oppfølgingstype',
};
