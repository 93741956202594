import moment from 'moment/moment';
import weekdays from '../constants/weekdays';
import { DASH_YEAR_MONTH_DAY } from '../constants/dateFormats';

export default function dateFromToday(daysFromToday = 0) {
  const today = new Date();

  today.setDate(today.getDate() + daysFromToday);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  const weekday = weekdays[today.getDay()];
  const date = moment(`${yyyy}-${mm}-${dd}`).format(DASH_YEAR_MONTH_DAY);

  return {
    date,
    weekday,
  };
}
