import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_REQUEST,
  VERIFY_TOKEN_FAILURE,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  EXTERNAL_INSTALLER,
  DEV_SIGN_IN_REQUEST,
} from '../constants';
import { TOKEN } from '../../../constants/localStorage';
import { setToken } from '../../../api/apiConfig';

const signInReducerDefaultState = {
  isAuthenticated: false,
  isFetching: false,
  role: EXTERNAL_INSTALLER,
  signInError: false,
};

export default (state = signInReducerDefaultState, { credentials, role, token, type }) => {
  switch (type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isFetching: true,
        credentials,
      };

    case DEV_SIGN_IN_REQUEST:
      return {
        ...state,
        isFetching: true,
        credentials,
      };

    case SIGN_IN_SUCCESS:
      localStorage.setItem(TOKEN, token);

      return {
        isFetching: false,
        isAuthenticated: true,
        signInError: false,
      };

    case SIGN_IN_FAILURE:
      return {
        isFetching: false,
        isAuthenticated: false,
        signInError: true,
      };

    case VERIFY_TOKEN_REQUEST:
      return {
        isFetching: true,
        isAuthenticated: false,
      };

    case VERIFY_TOKEN_SUCCESS:
      return {
        token,
        role,
        isAuthenticated: true,
        isFetching: false,
        signInError: false,
      };

    case VERIFY_TOKEN_FAILURE:
      localStorage.removeItem(TOKEN);

      return {
        isAuthenticated: false,
        isFetching: false,
        signInError: false,
      };

    case SIGN_OUT_REQUEST:
      localStorage.removeItem(TOKEN);

      return {
        isFetching: false,
        isAuthenticated: false,
        signInError: false,
      };

    default:
      return state;
  }
};
