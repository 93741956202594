import { TOGGLE_POPUP } from '../popupConstants';

const popupReducerDefaultState = {
  close: true,
  popupType: '',
};

export default (state = popupReducerDefaultState, { close, popupType, type }) => {
  switch (type) {
    case TOGGLE_POPUP:
      return {
        ...state,
        close,
        popupType,
      };

    default:
      return state;
  }
};
