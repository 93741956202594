import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  START_DAY_FAILURE,
  END_DAY_REQUEST,
  END_DAY_FAILURE,
  DAY_STATUS_SUCCESS,
  DEV_SIGN_IN_REQUEST,
} from '../constants';

export const requestSignIn = ({ credentials }) => ({
  type: SIGN_IN_REQUEST,
  credentials,
});

export const requestDevSignIn = ({ credentials }) => ({
  type: DEV_SIGN_IN_REQUEST,
  credentials,
});

export const receiveSignIn = ({ token }) => ({
  type: SIGN_IN_SUCCESS,
  token,
});

export const errorSignIn = ({ errorMessage }) => ({
  type: SIGN_IN_FAILURE,
  errorMessage,
});

export const requestLogout = () => ({
  type: SIGN_OUT_REQUEST,
});

export const receiveLogout = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const errorStartDay = ({ errorMessage }) => ({
  type: START_DAY_FAILURE,
  errorMessage,
});

export const requestEndDay = () => ({
  type: END_DAY_REQUEST,
});

export const errorEndDay = ({ errorMessage }) => ({
  type: END_DAY_FAILURE,
  errorMessage,
});

export const errorEndStartDay = ({ errorMessage }) => ({
  type: END_DAY_FAILURE,
  errorMessage,
});

export const receiveDayStatus = ({ dayActive }) => ({
  type: DAY_STATUS_SUCCESS,
  dayActive,
});
