export const ARCHIVE = 'archive';
export const ARROW_DOWN = 'arrowDown';
export const ARROW_LEFT = 'arrowLeft';
export const CALENDAR = 'calendar';
export const CAMERA = 'camera';
export const CAR = 'car';
export const CHECK = 'check';
export const CLOCK = 'clock';
export const CROSS = 'cross';
export const CROSS_LARGE = 'crossLarge';
export const DELETE = 'delete';
export const EDIT = 'edit';
export const EXPAND = 'expand';
export const GET_BOX = 'getBox';
export const HEALTH_CHECK = 'healthCheck';
export const HEART = 'heart';
export const HISTORY = 'history';
export const HOUSE = 'house';
export const LINK = 'link';
export const MAP = 'map';
export const MINIMIZE = 'minimize';
export const PLUS = 'plus';
export const REFRESH = 'refresh';
export const SAVE = 'save';
export const SCAN = 'scan';
export const SEARCH = 'search';
export const STATUS_ERROR = 'statusError';
export const STATUS_OK = 'statusOk';
export const SWITCH = 'switch';
export const TELEPHONE = 'telephone';
export const TOOL = 'tool';
export const TOOL_OULINED = 'toolOutlined';
export const TRASH = 'trash';
export const USER = 'user';
export const PDF = 'pdf';
export const SWITCH2 = 'switch2';
