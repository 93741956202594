import {
  CUSTOMER_PRODUCTS_REQUEST,
  CUSTOMER_PRODUCTS_SUCCESS,
  CUSTOMER_PRODUCTS_FAILURE,
  CUSTOMER_PRODUCTS_UPDATE,
  CUSTOMER_PRODUCTS_REQUEST_BACKGROUND,
  UPDATE_SINGLE_PRODUCT,
  REMOVE_PRODUCT_SUCCESS,
} from '../customerProductsConstants';

const customerProductsReducerDefaultState = {
  customerProducts: [],
  errorMessage: '',
  isFetching: true,
  isFetchingInBackground: false,
  selectedProductId: 0,
};

export default (
  state = customerProductsReducerDefaultState,
  { customerProducts, errorMessage, type, newProduct, removedProductId },
) => {
  switch (type) {
    case CUSTOMER_PRODUCTS_REQUEST:
      return {
        ...state,
        customerProducts: [],
        isFetching: true,
        errorMessage: '',
      };

    case CUSTOMER_PRODUCTS_REQUEST_BACKGROUND:
      return {
        ...state,
        isFetchingInBackground: true,
      };

    case CUSTOMER_PRODUCTS_SUCCESS:
      return {
        ...state,
        customerProducts,
        isFetching: false,
        isFetchingInBackground: false,
      };

    case CUSTOMER_PRODUCTS_FAILURE:
      return {
        ...state,
        errorMessage,
        isFetching: false,
        isFetchingInBackground: false,
      };

    case CUSTOMER_PRODUCTS_UPDATE:
      return {
        ...state,
        customerProducts,
      };
    case UPDATE_SINGLE_PRODUCT:
      return {
        ...state,
        customerProducts: state.customerProducts.map(oldProduct =>
          oldProduct.productId === newProduct.productId ? newProduct : oldProduct,
        ),
      };
    case REMOVE_PRODUCT_SUCCESS: {
      return {
        ...state,
        customerProducts: state.customerProducts.filter(
          product => product.productId !== removedProductId,
        ),
      };
    }
    default:
      return state;
  }
};
