import {
  WORK_ORDER_HISTORY_REQUEST,
  WORK_ORDER_HISTORY_FAILURE,
  WORK_ORDER_HISTORY_SUCCESS,
} from '../workOrderHistoryConstants';

const workOrderHistoryReducerDefaultState = {
  workOrderHistory: [],
  errorMessage: '',
  isFetching: false,
};

export default (state = workOrderHistoryReducerDefaultState, action) => {
  const { workOrderHistory, errorMessage, type, workOrderId } = action;

  switch (type) {
    case WORK_ORDER_HISTORY_REQUEST:
      return {
        workOrderHistory: [],
        workOrderId,
        isFetching: true,
      };
    case WORK_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        workOrderHistory,
      };
    case WORK_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage,
      };
    default:
      return state;
  }
};
