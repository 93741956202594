import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';
import DeviceStatus from '../../constants/deviceStatus';

function Table({ columns, rows, columnValueTypes }) {
  function colorPicker(status) {
    switch (status) {
      case DeviceStatus.OK:
        return 'td--green';
      case DeviceStatus.NEUTRAL:
        return '';
      case DeviceStatus.WARN:
        return 'td--warn';
      case DeviceStatus.ERROR:
        return 'td--error';
      default:
        return '';
    }
  }
  const columnNames = columns.filter(column => column !== '');

  return (
    <div className="table">
      <div className="tr">
        {columnNames.map(column => (
          <div className="th" key={column}>
            {column}
          </div>
        ))}
      </div>
      {rows.map(row => (
        <div className="tr" key={row.name}>
          <span className="td">{row.name}</span>
          {columnValueTypes.map(type => {
            const propertyValue = row.propertyValues.find(value => value.type === type);

            if (propertyValue) {
              return (
                <div className="td" key={propertyValue}>
                  <div>
                    <span className={colorPicker(propertyValue.value.first.valueStatus)}>
                      {propertyValue.value.first.value}
                    </span>
                    {propertyValue.value.second.value && (
                      <span>
                        <span> / </span>
                        <span className={colorPicker(propertyValue.value.second.valueStatus)}>
                          {propertyValue.value.second.value}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              );
            }

            return <div className="td" key={propertyValue} />;
          })}
        </div>
      ))}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyValues: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.objectOf(
            PropTypes.shape({
              first: PropTypes.objectOf(
                PropTypes.shape({
                  value: PropTypes.string.isRequired,
                  valueStatus: PropTypes.string.isRequired,
                }),
              ),
              second: PropTypes.objectOf(
                PropTypes.shape({
                  value: PropTypes.string.isRequired,
                  valueStatus: PropTypes.string.isRequired,
                }),
              ),
            }),
          ).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  columnValueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Table;
