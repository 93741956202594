import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Heading from '../../components/product/Heading';
import Icon from '../../../../icons';
import { LINK } from '../../../../constants/iconNames';
import NOB from '../../../../constants/language-strings';
import './linking.scss';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import FetchingSpinner from '../../../../utils/FetchingSpinner';
import * as linkAction from '../../actions/linkProductActions';
import RadioButton from '../../../../components/Buttons/RadioButton/RadioButton';

function LinkingContainer({
  customerId,
  linkProductRequest,
  productId,
  product: { name, multipleLinksAllowed },
  linkOptions,
  isFetchingInBackground,
  isLinkingFetching,
  errorMessage,
  linkSingleProductRequest,
}) {
  const subHeading = `${NOB.LINKING.WHERE_SHALL} ${name} ${NOB.LINKING.BE_LINKED}`;

  const linkProduct = event => {
    linkProductRequest(customerId, productId, event.target.name);
  };

  const linkSingleProduct = event => {
    linkSingleProductRequest(customerId, productId, event.target.name);
  };

  if (isFetchingInBackground) return <FetchingSpinner />;

  if (linkOptions.length > 0) {
    return (
      <div className="linking">
        <Heading
          icon={<Icon name={LINK} />}
          mainHeading={NOB.LINKING.NO_AVAILABLE}
          subHeading={subHeading}
        />
        <ul>
          {linkOptions.map(option => (
            <li key={option.agreementDetailId}>
              {multipleLinksAllowed ? (
                <Checkbox
                  label={`${option.name} (${
                    option.serialNumber ? option.serialNumber : 'Ikke aktiv'
                  })`}
                  checked={option.linked}
                  onClick={event => {
                    linkProduct(event);
                  }}
                  name={option.dpadId.toString()}
                  isFetching={
                    isLinkingFetching.isFetchingSingleDpad &&
                    isLinkingFetching.dpadId === option.dpadId.toString()
                  }
                />
              ) : (
                <RadioButton
                  label={`${option.name} (${
                    option.serialNumber ? option.serialNumber : 'Ikke aktiv'
                  })`}
                  checked={option.linked}
                  onClick={event => {
                    linkSingleProduct(event);
                  }}
                  name={option.dpadId.toString()}
                  value={option.dpadId.toString()}
                  isFetching={
                    isLinkingFetching.isFetchingSingleDpad &&
                    isLinkingFetching.dpadId === option.dpadId.toString()
                  }
                />
              )}
            </li>
          ))}
        </ul>
        {errorMessage && <span className="linking--error-message">{errorMessage}</span>}
      </div>
    );
  }

  return null;
}

LinkingContainer.propTypes = {
  customerId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  linkProductRequest: PropTypes.func.isRequired,
  linkSingleProductRequest: PropTypes.func.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetchingInBackground: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLinkingFetching: PropTypes.objectOf(PropTypes.any).isRequired,
  linkOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state, props) => ({
  product: state.customerProducts.customerProducts.find(
    customerProduct => customerProduct.productId === props.productId,
  ),
  linkOptions: state.customerProducts.customerProducts.find(
    customerProduct => customerProduct.productId === props.productId,
  ).linkOptions,
  customerId: state.workOrder.workOrder.customer.customerId,
  isFetchingInBackground: state.customerProducts.isFetchingInBackground,
  isLinkingFetching: state.linkProduct.isFetching,
  errorMessage: state.linkProduct.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  linkProductRequest: (customerId, productId, dpadId) =>
    dispatch(linkAction.linkProductRequest(customerId, productId, dpadId)),
  linkSingleProductRequest: (customerId, productId, dpadId) =>
    dispatch(linkAction.linkSingleDpadRequest(customerId, productId, dpadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkingContainer);
