import PropTypes from 'prop-types';
import React, { Component } from 'react';
import capitalize from '../../../../utils/capitalizeString';
import Icon from '../../../../icons';
import NOB from '../../../../constants/language-strings';
import Status, { COMPLETE, INCOMPLETE } from '../../../../constants/status-codes';
import { CAR, TOOL_OULINED } from '../../../../constants/iconNames';
import './workOrderDetails.scss';
import { hours, minutes } from '../../../../utils/timeCalculator';
import getFormattedDate from '../../../../utils/getFormattedDate';
import isPast from '../../../../utils/isPastDue';

class WorkOrderDetails extends Component {
  constructor(props) {
    super(props);

    const { travelDuration, workDuration } = this.props;

    this.state = {
      driveTime: this.formatTime(travelDuration - workDuration),
      workTime: this.formatTime(workDuration),
      driveSeconds: travelDuration,
      workSeconds: workDuration,
    };

    this.driveTimer = 0;
    this.workTimer = 0;

    if (Status.TRAVELING.includes(props.workOrder.status)) {
      this.setState({
        driveSeconds: travelDuration,
        driveTime: this.formatTime(travelDuration),
      });

      this.startDriveTimer();
      this.stopWorkTimer();
    }

    if (Status.ON_SITE.includes(props.workOrder.status)) {
      this.setState({
        workSeconds: workDuration,
        driveSeconds: travelDuration,
        driveTime: this.formatTime(travelDuration),
      });

      this.startWorkTimer();
      this.stopDriveTimer();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { travelDuration, workDuration } = props;
    const driveTime = state.driveSeconds;
    // In case the time from ICX (travelDuration) does not match the timed seconds in driveTime, use the ICX time, unless it is zero.
    const actualDriveTime = travelDuration !== 0 ? travelDuration : driveTime;

    let newState = null;

    if (Status.ON_SITE.includes(props.workOrder.status)) {
      newState = {
        workSeconds: workDuration,
        driveSeconds: actualDriveTime,
        workTime: this.formatTime(workDuration),
        driveTime: this.formatTime(actualDriveTime),
      };

      this.startWorkTimer();
      this.stopDriveTimer();
    }

    if (Status.TRAVELING.includes(props.workOrder.status)) {
      newState = {
        driveSeconds: actualDriveTime,
        driveTime: this.formatTime(actualDriveTime),
      };

      this.startDriveTimer();
      this.stopWorkTimer();
    }

    return newState;
  }

  componentWillUnmount() {
    this.startWorkTimer();
    this.startDriveTimer();
    this.stopWorkTimer();
    this.stopDriveTimer();
    this.countDrive();
    this.countWork();
  }

  formatTime = sec => {
    this.hours = Math.floor(sec / (60 * 60));
    this.formatMinutes = sec % (60 * 60);
    this.minutes = Math.floor(this.formatMinutes / 60);
    this.formatSeconds = this.formatMinutes % 60;
    this.seconds = Math.ceil(this.formatSeconds);

    this.time = {
      hours: this.hours.toString(),
      minutes: this.minutes.toString(),
      seconds: this.seconds.toString(),
    };

    return this.time;
  };

  startDriveTimer = () => {
    if (this.driveTimer === 0) {
      this.driveTimer = setInterval(this.countDrive, 1000);
    }
  };

  stopDriveTimer = () => {
    clearInterval(this.driveTimer);
  };

  countDrive = () => {
    const driveSeconds = this.state.driveSeconds + 1;

    this.setState({
      driveTime: this.formatTime(driveSeconds),
      driveSeconds,
    });

    if (driveSeconds === 0) {
      clearInterval(this.driveTimer);
    }
  };

  startWorkTimer = () => {
    if (this.workTimer === 0) {
      this.workTimer = setInterval(this.countWork, 1000);
    }
  };

  stopWorkTimer = () => {
    clearInterval(this.workTimer);
  };

  countWork = () => {
    const workSeconds = this.state.workSeconds + 1;

    this.setState({
      workTime: this.formatTime(workSeconds),
      workSeconds,
    });

    if (workSeconds === 0) {
      clearInterval(this.workTimer);
    }
  };

  render() {
    const { isFetching, done, workOrder, totalTimeEstimate, inWorkLog } = this.props;

    const driveTimer = `
      ${this.state.driveTime.hours.padStart(2, 0)}:
      ${this.state.driveTime.minutes.padStart(2, 0)}:
      ${this.state.driveTime.seconds.padStart(2, 0)}`;

    const workTimer = `
      ${this.state.workTime.hours.padStart(2, 0)}:
      ${this.state.workTime.minutes.padStart(2, 0)}:
      ${this.state.workTime.seconds.padStart(2, 0)}`;

    const status = /\d/.test(workOrder.status)
      ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
      : workOrder.status;

    const header = () => `
        ${NOB.WORKORDER_DETAILS.ESTIMATED_TIME} 
        ${hours(totalTimeEstimate)} 
        ${minutes(totalTimeEstimate)}`;
    const isPastDue = isPast(workOrder.serviceDate);

    return (
      <div className="workOrder__details__outer__container">
        <div className="workOrder__details__container">
          <span className="item">
            <span className="item__description">{NOB.WORKORDER_DETAILS.WORKORDER}:&nbsp;</span>
            {workOrder.workOrderId}
          </span>

          {!(
            Status.ON_SITE.includes(workOrder.status) || Status.TRAVELING.includes(workOrder.status)
          ) ? (
            <div>
              {COMPLETE.includes(status) || INCOMPLETE.includes(status) ? (
                <div className="item">
                  <span className="item--item">{capitalize(status)}</span>
                </div>
              ) : (
                <div className={isPastDue ? 'item--red' : 'item'}>
                  {getFormattedDate(workOrder.serviceDate)} kl.{workOrder.timeSlot}
                </div>
              )}
            </div>
          ) : (
            <div className="item">
              <span>
                {Status.TRAVELING.includes(workOrder.status)
                  ? NOB.WORKORDER_LIST.DRIVESTARTED
                  : NOB.WORKORDER_LIST.WORKSTARTED}
              </span>
            </div>
          )}
        </div>

        {!done && !inWorkLog && (
          <div>
            {!(
              Status.ON_SITE.includes(workOrder.status) ||
              Status.TRAVELING.includes(workOrder.status)
            ) ? (
              <span className="workOrder__details__container__items--estimatedTime">
                {header()}
              </span>
            ) : (
              <div className="workOrder__details__container--bottom">
                {Status.ON_SITE.includes(workOrder.status) && (
                  <div
                    className={
                      Status.ON_SITE.includes(workOrder.status)
                        ? 'workOrder__details__container__items--started'
                        : 'workOrder__details__container__items--bottom'
                    }
                  >
                    <Icon name={TOOL_OULINED} />
                    <div> {NOB.WORKORDER_DETAILS.WORKING_TIME} </div>&nbsp;
                    {isFetching && (
                      <div className="loader__wrapper">
                        <div className="circle-loader-black" />
                      </div>
                    )}
                    {!isFetching && <span>{workTimer}</span>}
                  </div>
                )}

                <div
                  className={
                    Status.TRAVELING.includes(workOrder.status)
                      ? 'workOrder__details__container__items--started'
                      : 'workOrder__details__container__items--bottom'
                  }
                >
                  <Icon name={CAR} />
                  {NOB.WORKORDER_DETAILS.DRIVING_TIME}&nbsp;
                  {isFetching && (
                    <div className="loader__wrapper">
                      <div className="circle-loader-black" />
                    </div>
                  )}
                  {!isFetching && <span>{driveTimer}</span>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

WorkOrderDetails.propTypes = {
  isFetching: PropTypes.bool,
  done: PropTypes.bool.isRequired,
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  totalTimeEstimate: PropTypes.number.isRequired,
  travelDuration: PropTypes.number,
  workDuration: PropTypes.number,
  inWorkLog: PropTypes.bool.isRequired,
};

WorkOrderDetails.defaultProps = {
  isFetching: false,
  travelDuration: 0,
  workDuration: 0,
};

export default WorkOrderDetails;
