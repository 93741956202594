/*
 * Function using regular expressions to match the provided serviceNames
 * and return the maximum allowed quantity for the given service.
 *
 * To allow some leeway for human error, the regex is based on units of three letters with
 * optional whitespaces, and letters, in between each unit.
 *
 * Example:
 * given the string:  Lorem ipsum dolor
 * the regex would be created based on the segments lor -- ips -- dol.
 * regex: lor[a-z]*\s?ips[a-z]*\sdol[a-z]*
 */

const serviceQuantityRules = serviceName => {
  switch (true) {
    // Bytte kontakt
    case /byt[a-z]*\s?kon[a-z]*/gi.test(serviceName):
      return 20;

    // Inst kontakt
    case /ins[a-z]*\s?kon[a-z]*/gi.test(serviceName):
      return 20;

    // Ekstra stolpe klargjøring
    case /eks[a-z]*\s?sto[a-z]*\s?kla.*/gi.test(serviceName):
      return 5;

    // Ekstra tid Installasjon
    case /eks[a-z]*\s?tid\s?ins[a-z]*/gi.test(serviceName):
      return 20;

    // Tillegstid inst
    case /til[a-z]*\s?ins[a-z]*/gi.test(serviceName):
      return 20;

    // Montere nedføringsrør
    case /mon[a-z]*\s?ned.*/gi.test(serviceName):
      return 5;

    // Godkjente løpende timer
    case /god[a-z]*\s?l.*\s?tim[a-z]*/gi.test(serviceName):
      return 10;

    // Inst. FTTH switch
    case /ins.*\s?FTTH\s?swi[a-z]*/gi.test(serviceName):
      return 5;

    // Klargjøring
    case /^kla.*gj.*in.*/gi.test(serviceName):
      return 5;

    // Timespris
    case /tim[a-z]*\s?pris[a-z]*/gi.test(serviceName):
      return 20;

    // Forbruksmateriell
    case /forbruk[a-z]*\s?mat[a-z]*\s?riel[a-z]*/gi.test(serviceName):
      return 20;

    default:
      return 1;
  }
};

/*
 * Function takes the serviceId as input and returns
 * the maximum quantity allowed for the given service.
 *
 * NOTE: Currently, we do not know the serviceId's for the services
 * that should have extended priviliges.
 */

// const serviceQuantityRules = serviceId => {
//   switch (serviceId) {
//     // CATEGORY: HFC Installasjon
//     case 2593 || 2598: // Bytte kontakt
//       return 20;

//     case 2614: // Ekstra tid installasjon (Tillegstid inst)
//       return 20;

//     // case : // Godkjente løpende timer
//     //   return 10;

//     // case : // Inst kontakt
//     //   return 20;

//     case 2617 || 2611: // Montere nedføringsrør
//       return 5;

//     // CATEGORY: FTTH Installasjon
//     case 2977: // Bytte kontakt
//       return 20;

//     case 3003: // Ekstra stolpe klargjøring
//       return 5;

//     case 3029: // Ekstra tid installasjon
//       return 20;

//     case 3031: // Godkjente løpende timer
//       return 10;

//     case 2976: // Inst kontakt
//       return 20;

//     case 1520: // Inst. FTTH switch
//       return 5;

//     case 3001: // Klargjøring
//       return 5;

//     case 3033: // Montere nedføringsrør
//       return 5;

//     default:
//       return 1;
//   }
// };

export default serviceQuantityRules;
