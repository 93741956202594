import {
  WORKORDER_SERVICES_REQUEST,
  WORKORDER_SERVICES_SUCCESS,
  WORKORDER_SERVICES_ERROR,
  UPDATE_SERVICE_REQUEST,
  REMOVE_SERVICE_REQUEST,
  COMPLETE_WORK_ORDER_WITH_ZENDESK,
  COMPLETE_STATUS_CHANGE_SUCCESS,
  COMPLETE_STATUS_CHANGE_FAILURE,
} from '../workLogConstants';

const workorderServicesReducerDefaultState = {
  isFetchingServices: false,
  isCompletingWorkOrder: false,
  errorMessage: '',
  orderedItems: {
    itemSummary: [],
    totalTimeEstimate: 0,
    totalPrice: 0,
  },
  workOrderServiceIdToBeChanged: null,
};

export default (
  state = workorderServicesReducerDefaultState,
  { errorMessage, services, type, workOrderServiceId },
) => {
  switch (type) {
    case WORKORDER_SERVICES_REQUEST:
      return {
        ...state,
        isFetchingServices: true,
      };

    case WORKORDER_SERVICES_SUCCESS:
      return {
        ...state,
        isFetchingServices: false,
        orderedItems: services,
        workOrderServiceIdToBeChanged: null,
      };

    case WORKORDER_SERVICES_ERROR:
      return {
        ...state,
        isFetchingServices: false,
        errorMessage,
      };
    case UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        workOrderServiceIdToBeChanged: workOrderServiceId,
        isFetchingServices: !workOrderServiceId,
      };
    case REMOVE_SERVICE_REQUEST:
      return {
        ...state,
        workOrderServiceIdToBeChanged: workOrderServiceId,
      };

    case COMPLETE_WORK_ORDER_WITH_ZENDESK:
      return {
        ...state,
        isCompletingWorkOrder: true,
      };
    case COMPLETE_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        isCompletingWorkOrder: false,
      };
    case COMPLETE_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        isCompletingWorkOrder: false,
      };
    default:
      return state;
  }
};
